import axios  from 'axios'


const functions = axios.create({
  baseURL: 'https://asia-northeast3-ai-englemap.cloudfunctions.net',
})

export  const functionsAPI = {
  QnAPost: (data: Object) => functions.post('/qaemail-sendMail', data,  {headers: {'Content-Type':'multipart/form-data'}}),
}
