import React, { useState, useEffect }  from 'react';
import { logEvent } from 'firebase/analytics';
import { applyActionCode } from "firebase/auth";
// TODO import Spinner from 'react-spinkit';
import { analytics, auth } from '../../firebase';
import { CircularProgress, Stack, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

export const LoadingTypography = styled(Typography)({
  fontSize: "16px",
  color: "#101010",
  fontFamily: 'NanumSquare',
  height: "20px",
  fontWeight: '600',
});

type AuthProps = {
  actionCode: string;
}

export type AuthState = {
  actionCode: string;
  error: string;
  validCode: boolean;
  verifiedCode: boolean;
}

export default function VerifyEmail(props:AuthProps)  {
    const [error, setError] = useState('');
    const [verifiedCode, setVerifiedCode] = useState(false);
    const [validCode, setValidCode] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
      applyActionCode(auth, props.actionCode)
        .then(() => {
          logEvent(analytics, 'sign_up', { method: 'verifyEmail' });
          // Email address has been verified.
          setValidCode(true);
          setVerifiedCode(true);
        }, error => {
          // Code is invalid or expired. Ask the user to verify their email address
          console.log(error.email);
          setError(error.message);
          setValidCode(false);
          setVerifiedCode(true);
        });
    });

  let component;
  if (!verifiedCode) {
    component = (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} padding={5} bgcolor="#FFFFFF" border="1px solid #5a62ad">
          <CircularProgress />
          <LoadingTypography justifyContent="center">이메일 인증 확인 중입니다.</LoadingTypography>
        </Stack>
      </Box>);
  } else if (verifiedCode && validCode) {
    component = (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} padding={5} bgcolor="#FFFFFF" border="1px solid #5a62ad">
          <LoadingTypography justifyContent="center">사용자 이메일이 확인 되었습니다.</LoadingTypography>
          <LoadingTypography justifyContent="center">가입하신 이메일로 로그인하시면 AI잉글맵으로 인공지능 구분분석 서비스를 이용하실 수 있습니다.</LoadingTypography>
        </Stack>
      </Box>);
  } else if (verifiedCode && !validCode) {
    component = (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2} padding={5} bgcolor="#FFFFFF" border="1px solid #5a62ad">
          <LoadingTypography justifyContent="center">유효하지 않은 이메일 인증 링크입니다.</LoadingTypography>
          <LoadingTypography justifyContent="center">이미 이메일 인증을 하셨거나, 새로운 이메일 링크로 인증하셔야 합니다. </LoadingTypography>
          <LoadingTypography justifyContent="center">이메일이 인증되지 않았다면, 아이디와 비밀번호로 입력하고 로그인하면 인증이메일을 재발송 할 수 있습니다.</LoadingTypography>
          <Button variant="outlined"  onClick={()=> {navigate('/signin', {replace: false}); }}>로그인</Button>
        </Stack>
      </Box>);
  }

  return component;
}
