import axios from 'axios'

const mock = axios.create({
  baseURL: 'https://jsonplaceholder.typicode.com',
})

export const inputSample = [
  "Soldiers! Don't give yourselves to brutes - men who despise you - enslave you who regiment your lives - tell you what to do - what to think or what to feel!.",
  "Let us fight to new world, a decent world that will give men a chance to work, that will give youth the future and old age a security.",
  "Let us fight to free the world, to do away with national barriers, to do away with greed, with hate and intolerance.",
  "Let us fight for a world of reason, a world where science and progress will lead to all men's happiness.",
];


type ContentType = "element" | "base" | "knot";

export interface Content {
  content: string,
  element: string,
  layer?: number,
  knot?: string,
  part?: string,
}
export interface Sentence {
  enSentence: string,
  krSentence: string,
  content: Array<Content>,
}

const sample1: Sentence = {
  enSentence: inputSample[0],
  krSentence: "군인들이여! 잔인한 자들에게 굴복하지 마라 - 당신을 경멸하는 - 당신을 삶을 통제하고 노예로 만드는- 당신에게 하라고 말하는 - 무엇을 생각할지, 무엇을 느낄지!.",
  content: [
    {content: "Soldiers!", element: "s", layer: 0},
    {content: "Don't give", element: "v", layer: 0},
    {content: "yourselves", element: "o", layer: 0},
    {content: "to brutes", element: "c", layer: 0},
    {content: "- men", element: "c", layer: 1, knot: "12", part: "adjective"},
    {content: "who despise you", element: "c", layer: 2, knot: "3", part: "adjectiveP"},
    {content: "-", element: "c", layer: 3, knot: "42", part: "coordinator"},
    {content: "enslave you", element: "c", layer: 2},
    {content: "who regiment your lives", element: "c", layer: 2, knot: "3", part: "adjectiveP"},
    {content: "-", element: "c", layer: 3, knot: "42", part: "coordinator"},
    {content: "tell you", element: "c", layer: 2},
    {content: "what to do", element: "c", layer: 3, knot: "20", part: "noun"},
    {content: "-", element: "c", layer: 4, knot: "42", part: "coordinator"},
    {content: "what to think", element: "c", layer: 3, knot: "20", part: "noun"},
    {content: "or", element: "c", layer: 4, knot: "42", part: "coordinator"},
    {content: "what to feel!.", element: "c", layer: 3, knot: "20", part: "noun"},
  ],
}

const sample2 = {
  enSentence: inputSample[1],
  krSentence: "사람들이 일할 수 있는 기회와 젊은이에게 미래, 노인들에게는 안전을 줄 수 있는 새로운 세상를 위해 싸웁시다.",
}

const sample3 = {
  enSentence: inputSample[2],
  krSentence: "나라간의 경계, 탐욕, 증오 배척이 없는 자유로운 세상을 위해 싸웁시다.",
}

const sample4 = {
  enSentence: inputSample[3],
  krSentence: "과학과 발전이 모두에게 행복을 가져다 줄 수 있는 이성적인 세상을 위해 싸웁시다.",
}

export const mockApi = {
  post: (sample: Array<string>) => {
    return [sample1];
  }
}
