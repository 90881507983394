import * as React from 'react';
import { logEvent } from 'firebase/analytics';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

import Pagination from "react-js-pagination";

import LineCalendar from '../../components/LineCalendar';
import Lectureplayer from '../../components/LecturePlayer';

import { withDispatch } from '../../router';
import { analytics, auth } from '../../firebase';
import { StatLecture, getLectures, GetParam, paginationCount, UserCollection } from '../../store/statSlice';

import { StudyTableCell, StudySubTitle, StudyLoadingTypography} from './StudyStyle';
import {CircularProgress, Stack} from '@mui/material';

const ClockButton = styled(Button)({
  marginLeft:"20px",
  marginRight:"1px",
  width: "10px",
  maxWidth: "10px",
  minWidth: "10px",
  height: "10px",
  maxHeight: "10px",
  minHeigth: "10px",
});

interface appProps {
  refresh: string,
  navigate(to: string, option?: any): any
  dispatch(to: any): any
}

interface appState {
  refresh: string,
  list: Array<StatLecture>,
  open: boolean,
  knot: string,
  title: string,
  link: string,
  //
  date: Date,
  count: number,
  page: number,
  rowsPerPage: number,
  isLoading: boolean, 
}

class Lecture extends React.Component<appProps, appState>  {

  constructor (props : appProps) {
    super(props);
    this.state = {
      refresh: undefined,
      list: [],
      open: false,
      knot: '',
      title: '',
      link: '',
      //
      date: new Date(),
      count: 0,
      page: 0,
      rowsPerPage: 10,
      isLoading : true,
    }
  }

  componentDidMount() {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        this.props.navigate('/signIn');
        return;
      }

      const param: GetParam = {
        date: this.state.date,
        page: 0,
        rowsPerPage: this.state.rowsPerPage,
      }
      const count = await paginationCount(UserCollection.Lectures, param);
      this.setState({count: count});

      await this.getLectures(this.state.date, this.state.page, this.state.rowsPerPage);
    });
  }

  static getDerivedStateFromProps(nextProps:any, prevState :any) {
    if (nextProps.refresh !== prevState.refresh) {
      return { 
        refresh: nextProps.refresh,
       };
    }
    return null;
  }

  async getLectures(date: Date, page: number, rowsPerPage: number) {
    const param: GetParam = {
      date: date,
      page: page,
      rowsPerPage: rowsPerPage,
    }
    await this.props.dispatch(getLectures(param)).then((result: any) => {
      if (result && result.payload) {
        const knots: Array<StatLecture> = result.payload;
        knots.forEach((k: StatLecture) => {
          this.state.list.push(k);
          this.setState({ refresh : '1' });
        });
      }
    }).catch((error: Error) => {
      console.error('getLectures: error:', error);
    }).finally(()=>{
      this.setState({isLoading: false});
    });
  }

  render() {
    const handleDateChange = async (date: Date) => {
      const param: GetParam = {
        date: date,
        page: 0,
        rowsPerPage: this.state.rowsPerPage,
      }
      this.setState({isLoading: true});
      const count = await paginationCount(UserCollection.Lectures, param);
   
      this.setState({count: count});

      this.setState({date: date});
      this.setState({page: 0});
      this.setState({list: []});
      this.getLectures(date, this.state.page, this.state.rowsPerPage);
    }

    const handleOpen = (v: StatLecture) => {
      logEvent(analytics, 'select_item', {
        item_list_name: 'lecture',
        item_list_id: v.knot,
      });
      this.setState({ 
        open : true,
        knot: v.knot,
        title: v.title,
        link: v.link,
      });
    }

    const handleClose = () => {
      this.setState({ open: false })
    }

    function convertSecondToString(seconds: number) {
      if (isNaN(seconds)) {
        return '00:00:00';
      }
      var dDate = new Date(null);
      dDate.setSeconds(seconds);
      var d = dDate.toISOString().substr(11, 8);
      return d;
    }

    const handleChangePage = (event: unknown, newPage: number) => {
      this.setState({page: newPage});
      this.setState({list: []});
      this.getLectures(this.state.date, newPage, this.state.rowsPerPage);
    }

    const handlePageChange = (newPage: number) => {
      newPage = newPage - 1;
      this.setState({isLoading: true});
      this.setState({page: newPage});
      this.setState({list: []});
      this.getLectures(this.state.date, newPage, this.state.rowsPerPage);
    }

    const getTableRows = (list:StatLecture[])  =>{
      if (this.state.isLoading) {
        return (          
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <StudyTableCell component="th" scope="row" sx={{pl:"3%"}}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <CircularProgress  size={20} />
              <StudyLoadingTypography justifyContent="center">로딩 중...</StudyLoadingTypography>
            </Stack>
            </StudyTableCell>
          </TableRow>
        )
      }

      if (list.length > 0) {
        return (
          this.state.list.map((row) => (
            <TableRow
              key={row.knot}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() => {handleOpen(row)}}>
              <StudyTableCell component="th" scope="row" sx={{pl:'3%'}}> #{row.knot}  {row.title} </StudyTableCell>
              <StudyTableCell component="th" scope="row" align="right" sx={{pr:'3%'}}>
                <ClockButton startIcon={<AccessTimeIcon />} />
                {convertSecondToString(row.duration)}
              </StudyTableCell>
            </TableRow>
          ))
        );
      } else {
        return (
            <TableRow
              key={0}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StudyTableCell component="th" scope="row" align='center'>시청한 강의가 없습니다.</StudyTableCell>
            </TableRow>
        );
      }
    }


    return (
      <Box component="div" className="StudyBody">
        <Container maxWidth="lg" sx={{ my:1 }}>
          <StudySubTitle>구문강의</StudySubTitle>
          <Divider sx={{  my: 1, borderBottomWidth: 1, backgroundColor:"rgba(90, 98, 173, 1)", marginBottom:"-10px" }} />
          <TableContainer component={Paper} className="Table"   >
            <Table sx={{ minWidth: 650}} size="small" stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StudyTableCell colSpan={2}>
                    <LineCalendar date={this.state.date} onChange={handleDateChange}></LineCalendar>
                  </StudyTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getTableRows(this.state.list)}
              </TableBody>
            </Table>
          </TableContainer>
          {/*
          <TablePagination
            rowsPerPageOptions={[this.state.rowsPerPage]}
            component="div"
            count={this.state.count}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
          */}
          <br/>
          <Pagination 
            activePage={this.state.page+1} 
            itemsCountPerPage={10} 
            totalItemsCount={this.state.count} 
            pageRangeDisplayed={5} 
            prevPageText={<button className="lt">&lt;</button>} 
            nextPageText={<button className="gt">&gt;</button>} 
            firstPageText={<button className="lt">&lt;&lt;</button>}
            lastPageText={<button className="gt">&gt;&gt;</button>}
            onChange={handlePageChange}
            hideDisabled={(this.state.list.length <=0 ? true: false)}
          />
        </Container>

        <Lectureplayer open={this.state.open} knot={this.state.knot} save={false} onClose={handleClose} />
      </Box>
    );
  }
}

export default withDispatch(Lecture);
