import React from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import StopSharpIcon from '@mui/icons-material/StopSharp';

interface TitleProps {
  title: string,
  subTitle?: string,
  divider: boolean,
  icon: boolean,
  style?: Object,
};

const TitleTypography = styled(Typography)`
  font-size: 22px;
  font-weight: 900;
  font-family: NanumSquareExtraBold;
`;

const SubtitleTypography = styled(Typography)`
  font-size: 12px;
  font-family: NanumGothic;
`;

export default class Title extends React.Component<TitleProps> {
  static defaultProps = {divider: true, icon: true};

  render() {
    return (
      <Box component="div" sx={{ mx: 3, my: 3}} style={this.props.style}>
        <Stack spacing={1} direction="row" alignItems="center">
          {
            this.props.icon?
              <StopSharpIcon /> : null
          }
          <Typography variant="subtitle1" color="primary">{this.props.title}</Typography>
          <SubtitleTypography color="#777777">{this.props.subTitle}</SubtitleTypography>
        </Stack>
        {
          this.props.divider?
            <Divider sx={{ borderColor: '#707070' }} /> : null
        }
      </Box>
    );
  }
};
