import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

export const StudyTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(234, 236, 245, 1)",
    fontSize: "16px",
    fontFamily: 'NanumSquare',
    height:"30px",
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    color: "#101010",
    fontFamily: 'NanumSquare',
    height:"30px",
    fontWeight: 'normal',
  },
}));

export const StudySubTitle = styled(Typography)({
  color: '#101010',
  fontFamily: 'NanumSquare',
  fontSize: '16px',
});

export const StudyLoadingTypography= styled(Typography)({
  fontSize: "14px",
  color: "#101010",
  fontFamily: 'NanumSquare',
  height:"20px",
  fontWeight: 'normal',
});