import { Component } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography'

type knotProps = BoxProps & {}

const B = styled(Box)({
  width: '24px',
  border: '1px solid #333333',
  borderRadius: '3px',
})
const T = styled(Typography)({
  color: '#707070',
  fontFamily: 'Roboto',
  fontSize: '10px',
})

export default class Knot extends Component<knotProps> {
  render() {
    return (
      <B {...this.props} >
        <T align="center">#{this.props.children}</T>
      </B>
    );
  }
}
