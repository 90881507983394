import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import '../assets/css/EngDic.css';

export interface EngDicState{
  content: string;
  isLoading : boolean;
}

export interface SearchEngDicArg {
  content: any;
}

const initialState : EngDicState = {
  content:"",
  isLoading: false,
}

export const EngDicSlice = createSlice({
  name:"engdic",
  initialState,
  reducers: {
    closeEngDic:(state) =>{
      state.content = "";
    },
    foundEngDic:(state, action: PayloadAction<SearchEngDicArg>) => {
      console.log("[EngDic] state:", state, "action:", action);
      state.content = action.payload.content;
      state.isLoading = false;
    },
    setLoading:(state, action) => {
      state.isLoading = action.payload.loading;
    }
  }
})



export const searchEngDic  = (word: string) :AppThunk => async ( dispatch, getState ) => {
  
  const data = {
      word : word
  }

  const engdic = httpsCallable(functions, 'engdic-engdic');
  
  dispatch(setLoading({loading: true}));

  engdic(data).then( function (result: any) {
    console.log(result);
    const arg:SearchEngDicArg = {
      content : result.data.content
    }
    console.log("[searchEngDic]," ,getState());
    dispatch(foundEngDic(arg));
    return ;
  }).catch((e:any)=>{
    console.log("[searchEngDic] ERROR " ,e.message);
    dispatch(setLoading({loading: false}));
  });;
}

export const selectEngDic = (state: RootState) => state.engdic;
export const {foundEngDic, closeEngDic, setLoading} = EngDicSlice.actions;

export default EngDicSlice.reducer;