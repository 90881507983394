import React, { useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { useNavigate } from 'react-router-dom';
import {findPassword} from '../../store/authSlice'
import { Box, Button, Container, Divider, Grid, Stack, styled, TextField, Typography } from '@mui/material';
import Title from '../../components/common/Title';
import ConfirmDialog, { DialogState } from '../../components/ConfirmDialog';
import LeftSideTitle from '../../components/LeftSideTitle';
import { LabelTypography, StyledButton, StyledTextField } from '../../components/Profile';




type InfoPopup  = DialogState &  {
    name: string;
}

export default function PasswordModify() {
    
    const [email, setemail] = useState<string>('')
    const [infoPopup, setInfoPopup] = useState<InfoPopup>({open:false, name:""});
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const contents = [
      "회원가입 시 입력하신 이메일로", 
      "비밀번호 변경 링크를 전송해 드립니다.",
      "링크를 통해 비밀번호를 변경하시면 됩니다."
    ]


    let regExp = /^\w[\w\-.]*@\w+\.\w{2,}/

    const modify = async() => {
        console.log("modify");

       dispatch(findPassword(email, (values)=>{
            console.log(values);
            if (values === "success") {
                setInfoPopup({title:"안내", content:"회원가입 시 입력한 이메일로 비밀번호 변경 링크를 전송해드렸습니다.", confirm:"확인", open:true, name:""});
            } else {
                setInfoPopup({title:"안내", content:"등록된 이메일이 없습니다.", confirm:"확인", open:true, name:""});
            }
        }));
        
    }

    const return_login = async() => {
        navigate('/signIn')
    }

    return(
      <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={6}>
        <LeftSideTitle bigTitle='FINDING A PASSWORD' title="비밀번호 찾기" contents={contents}></LeftSideTitle>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Box component="div" display="flex" alignItems="center" sx={{ height: '100vh', backgroundColor: '#f4f5fa' }}>
          <Stack alignItems="center" direction="column" maxWidth="md" sx={{ pl: 10, width: "60%", mt: -20 }} spacing={4} >
            <Stack alignItems="left" direction="column" maxWidth="md" sx={{ width: "90%" }} spacing={2} >
             
              <Stack direction="row" spacing={1}><LabelTypography >이메일</LabelTypography></Stack>
              <StyledTextField placeholder="이메일을 입력하세요." id="tf_user_nickname" variant="standard" size="small" fullWidth={true} value={email} onChange={(event) => setemail(event.currentTarget.value)}></StyledTextField>
        
            
           
        
            </Stack>
            <Grid container spacing={1} columns={{ xs: 4 }} justifyContent="center">
                <Grid item xs={3}><StyledButton variant="contained" size="medium" fullWidth={true} onClick={modify}>비밀번호 찾기</StyledButton></Grid>
                <Grid item xs={1}><StyledButton variant="outlined" size="medium" fullWidth={true} onClick={(e) => { navigate("/") }} >취소</StyledButton></Grid>
              </Grid>
          </Stack>

          <ConfirmDialog title={infoPopup.title} content={infoPopup.content} confirm={infoPopup.confirm} open={infoPopup.open}
            onClose={() => {
              setInfoPopup({title:"",  content:".", confirm:"", open:false, name:""})
        if (infoPopup.name === "popup_userinfo") {
            navigate('/password_modify');
        }
            }}
            onConfirm={() => {
              setInfoPopup({title:"",  content:".", confirm:"", open:false, name:""})
        if (infoPopup.name === "popup_userinfo") {
            navigate('/password_modify');
        }
            }}
          ></ConfirmDialog>
        </Box>
      </Grid>
    </Grid>
    
    
    );
}
    
