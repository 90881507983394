import {useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import VideoPlayer from "./video";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import {getLectureVideoAsync, selectVideo , clearLectureVideo} from '../store/lectureSlice';
import { StatLecture, saveLecture } from '../store/statSlice';

const CloseButton = styled(Button)({
  float:"right",
  color: "#ffffff",
  fontSize: 14,
  minWidth: 27,
  minHeight: 22,
  padding:0,
}); 


interface ILectureplayerProps {
  knot?: string;
  open: boolean;
  onClose: () => void;
  // onSave?: (currentTime: number, duration: number) => void
  save?: boolean;
}

const Lectureplayer: React.FC<ILectureplayerProps> = (props) => {
  const lectureVideo = useAppSelector(selectVideo);
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(props.open);
  const [save, setSave ] = useState<boolean>(true);

  useEffect(() => {
    console.log("userEffect open:", props.open, ", knot:" , props.knot, "save:", props.save);
    if (props.open !== open) {
      setOpen(props.open);
      if (props.open) {
        dispatch(getLectureVideoAsync(props.knot)).then((result) => {
         setOpen(true);
        });
      }
    } 

    if (props.save !== undefined) {
      setSave(props.save);
    }
  }, [dispatch, open, props.open, props.knot, props.save]);

  const handleClose = () => {
    console.log('[[handleClose]]');
    props.onClose();
  };

  const handlePlayerReady = (player: any) => {
    // you can handle player events here
    player.on('play', () => {
      console.log('player is playing', player.currentTime(), player.duration());
    });

    player.on('waiting', () => {
      console.log('player is waiting');
    });

    player.on('dispose', () => {
      console.log('player will dispose');
      // props.onSave && props.onSave(player.currentTime(), player.duration());

      _saveLecture(player.currentTime(), player.duration());

      dispatch(clearLectureVideo());
    });
  };

  const _saveLecture = (currentTime: number, duration: number) => {
    if (!save) {
      return;
    }
    const s: StatLecture = {
      knot:  lectureVideo.knot,
      knotTitle: lectureVideo.title,
      lectureId: lectureVideo.id,
      title: lectureVideo.title,
      link: lectureVideo.link,
      date: new Date(),
      currentTime: currentTime,
      duration: duration,
    }
    dispatch(saveLecture(s)).then(() => {
    }).catch((error: any) => {
      console.error('saveLecture: error:', error);
    });
  }

  const lecVideoPlayer = (url:string) => {
    console.log("lecVideoPlayer url :" , url);
    if (url && url.length >= 0 )
     {
      return (      <VideoPlayer options={{
        sources: [
          {
            src: url,
            type: "video/mp4"
          }
        ],
      }
      } 
      onReady={handlePlayerReady}/>);
    } 
  }

  console.log("lectureVideo Link :" , lectureVideo.link);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle >
        {lectureVideo.title}
        <CloseButton aria-label="close" color="primary" onClick={handleClose} sx={{ m: 0 }}>
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
         {lecVideoPlayer(lectureVideo.link)}
      </DialogContent>
    </Dialog>
  );
}

export default Lectureplayer;



