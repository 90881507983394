import * as React from 'react';
import { logEvent } from 'firebase/analytics';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

import Pagination from "react-js-pagination";

import LineCalendar from '../../components/LineCalendar';
import SyntaxDialog from '../../components/SyntaxDialog';

import { withDispatch } from '../../router';
import { analytics, auth } from '../../firebase';
import { KnotExample, getKnotExamples, GetParam, paginationCount, UserCollection, StatExample, getExamplesOnDate } from '../../store/statSlice';

import { StudyTableCell, StudySubTitle, StudyLoadingTypography } from './StudyStyle';
import {CircularProgress, Typography, Stack} from '@mui/material';
import { Statement } from 'typescript';

interface appProps {
  refresh: string,
  navigate(to: string, option?: any): any
  dispatch(to: any): any
}

interface appState {
  refresh: string,
  list: Array<StatExample>,
  open: boolean,
  knot: string,
  //
  date: Date,
  count: number,
  page: number,
  rowsPerPage: number,
  isLoading : boolean,
  selectNumber: string,
}

export class Example extends React.Component<appProps, appState> {
  constructor (props : appProps) {
    super(props);
    this.state = {
      refresh: undefined,
      list: [],
      open: false,
      selectNumber: '',
      knot: '',
      //
      date: new Date(),
      count: 0,
      page: 0,
      rowsPerPage: 10,
      isLoading: true
    }
  }

  componentDidMount() {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        this.props.navigate('/signIn');
        return;
      }

      const param: GetParam = {
        date: this.state.date,
        page: 0,
        rowsPerPage: this.state.rowsPerPage,
      }

      const count = await paginationCount(UserCollection.Examples, param);
      this.setState({count: count});

      await this.getKnotExamples(this.state.date, this.state.page, this.state.rowsPerPage);
     

    });
  }

  static getDerivedStateFromProps(nextProps:any, prevState :any) {
    if (nextProps.refresh !== prevState.refresh) {
      return { 
        refresh: nextProps.refresh,
       };
    }
    return null;
  }

  async getKnotExamples(date: Date, page: number, rowsPerPage: number) {
    const param: GetParam = {
      date: date,
      page: page,
      rowsPerPage: rowsPerPage,
    }

    // await this.props.dispatch(getKnotExamples(param)).then((result: any) => {
    //   if (result && result.payload) {
    //     const knots: Array<KnotExample> = result.payload;
    //     knots.forEach((k: KnotExample) => {
    //       this.state.list.push(k);
    //       this.setState({ refresh : '1' });
    //     });
    //   }
    // }).catch((error: Error) => {
    //   console.error('getKnotExamples: error:', error);
    // });

    await this.props.dispatch(getExamplesOnDate(param)).then((result: any) => {
      if (result && result.payload) {
        console.log(result.payload);

      if (result && result.payload) {
        const knots: Array<StatExample> = result.payload;
        knots.forEach((k: StatExample) => {
          this.state.list.push(k);
          this.setState({ refresh : '1' });
        });
      }
      }
    }).catch((error: Error) => {
      console.error('getExamplesOnDate: error:', error);
    });

    this.setState({isLoading: false});
  }

  render() {
    const handleDateChange = async (date: Date) => {
      const param: GetParam = {
        date: date,
        page: 0,
        rowsPerPage: this.state.rowsPerPage,
      }
      this.setState({isLoading: true})
      const count = await paginationCount(UserCollection.Examples, param);
      this.setState({count: count});

      this.setState({date: date});
      this.setState({page: 0});
      this.setState({list: []});
      this.getKnotExamples(date, this.state.page, this.state.rowsPerPage);
    }

    const handleOpen = (v: StatExample) => {
      logEvent(analytics, 'select_item', {
        item_list_name: 'example',
        item_list_id: v.knot,
      });
      this.setState({ 
        open : true,
        knot: v.knot,
        selectNumber: v.number,
      });
    }

    const handleClose = () => {
      this.setState({ open: false })
    }

    const handleChangePage = (event: unknown, newPage: number) => {
      this.setState({isLoading: true});
      this.setState({page: newPage});
      this.setState({list: []});
      this.getKnotExamples(this.state.date, newPage, this.state.rowsPerPage);
    }

    const handlePageChange = (newPage: number) => {
      newPage = newPage - 1;
      this.setState({isLoading: true});
      this.setState({page: newPage});
      this.setState({list: []});
      this.getKnotExamples(this.state.date, newPage, this.state.rowsPerPage);
    }

    const getTableRows = (list:StatExample[])  =>{

      if (this.state.isLoading) {
        return (          
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <StudyTableCell component="th" scope="row" sx={{pl:"3%"}}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <CircularProgress  size={20} />
              <StudyLoadingTypography justifyContent="center">로딩 중...</StudyLoadingTypography>
            </Stack>
            </StudyTableCell>
          </TableRow>
        )
      }

      if (list.length > 0) {
        return (
          this.state.list.map((row) => (
            <TableRow
              key={row.knot}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() => {handleOpen(row)}}
            >
              <StudyTableCell component="th" scope="row" sx={{pl:"3%"}}> #{row.knot} {row.knotTitle} - {row.number} {row.exampleTitle} </StudyTableCell>
            </TableRow>
          ))
        )
      } else {
        return (
            <TableRow
              key={0}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StudyTableCell component="th" scope="row" align='center'>학습한 구문이 없습니다.</StudyTableCell>
            </TableRow>
        );
      }
    }

    return (
      <Box component="div" className="StudyBody">
        <Container maxWidth="lg" sx={{ my:1 }}>
          <StudySubTitle>유사구문</StudySubTitle>
          <Divider sx={{  my: 1, borderBottomWidth: 1, backgroundColor:"rgba(90, 98, 173, 1)", marginBottom:"-10px" }} />
          <TableContainer component={Paper} className="Table">
            <Table sx={{ minWidth: 650 }} size="small" stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StudyTableCell colSpan={2}>
                    <LineCalendar date={this.state.date} onChange={handleDateChange}></LineCalendar>
                  </StudyTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getTableRows(this.state.list)}
              </TableBody>
            </Table>
          </TableContainer>
          {/*
          <TablePagination
            rowsPerPageOptions={[this.state.rowsPerPage]}
            component="div"
            count={this.state.count}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
          */}
          <Pagination 
            activePage={this.state.page+1} 
            itemsCountPerPage={10} 
            totalItemsCount={this.state.count} 
            pageRangeDisplayed={5} 
            prevPageText={<button className="lt">&lt;</button>} 
            nextPageText={<button className="gt">&gt;</button>} 
            firstPageText={<button className="lt">&lt;&lt;</button>}
            lastPageText={<button className="gt">&gt;&gt;</button>}
            onChange={handlePageChange}
            hideDisabled={(this.state.list.length <=0 ? true: false)}
          />
        </Container>

        <SyntaxDialog open={this.state.open} knot={this.state.knot} save={false} number={this.state.selectNumber} onClose={()=>{handleClose();}} />
      </Box>
    );
  }
}

export default withDispatch(Example);
