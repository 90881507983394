import { Component, Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from '../views/Layout';
import PhraseInput from '../views/phrase/PhraseInput';
import PhraseResult from '../views/phrase/PhraseResult';

// 학습노트(study)
// import Study from '../views/study/Study';
import StudyLayout from '../views/study/StudyLayout';
import StudyStat from '../views/study/Stat';
import StudyPhrase from '../views/study/Phrase';
import StudyQuiz from '../views/study/Quiz';
import StudyLecture from '../views/study/Lecture';
import StudyExample from '../views/study/Example';

import Main from '../views/Main';
import Profile from '../views/profile/Profile';
import EditProfile from '../views/profile/EditProfile';

import SignUp from '../views/auth/SignUp';
import SignIn from '../views/auth//SignIn';
import SignInNaver from'../views/auth/SignInNaver';
import SignInKakao from'../views/auth/SignInKakao';
import PasswordModify from'../views/auth/PasswordModify';
import AuthAction from'../views/auth/Action';

import Terms from'../views/policy/Terms';
import Privacy from'../views/policy/Privacy';
import Qa from'../views/policy/Qa';
import Notices from'../views/policy/Notices';
import Detail from'../components/Detail';

// Sample Module
import { Counter } from '../components/sample/Counter';
import TestView  from '../views/TestView';

// lazy Module

// Sample lazy Module
const Skeleton = lazy(() => import('../views/Skeleton'));
const SkeletonSub = lazy(() => import('../components/sample/Sub'));

export default class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Main />} />
              <Route path="signUp" element={<SignUp />} />
              <Route path="signIn" element={<SignIn />} />
              <Route path="passwordModify" element={<PasswordModify/>} />
              {/* <Route path="/passwordReset" element={<PasswordReset/>}/> */}
              <Route path="profile" element={<Profile />} />
              <Route path="profile/edit" element={<EditProfile />} />
              <Route path="terms" element={<Terms/>}/>
              <Route path="privacy" element={<Privacy/>}/>
              <Route path="qa" element={<Qa/>}/>
              <Route path="notices" element={<Notices/>}/>
              <Route path="notices/detail/:number" element={<Detail/>}/>
              {/* 임시 테스트 페이지 */}              
              <Route path="testView" element={<TestView />} /> 
            </Route>

            <Route path="/phrase" element={<Layout />}>
              <Route index element={<PhraseInput />} />
              <Route path=":id" element={<PhraseResult />} />
            </Route>

            <Route path="/auth" element={<Layout />}>
              <Route path="callback/naver" element={<SignInNaver />} />
              <Route path="callback/kakao" element={<SignInKakao />} />
              <Route path="action" element={<AuthAction />} />
            </Route>

            <Route path="/study" element={<Layout />}>
              {/* 
              <Route path="study" element={<Study />} />
              */}
              <Route path="" element={<StudyLayout />}>
                <Route index element={<StudyStat />} />
                <Route path="stat" element={<StudyStat />} />
                <Route path="phrase" element={<StudyPhrase />} />
                {/* <Route path="quiz" element={<StudyQuiz />} /> */}
                <Route path="lecture" element={<StudyLecture />} />
                <Route path="example" element={<StudyExample />} />
              </Route>
            </Route>

            <Route path="/skeleton" element={<Skeleton />}>
              <Route path="counter" element={<Counter />} />
              <Route path=":token" element={<SkeletonSub/>} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    );
  }
}
