import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import counterReducer from './counterSlice';
import authReducer from './authSlice';
import gnbReducer from './gnbSlice';
import lectureReducer from './lectureSlice';
import statReducer from './statSlice';
import engDicReducer from './engDicSlice';
import phraseReducer from './phraseSlice';
import noticeReducer from './policySlice'

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
    gnb: gnbReducer,
    lecture: lectureReducer,
    stat: statReducer,
    engdic: engDicReducer,
    phrase: phraseReducer,
    notice: noticeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
