import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { analytics } from '../../firebase';

import { useAppDispatch } from '../../store/hooks';
import { sample } from '../../store/phraseSlice';

import IconTitle from '../../components/common/IconTitle';
import BoxHeader from '../../components/common/BoxHeader';

import styles from '../../assets/css/PhraseInput.module.css';
import GuideImage from '../../assets/image/guide.png';
import { ReactComponent as IcMark } from '../../assets/image/icon/mark.svg';

import { inputSample } from '../../api/mock';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 280,
  height: 70,
  bgcolor: 'background.paper',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    borderRadius: 0,
    backgroundColor: '#FFFFFF',
    padding: '25px 32px 8px',
    ':hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  '& .MuiInputBase-input': {
    backgroundColor: '#FFFFFF',
  },
  '& .MuiFilledInput-root.Mui-focused': {
    backgroundColor: '#FFFFFF',
  },
});

export default function Phrase() {
  const dispatch = useAppDispatch();

  let [analysing, setAnalysing] = React.useState(false);
  let [showModal, setShowModal] = React.useState(false);
  let navigate = useNavigate();

  function analysis() {
    logEvent(analytics, 'search', { search_term: inputSample[0] });
    dispatch(sample(inputSample));
    setAnalysing(true);
    setShowModal(true);
  }

  function gotoResult() {
    setAnalysing(false);
    setShowModal(false);
    navigate("/phrase/result");
  }

  return (
    <Fragment>
      <Box className={styles.Container}>
        <IconTitle className={styles.Title} icon={<IcMark />}>구문 입력</IconTitle>
        <Box className={styles.Phrase}>
          <BoxHeader>분석할 내용을 입력하세요. 문장 마침 부호를 꼭 찍어주세요.</BoxHeader>
          <StyledTextField className={styles.mini} fullWidth autoFocus multiline minRows={2} maxRows={3} inputProps={{style: { fontFamily: "NotoSerifKR", fontSize: "18px", fontWeight: 'bold', color: "#101010", lineHeight: "30px" }}} variant="filled" defaultValue={inputSample[0]} />
          <StyledTextField className={styles.large} fullWidth autoFocus multiline minRows={5} maxRows={7} inputProps={{style: { fontFamily: "NotoSerifKR", fontSize: "18px", fontWeight: 'bold', color: "#101010", lineHeight: "30px" }}} variant="filled" defaultValue={inputSample[0]} />
          <Box className={styles.ButtonArea}>
            <Button className={styles.Button} onClick={analysis} variant="contained" disabled={analysing}>
              <Typography variant="button">구문분석 시작</Typography>
            </Button>
          </Box>
        </Box>
        <Box className={styles.GuideArea}>
          <Box component="img" src={GuideImage} width="80%" sx={{maxWidth: "1000px"}}/>
        </Box>
      </Box>
      <Modal
        open={showModal}
        onClose={gotoResult}
      >
        <Box sx={modalStyle}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <CircularProgress />
            <Typography id="phrase-modal-description">분석중입니다.</Typography>
          </Stack>
        </Box>
      </Modal>
    </Fragment>
  );
}
