import { Component } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ProfileBGImage from '../assets/image/profile_bg.png';

const BigTitleTypography = styled(Typography)({
  color: '#ffffff',
  fontSize: 50,
  lineHeight: 1,
  fontFamily: 'NanumSquare',
});

const TitleTypography = styled(Typography)({
  color: '#101010',
  fontSize: 22,
  fontFamily: 'NanumSquare',
});

const SubtitleTypography = styled(Typography)({
  color: '#ff101010ffff',
  fontSize: 18,
  fontFamily: 'NanumSquare',
  fontWeight:'normal',
});

interface TitleProps {
  bigTitle?: string,
  title: string,
  subTitle?: string,
  contents?: Array<string>,
};

export default class LeftSideTitle extends Component<TitleProps> {
  render() {
    return (
        <Box component="div" display="flex" alignItems="center" justifyContent="left" sx={{ width: '100%', height: '100%', backgroundImage: `url(${ProfileBGImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <Stack direction="column" alignItems="left" sx={{ ml: '15%' }}>
            <BigTitleTypography > <b>{this.props.bigTitle}</b></BigTitleTypography>
            <TitleTypography  sx={{mt:2}} > <b>{this.props.title}</b></TitleTypography>
            <SubtitleTypography>{this.props.subTitle}</SubtitleTypography>
            {
              this.props.contents?.map((st) => (
                <SubtitleTypography>{st}</SubtitleTypography>
              ))
            }
          </Stack>
        </Box>
    );
  }
}
