import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    adjective: Palette['primary'];
    noun: Palette['primary'];
    adverb: Palette['primary'];
    etc: Palette['primary'];
  }
  interface PaletteOptions {
    adjective: PaletteOptions['primary'];
    noun: PaletteOptions['primary'];
    adverb: PaletteOptions['primary'];
    etc: PaletteOptions['primary'];
  }
}

export const white = createTheme({
  palette: {
    primary: {
      main: '#5A62AD',
    },
    secondary: {
      main: '#F4F5FA',
    },
    adjective: {
      main: '#FEEA34',
    },
    noun: {
      main: '#91CCF0',
    },
    adverb: {
      main: '#EAB7CF',
    },
    etc: {
      main: '#C7C7C7',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Arial',
      'sans-serif',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'NotoSerifKR',
    ].join(','),
    h1: {
      fontSize: 22,
      fontFamily: 'NotoSerifKR',
      fontWeight: 900,
    },
    h2: {
      fontSize: 16,
      fontFamily: 'NotoSerifKR',
      fontWeight: 900,
    },
    h3: {
      fontSize: 18,
      fontFamily: 'NotoSerifKR',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: 12,
      fontFamily: 'NotoSerifKR',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: 12,
      fontFamily: 'NanumSquare',
    },
    h6: {
      fontSize: 14,
      fontFamily: 'NotoSerifKR',
      fontWeight: 'bold',
    },
    subtitle1: {
      fontSize: 22,
      fontFamily: 'NotoSerifKR',
      fontWeight: 900,
    },
    subtitle2: {
      fontSize: 16,
      fontFamily: 'NotoSerifKR',
      fontWeight: 900,
    },
    body1: {
      fontSize: 18,
      fontFamily: 'NotoSerifKR',
      fontWeight: 'bold',
    },
    body2: {
      fontSize: 17,
      fontFamily: 'NotoSerifKR',
      fontWeight: 'bold',
    },
    button: {
      fontSize: 16,
      fontFamily: 'NotoSerifKR',
      fontWeight: 'bold',
    },
  },
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root:{
          backgroundColor: '#5a62ad',
          color: '#ffffff',
          fontSize: 14,
          padding: 10,
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper:{
          borderWidth: 2,
          borderColor: "#5a62ad",
          borderStyle: "solid",
          borderRadius:0,
        }
      }
    },
    MuiDialogContent:{
      styleOverrides:{
        root:{
          marginTop:20,
          marginLeft:20,
          marginBottom:20,
          marginRight:20,
          borderTop: 0,
          borderBottom:0,
          fontsize:26,
          textAlign:"center",
        }
      }
    },
    MuiDialogActions:{
      styleOverrides:{
        root:{
          justifyContent:"center",
          marginBottom:30,
        }
      }
    },
    MuiTabs:{
      styleOverrides:{
        indicator:{
          height:6,
        }
      }
    }
  },
});
