import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

type Pages = "terms" | "privacy" | "qa" | "notices";

export default function Footer() {
  const navigate = useNavigate();

  function goto(menu: Pages) {
    switch (menu) {
    case "terms":
      navigate('/terms');
      break;
    case "privacy":
      navigate('/privacy');
      break;
    case "qa":
      navigate('/qa');
      break;
    case "notices":
      navigate('/notices');
      break;
    }
  }

  return (
    <Box className="Container">
      <Stack className="NaviArea" spacing={2} direction="row" justifyContent="center" alignItems="center" divider={<Divider orientation="vertical" sx={{ height: '12px' }} />} >
        <Button variant="text" onClick={() => goto("terms")}>
          <Typography variant="h5" color="primary">이용약관</Typography>
        </Button>
        <Button variant="text" onClick={() => goto("privacy")}>
          <Typography variant="h5" color="primary">개인정보처리방침</Typography>
        </Button>
        <Button variant="text" onClick={() => goto("qa")}>
          <Typography variant="h5" color="primary">문의하기</Typography>
        </Button>
        <Button variant="text" onClick={() => goto("notices")}>
          <Typography variant="h5" color="primary">공지사항</Typography>
        </Button>
        <Typography variant="h5" color="primary">Copyrightⓒ2021 by (주)제네시스에듀</Typography>
      </Stack>
      <Typography variant="h5" color="primary">* 본 사이트는 최신 브라우저(해상도 1920*1080)에 최적화되어 있습니다.</Typography>
    </Box>
  );
}
