import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Content, Sentence, mockApi } from '../api/mock';
import { RootState } from './store';

export type Part = "adjectiveP" | "adjective" | "nounP" | "noun" | "adverbP" | "adverb" | "specialP" | "coordinator";

export interface PhraseState {
  status: 'idle' | 'loading' | 'success';
  sentences?: Array<Sentence>; // TODO - split with phrase
  selected: number;

  element: boolean,
  translate: boolean,

  maxLayer: number,
  layerDepth: number,

  showParts: { [name in Part]: boolean};
  countParts: { [name in Part]: number};
}

const initialState: PhraseState = {
  status: 'idle',

  element: true,
  translate: true,
  selected: 0,

  maxLayer: 0,
  layerDepth: 0,

  showParts: {
    adjectiveP: true,
    adjective: true,
    nounP: true,
    noun: true,
    adverbP: true,
    adverb: true,
    specialP: true,
    coordinator: true,
  },
  countParts: {
    adjectiveP: 0,
    adjective: 0,
    nounP: 0,
    noun: 0,
    adverbP: 0,
    adverb: 0,
    specialP: 0,
    coordinator: 0,
  },
};

interface partArgs {
  name: Part,
  value: boolean,
}

export const sample = createAsyncThunk(
  'phrase/sample',
  async (sample: Array<string>) => {
    return await mockApi.post(sample);
  }
);

function countPart(contents: Array<Content>, part: Part): number {
  return contents.filter((value) => value.part != null && value.part === part).length
}
function maxLayer(contents: Array<Content>): number {
  let max = 0;
  contents.forEach((value) => max = Math.max(max, value.layer));
  return max;
}

export const phraseSlice = createSlice({
  name: 'phrase',
  initialState,
  reducers: {
    changeElement: (state, action: PayloadAction<boolean>) => {
      state.element = action.payload;
    },
    changeTranslate: (state, action: PayloadAction<boolean>) => {
      state.translate = action.payload;
    },
    incrementLayer: (state) => {
      if (state.layerDepth < state.maxLayer) {
        state.layerDepth += 1;
      }
    },
    decrementLayer: (state) => {
      if (state.layerDepth > 0) {
        state.layerDepth -= 1;
      }
    },
    switchParts: (state, action: PayloadAction<partArgs>) => {
      state.showParts[action.payload.name] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sample.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sample.fulfilled, (state, action) => {
        state.status = 'success';
        state.sentences = action.payload;
        state.selected = 0;
        let content = action.payload[0].content;
        state.countParts.adjectiveP = countPart(content, "adjectiveP");
        state.countParts.adjective = countPart(content, "adjective");
        state.countParts.nounP = countPart(content, "nounP");
        state.countParts.noun = countPart(content, "noun");
        state.countParts.adverbP = countPart(content, "adverbP");
        state.countParts.adverb = countPart(content, "adverb");
        state.countParts.specialP = countPart(content, "specialP");
        state.countParts.coordinator = countPart(content, "coordinator");
        let max = maxLayer(content);
        state.maxLayer = max;
        state.layerDepth = max;
      });
  },
});

export const { changeElement, changeTranslate, incrementLayer, decrementLayer, switchParts } = phraseSlice.actions;

export default phraseSlice.reducer;
