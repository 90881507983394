import { Component } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export type MenuName = "lecture" | "syntax" | "quiz";

type KnotMenuProps = {
  anchorEl: any,
  open: boolean,
  onClose: (menu?: MenuName) => void,
}

export default class KnotMenu extends Component<KnotMenuProps> {
  render() {
    return (
      <Menu
        anchorEl={this.props.anchorEl}
        open={this.props.open}
        onClose={this.props.onClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
        transformOrigin={{vertical: 'top', horizontal: 'center',}}
      >
        <MenuItem onClick={() => this.props.onClose("lecture")}>구문 강의</MenuItem>
        <MenuItem onClick={() => this.props.onClose("syntax")}>유사 구문</MenuItem>
        { /** <MenuItem onClick={() => this.props.onClose("quiz")}>문제 풀이</MenuItem> **/ }
      </Menu>
    );
  }
}
