import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';

import Title from '../../components/common/Title';
import ImageButton from '../../components/ImageButton';

import { withDispatch } from '../../router';

import studyLogo from '../../assets/image/icon/study.svg';

const GrayButtonInit = styled(Button)({
  fontSize: '16px',
  fontFamily: 'NanumGothic',
  borderColor: '#dcdcdc',
  color: '#101010',
  backgroundColor: '#dcdcdc',
  '&:hover': {
    color: '#101010',
    backgroundColor: '#dcdcdc',
  },
});

const GrayButton = styled(Button)({
  fontSize: '16px',
  fontFamily: 'NanumGothic',
  borderColor: '#dcdcdc',
  color: '#101010',
  backgroundColor: '#ffffff',
  '&:hover': {
    borderColor: '#dcdcdc',
    color: '#101010',
  },
  '&:active': {
    color: '#101010',
    backgroundColor: '#dcdcdc',
  },
  '&:focus': {
    color: '#10101g',
    backgroundColor: '#dcdcdc',
  },
});

const StudyIconButton = styled(Button)({
  color: 'none',
  backgroundColor: 'rgba(244, 245, 250, 1)',
  boxShadow: 'none',
  '&:hover': {
    color: 'none',
    backgroundColor: 'rgba(244, 245, 250, 1)',
    boxShadow: 'none',
  },
  '&:active': {
    color: 'none',
    backgroundColor: 'rgba(244, 245, 250, 1)',
    boxShadow: 'none',
  },
  '&:focus': {
    color: 'none',
    backgroundColor: 'rgba(244, 245, 250, 1)',
    boxShadow: 'none',
  },
});

enum StudyTabs {
  None = '',
  Stat = 'stat',
  Phrase = 'phrase',
  // Quiz = 'quiz',
  Lecture = 'lecture',
  Example = 'example',
}

type StudyProps = {
  tab?: string;
  navigate(to: string, option?: any): any
  dispatch(to: any): any
}

type StudyState = {
  tab?: string;
}

export class StudyHeader extends React.Component<StudyProps, StudyState> {

  constructor (props : StudyProps) {
    super(props);
    this.state = {
      tab : StudyTabs.Stat,
    }
  }

  componentDidMount() {
    const t = this.parseTabFromUrl()
    this.switchTab(t);
  }

  parseTabFromUrl() {
    const pList = window.location.pathname.split('/')
    let tab = 'stat';
    if (pList.length === 3) {
      tab = pList[2];
    }
    return tab;
  }

  getVariant(t: string) {
    if (t === this.state.tab) {
      return 'contained';
    }
    return 'outlined';
  }

  getStyle(t: string) {
    if (t === this.state.tab) {
      return {
        sx: {
          fontSize: '16px',
          fontFamily: 'NanumGothic',
          borderColor: '#dcdcdc',
          color: '#ffffff',
          backgroundColor: 'rgba(158, 163, 207, 1)',
          '&:hover': {
            borderColor: '#dcdcdc',
            color: '#101010',
            backgroundColor: 'rgba(158, 163, 207, 1)',
          },
          '&:active': {
            color: '#ffffff',
            backgroundColor: 'rgba(158, 163, 207, 1)',
          },
          '&:focus': {
            color: '#ffffff',
            backgroundColor: 'rgba(158, 163, 207, 1)',
          },
        },
      };
    }
  }

  switchTab(t: string){
    this.setState({ tab : t });

    switch(t) {
      case StudyTabs.Stat:
        this.props.navigate('/study/stat');
        return;
      case StudyTabs.Phrase:
        this.props.navigate('/study/phrase');
        return;
      // case StudyTabs.Quiz:
      //   this.props.navigate('/study/quiz');
      //   return;
      case StudyTabs.Lecture:
        this.props.navigate('/study/lecture');
        return;
      case StudyTabs.Example :
        this.props.navigate('/study/example');
        return;
      default:
        this.props.navigate('/study/stat');
        return;
    }
  }

  render() {
    const buttons = () => {
      let buttons;
      buttons =
        <Stack direction="row" spacing={2}>
          <GrayButton {...this.getStyle(StudyTabs.Stat)} variant={this.getVariant(StudyTabs.Stat)} size="small" fullWidth={true} onClick={(e) => {this.switchTab(StudyTabs.Stat)}}>학습현황</GrayButton>
          <GrayButton {...this.getStyle(StudyTabs.Phrase)} variant={this.getVariant(StudyTabs.Phrase)} size="small" fullWidth={true} onClick={(e) => {this.switchTab(StudyTabs.Phrase)}}>구문분석</GrayButton>
          {/* <GrayButton {...this.getStyle(StudyTabs.Quiz)} variant={this.getVariant(StudyTabs.Quiz)} size="small" fullWidth={true} onClick={(e) => {this.switchTab(StudyTabs.Quiz)}}>구문문제</GrayButton> */}
          <GrayButton {...this.getStyle(StudyTabs.Lecture)} variant={this.getVariant(StudyTabs.Lecture)} size="small" fullWidth={true} onClick={(e) => {this.switchTab(StudyTabs.Lecture)}}>구문강의</GrayButton>
          <GrayButton {...this.getStyle(StudyTabs.Example)} variant={this.getVariant(StudyTabs.Example)} size="small" fullWidth={true} onClick={(e) => {this.switchTab(StudyTabs.Example)}}>유사구문</GrayButton>
        </Stack>
      ;

      return buttons;
    }

    return (
      <Box component="div" className="StudyHeader">
        <Container maxWidth="lg" sx={{my:5}}>
          {/* 
          <StudyIconButton disableRipple>
            <IconStudy />
            <Title title="학습노트" icon={false} divider={false} />
          </StudyIconButton>
          */}
          {/*
          <Grid container spacing={1} columns={{ xs: 8 }} justifyContent="left">
            <ImageButton src={studyLogo} alt="studyLogo" width="20px" height="20px" borderRadius="0%" />
            <Title title="학습노트" icon={false} divider={false} style={{marginLeft:"-10px"}} />
          </Grid>
          <Grid container spacing={1} alignItems="center" sx={{marginTop:"-30px",}}>
          */}
          <Stack spacing={1} direction="row" alignItems="center" justifyContent="left">
            <Box component="img" src={studyLogo} alt="studyLogo" sx={{width:"26px", height:"26px", borderRadius:"0%"}}/>
            <Title title="학습노트" icon={false} divider={false} />
          </Stack>
          <Grid container xs ={12} spacing={1} mt={1} alignItems="center">
            <Grid item xs={9}>
              {buttons()}
            </Grid>
          </Grid>
        </Container>
      </Box>
    );
  }
}

export default withDispatch(StudyHeader);
