import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

import Pagination from "react-js-pagination";

import LineCalendar from '../../components/LineCalendar';

import { withDispatch } from '../../router';
import { auth } from '../../firebase';
import { StatPhrase, getPhrases, GetParam, paginationCount, UserCollection } from '../../store/statSlice';
import { sample } from '../../store/phraseSlice';
// import { savePhrase } from '../../store/statSlice'; // TODO

import { StudyTableCell, StudySubTitle, StudyLoadingTypography} from './StudyStyle';
import {CircularProgress, Stack} from '@mui/material';

interface appProps {
  refresh: string,
  navigate(to: string, option?: any): any
  dispatch(to: any): any
}

interface appState {
  refresh: string,
  list: Array<StatPhrase>,
  //
  date: Date,
  count: number,
  page: number,
  rowsPerPage: number,
  isLoading: boolean,
}

export class Phrase extends React.Component<appProps, appState> {
  constructor (props : appProps) {
    super(props);
    this.state = {
      refresh: undefined,
      list: [],
      //
      date: new Date(),
      count: 0,
      page: 0,
      rowsPerPage: 10,
      isLoading: true,
    }
  }

  componentDidMount() {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        return;
      }

      // TODO
      /*
      const k: StatPhrase = {
        index: 0,
        date: new Date(),
        phraseId: '',
        phrase: 'TODO TODO TODO phrase',
      }
      await this.props.dispatch(savePhrase(k)).then((result: any) => {
        console.log('TODO >>> savePhrase', result.payload);
      }).catch((error: Error) => {
        console.log('TODO >>> savePhrase: error:', error);
      });
      */

      const param: GetParam = {
        date: this.state.date,
        page: 0,
        rowsPerPage: this.state.rowsPerPage,
      }
      const count = await paginationCount(UserCollection.Phrases, param);
      this.setState({count: count});

      await this.getPhrases(this.state.date, this.state.page, this.state.rowsPerPage);
    });
  }

  static getDerivedStateFromProps(nextProps:any, prevState :any) {
    if (nextProps.refresh !== prevState.refresh) {
      return { 
        refresh: nextProps.refresh,
       };
    }
    return null;
  }

  async getPhrases(date: Date, page: number, rowsPerPage: number) {
    const param: GetParam = {
      date: date,
      page: page,
      rowsPerPage: rowsPerPage,
    }
    await this.props.dispatch(getPhrases(param)).then((result: any) => {
      if (result && result.payload) {
        const knots: Array<StatPhrase> = result.payload;
        knots.forEach((k: StatPhrase) => {
          this.state.list.push(k);
          this.setState({ refresh : '1' });
        });
      }
    }).catch((error: Error) => {
      console.error('getPhrases: error:', error);
    }).finally(()=>{
      this.setState({isLoading:false});
    });
  }

  render() {
    const handleDateChange = async (date: Date) => {
      const param: GetParam = {
        date: date,
        page: 0,
        rowsPerPage: this.state.rowsPerPage,
      }
      this.setState({isLoading: true});
      const count = await paginationCount(UserCollection.Phrases, param);
      this.setState({count: count});

      this.setState({date: date});
      this.setState({page: 0});
      this.setState({list: []});
      this.getPhrases(date, this.state.page, this.state.rowsPerPage);
    }

    const handleOpen = async (v: StatPhrase) => {
      const sentences = [
        v.phrase,
      ]
      await this.props.dispatch(sample(sentences));
      this.props.navigate("/phrase/result");
    }

    const handleChangePage = (event: unknown, newPage: number) => {
      this.setState({page: newPage});
      this.setState({list: []});
      this.getPhrases(this.state.date, newPage, this.state.rowsPerPage);
    }

    const handlePageChange = (newPage: number) => {
      newPage = newPage - 1;
      this.setState({isLoading: true});
      this.setState({page: newPage});
      this.setState({list: []});
      this.getPhrases(this.state.date, newPage, this.state.rowsPerPage);
    }

    const getTableRows = (list:StatPhrase[])  =>{

      if (this.state.isLoading) {
        return (          
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <StudyTableCell component="th" scope="row" sx={{pl:"3%"}}>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <CircularProgress  size={20} />
              <StudyLoadingTypography justifyContent="center">로딩 중...</StudyLoadingTypography>
            </Stack>
            </StudyTableCell>
          </TableRow>
        )
      }

      if (list.length > 0) {
        return (
          this.state.list.map((row) => (
            <TableRow
              key={row.index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              onClick={() => {handleOpen(row)}}
            >
              <StudyTableCell component="th" scope="row"> {row.index} </StudyTableCell>
              <StudyTableCell align="left"> {row.phrase} </StudyTableCell>
            </TableRow>
          ))
        );
      } else {
        return (
            <TableRow
              key={0}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <StudyTableCell component="th" scope="row" align='center'>분석한 구문이 없습니다. </StudyTableCell>
            </TableRow>
        );
      }
    }

    /*
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      const rowsPerPage = parseInt(event.target.value, 10);
      this.setState({rowsPerPage: rowsPerPage});
      this.setState({list: []});
      this.setState({page: 0});
      this.getPhrases(this.state.date, 0, rowsPerPage);
    }
    */

    /* TODO
    return (
      <Box component="div">
        <Title title="구문분석" icon={false} />
        <Container maxWidth="md" sx={{ my:1 }}>
          <div>
            Phrase
          </div>
        </Container>
      </Box>
    );
    */
    return (
      <Box component="div" className="StudyBody">
        <Container maxWidth="lg" sx={{ my:1 }}>
          <StudySubTitle>구문분석</StudySubTitle>
          <Divider sx={{  my: 1, borderBottomWidth: 1, backgroundColor:"rgba(90, 98, 173, 1)", marginBottom:"-10px" }} />
          <TableContainer component={Paper} className="Table">
            <Table sx={{ minWidth: 650 }} size="small" stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StudyTableCell colSpan={2}>
                    <LineCalendar date={this.state.date} onChange={handleDateChange}></LineCalendar>
                  </StudyTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getTableRows(this.state.list)}
              </TableBody>
            </Table>
          </TableContainer>
          {/*
          <TablePagination
            rowsPerPageOptions={[this.state.rowsPerPage]}
            component="div"
            count={this.state.count}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
          />
          */}
          <br/>
          <Pagination 
            activePage={this.state.page+1} 
            itemsCountPerPage={10} 
            totalItemsCount={this.state.count} 
            pageRangeDisplayed={5} 
            prevPageText={<button className="lt">&lt;</button>} 
            nextPageText={<button className="gt">&gt;</button>} 
            firstPageText={<button className="lt">&lt;&lt;</button>}
            lastPageText={<button className="gt">&gt;&gt;</button>}
            onChange={handlePageChange}
            hideDisabled={(this.state.list.length <=0 ? true: false)}
          />
        </Container>

      </Box>
    );
  }
}

export default withDispatch(Phrase);
