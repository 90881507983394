import { Component } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import styles from '../../assets/css/BoxSwitch.module.css';

const HVSwitch = styled(Switch)((props) => ({
  width: 35,
  height: 18,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    color: '#FFFFFF',
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#FFFFFF',
      '& + .MuiSwitch-track': {
        backgroundColor: props.id.startsWith("adjective") ? '#FEEA34'
          : props.id.startsWith("noun") ? '#91CCF0'
          : props.id.startsWith("adverb") ? '#EAB7CF'
          : '#C7C7C7',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: '#fff',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 18 / 2,
    backgroundColor: '#E2E4F1',
    opacity: 1,
  },
}));

const re = /.*P/
export default class BoxSwitch extends Component<SwitchProps> {
  render() {
    return (
      <Box className={this.props.id.match(re) ? styles.Background : styles.Background2}>
        <Box className={styles.Area}>
          <Typography variant="h4" sx={{ lineHeight: '32px' }}>{this.props.children}</Typography>
          <HVSwitch {...this.props} />
        </Box>
      </Box>
    );
  }
}
