

import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions'
import { connect } from "react-redux";
import { searchEngDic, closeEngDic } from "../store/engDicSlice";
import { RootState } from '../store/store';
import { Close, } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

import '../assets/css/EngDic.css';


const CloseButton = styled(Button)({
  float: "right",
  color: "#ffffff",
  fontSize: 14,
  minWidth: 27,
  minHeight: 22,
  padding: 0,
});

 const LoadingTypography= styled(Typography)({
  fontSize: '16px',
  color: "#101010",
  fontFamily: 'NanumSquare',
  height:"24px",
  fontWeight: 600,
});


type EngDicDialogState = {
  word: string;
  open: boolean;
  content: string;
}

type EngDicDialogProps = {
  closeEngDic: () => void;
  searchEngDic: (s: string) => void;
  word: string;
  open: boolean;
  content: string;
  isLoading: boolean;
  onClose(): void;
  onResult?: () => void;
}




class EngDicDialog extends React.Component<EngDicDialogProps, EngDicDialogState>{

  constructor(props: EngDicDialogProps) {
    super(props);
    this.state = {
      open: props.open,
      word: props.word,
      content: props.content,
    }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    console.log("getDerivedStateFromProps prevState :", prevState);
    console.log("getDerivedStateFromProps nextprops :", nextProps);

    if (nextProps.open !== prevState.open) {
      if (nextProps.open) nextProps.searchEngDic(nextProps.word);
      return {
        open: nextProps.open
      };
    }
    return null;
  }

  render() {
    const handleClose = () => {

      console.log("handleClose state :", this.state);
      console.log("handleClose props :", this.props);

      this.props.closeEngDic();

      if (this.props.onClose) {
        this.props.onClose();
      }
    };

    const displayContent = (isLoading:boolean, content:string) => {
      if (isLoading) {
        return (<Box>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <CircularProgress  size={20} />
        <LoadingTypography justifyContent="center">로딩 중...</LoadingTypography>
      </Stack></Box>);
      }
      return (<Box component='div' className="EngDicBox" dangerouslySetInnerHTML={{ __html:content }}></Box>);
    }

    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={this.props.open}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle >
          <Typography sx={{ width: '100%', flexShrink: 0, }}>
            영어사전
            <CloseButton aria-label="close" color="primary" onClick={handleClose} sx={{ m: 0 }}>
              <Close />
            </CloseButton>
          </Typography>
        </DialogTitle>
        <DialogContent  dividers  sx={{textAlign:'left', mb:"0px"}} >
          <Box component='div' className="EngDicBox" dangerouslySetInnerHTML={{ __html: this.props.content }}></Box>
          {displayContent(this.props.isLoading, this.props.content)}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>닫기</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  console.log("mapStateToProp:", state);
  return ({
    content: state.engdic.content,
    isLoading : state.engdic.isLoading
  })
};

export default connect(mapStateToProps, { closeEngDic, searchEngDic })(EngDicDialog);
