import { createSlice, createAsyncThunk,  PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { firestore } from '../firebase';
import { query, collection, getDocs, orderBy, where, limit} from "firebase/firestore";
import { addISOWeekYears } from 'date-fns';
import { decodedTextSpanIntersectsWith } from 'typescript';


export const termsText = (): AppThunk => async (dispatch) => {

    const first = query(collection(firestore, 'terms'), orderBy("date", "asc"));
    const documentSnapshots = await getDocs(first);
    console.log(documentSnapshots);

    let tmp: any;
    documentSnapshots.forEach((doc) => {
        const ddc = doc.data();
        tmp = ddc.text;
    })
    return tmp;
}


export const privacyText = (): AppThunk => async (dispatch) => {

    const first = query(collection(firestore, 'privacy'), orderBy("date", "asc")); 
    //orderby의 date가 반드시 있어야 오름차순으로 돌아감
    const documentSnapshots = await getDocs(first);
    console.log(documentSnapshots);

    let tmp: any;
    documentSnapshots.forEach((doc) => {
        const ddc = doc.data();
        tmp = ddc.text;
    })
    return tmp;
}


export const noticesText = (): AppThunk => async (dispatch) => {
    const first = query(collection(firestore, 'notices'), orderBy("date", "asc")); 
    //orderby의 date가 반드시 있어야 오름차순으로 돌아감
    const documentSnapshots = await getDocs(first);

    let tmp:any;
 
    let rows:any =[];
    documentSnapshots.forEach((docs) => {
        const ddc = docs.data();
        ddc.date = ddc.date.toDate().toLocaleDateString('ko-KR', {year:"numeric",month:"2-digit", day:"2-digit"});
        
        tmp = ddc;
      
        rows.push(tmp);
    })
    // for (let i = 1; i <= Math.ceil(text.length / 10); i++) {
    //     text.push(i);
    // }
    return rows;
}

export interface Notice   {
    id: number;
    title: string;
    content: string;
    date : string;
}


export const noticeCountAsync = createAsyncThunk(
    'notice/noticeCountAsync',
    
    async (id:number) => {
      const resultCount = await getNoticesCount(id);
      return resultCount;
    }
)


export async function getNoticesCount(id:number) {
    console.log("[[getNoticesCount]]");
    const q = query(collection(firestore, "notices"), where("id", ">=", id),orderBy("id", "asc"), limit(3));
    const querySnapshot = await getDocs(q);

    const noticeList: Array<Notice> = [];

    querySnapshot.forEach(async (doc) => {
      console.log(doc.id, " => ", doc.data());
      const docData = doc.data();
      const strDate = docData.date.toDate().toLocaleDateString('ko-KR', {year:"numeric",month:"2-digit", day:"2-digit"});

      const notice:Notice = {
          id: docData.id,
          title: docData.title,
          content : docData.content,
          date : strDate
      }
      
      noticeList.push(notice);
    });
    return noticeList;
}



// size를 없애면 fetchNoticeList를 dispatch 할때 Detail.tsx 에서 오류가생김(limit관련)
export const getNoticeAsync = createAsyncThunk(
    'notice/getNoticeAsync',
    
    async (id:number) => {
      const result = await fetchNoticeList(id);
      return result;
    }
)


// size:number
export async function fetchNoticeList(id:number) {
        
    
    console.log("[[fetchNoticeList]]");
    const q = query(collection(firestore, "notices")  ,orderBy("date", "asc")); //limit(size)
    const querySnapshot = await getDocs(q);

    const noticeList: Array<Notice> = [];

    querySnapshot.forEach(async (doc) => {
      console.log(doc.id, " => ", doc.data());
      const docData = doc.data();
      const strDate = docData.date.toDate().toLocaleDateString('ko-KR', {year:"numeric",month:"2-digit", day:"2-digit"});

      const notice:Notice = {
          id: docData.id,
          title: docData.title,
          content : docData.content,
          date : strDate
      }
      
      noticeList.push(notice);
    });
    return noticeList;
}



export interface NoticeState{
    
        list : Array<Notice>;
        // index : number;
        // pageSize: number;
    
}

// export interface NoticeArg {
//   index: number;
//   pageSize : number;
// }

const initialState : NoticeState = {
    
        list: [],
        // index : 0,
        // pageSize: 0,
    
}

export const NoticeSlice = createSlice({
    name:'notice',
    initialState,
    reducers: {
        setFetchList:(state, action: PayloadAction<NoticeState>) =>{
            state.list = action.payload.list;
        },
        resetFetchList: (state) => {
            state.list =[];
        }
    },
    extraReducers: {
        [getNoticeAsync.pending.type]: (state, action) => {
            console.log("getNoticeAsync pending", state,",", action);
        },
        [getNoticeAsync.fulfilled.type]: (state, action) => {
            console.log("getNoticeAsync fulfilled", state,",", action.payload);
            state.list = [...action.payload];
            // state.index = action.payload.index;
            // state.pageSize = action.payload.pageSize;
        },
        [getNoticeAsync.rejected.type]: (state, action) => {
            console.log("getNoticeAsync rejected", state,",", action);
            state = initialState;
        },
        [noticeCountAsync.pending.type]: (state, action) => {
            console.log("noticeCountAsync pending", state,",", action);
        },
        [noticeCountAsync.fulfilled.type]: (state, action) => {
            console.log("noticeCountAsync fulfilled", state,",", action.payload);
            state.list = [...action.payload];
            // state.index = action.payload.index;
            // state.pageSize = action.payload.pageSize;
        },
        [noticeCountAsync.rejected.type]: (state, action) => {
            console.log("noticeCountAsync rejected", state,",", action);
            state = initialState;
        },
    }
});



  


export const selectNotice = (state: RootState) => state.notice;
export const {setFetchList, resetFetchList} = NoticeSlice.actions;
export default NoticeSlice.reducer;


