import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import Button from '@mui/material/Button';
import { Close, Check, Brightness1Outlined } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { CardMedia, Card, CardHeader, DialogActions, Stack } from '@mui/material';
import { getQuizAync, selectQuiz } from '../store/lectureSlice';
import { StatQuiz, saveQuiz } from '../store/statSlice';
import { padding } from '@mui/system';

const CloseButton = styled(Button)({
  float: "right",
  color: "#ffffff",
  fontSize: 14,
  minWidth: 27,
  minHeight: 22,
  padding: 0,
});

const OptionButton = styled(Button) ({
  textAlign: 'left',
  fontsize:14,
  lineHeight: 1.2,
  justifyContent: 'left',
  color: '#101010',
  borderColor: '#dddddd',
  borderRadius: 2,
  borderWidth: 1,
  ":hover": {
    color: '#5a62ad',
    borderColor: '#dddddd',
  },
  ":disabled":{
    textAlign: 'left',
    fontsize:14,
    lineHeight: 1.2,
    justifyContent: 'left',
    color: '#101010',
    borderColor: '#dddddd',
    borderRadius: 2,
    borderWidth: 1,
  }
});

const OptionSelectedButton = styled(Button)({
  textAlign: 'left',
  fontsize:14,
  lineHeight: 1.2,
  justifyContent: 'left',
  color: '#5a62ad',
  borderColor: '#5a62ad',
  borderRadius: 2,
  borderWidth: 1,
  ":disabled":{
    textAlign: 'left',
    fontsize:14,
    lineHeight: 1.2,
    justifyContent: 'left',
    color: '#5a62ad',
    borderColor: '#5a62ad',
    borderRadius: 2,
    borderWidth: 1,
  }
});

const OkeyStyleButton = styled(OptionSelectedButton)({
  ":disabled":{
  color: '#348a15',
  borderColor: '#348a15', //348a15
  }
});


const WrongStyleButton = styled(OptionSelectedButton)({
  ":disabled":{
    textAlign: 'left',
    fontsize:14,
    lineHeight: 1.2,
    justifyContent: 'left',
    color: '#be0000',
    borderColor: '#be0000',
    borderRadius: 2,
    borderWidth: 1,
  }
});

interface QuizDialogProps {
  knot?: string;
  open: boolean;
  isQuiz?: "quiz" | "solution";
  pickIndex?: number;
  onClose: () => void;
  save?: boolean;
}


export default function QuizDialog(props: QuizDialogProps) {
  const status_quiz = 'quiz';
  const status_solution = 'solution';
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState<boolean>(props.open);
  const [quizId, setQuizId] = React.useState<string>(props.knot);
  const quiz = useAppSelector(selectQuiz);
  const [status, setStatus] = React.useState<string>((props.isQuiz === status_solution ? status_solution : status_quiz));
  const [pickIndex, setPickIndex] = React.useState<number>(props.pickIndex | -1);
  const [save, setSave] = useState<boolean>(true);


  React.useEffect(() => {
    console.log("isOpen:", props.open, open, "knot:", props.knot, "save:", props.save, "isQuiz", props.isQuiz);
    /*
    if (!open) {
      if (props.knot) {
        dispatch(getQuizAync(props.knot)).then((result: any) =>{
          console.log("result", result);
          if (result.payload) {
            setQuizId(result.payload.quizId);
          }
        });
      }
    }
    */

    if (props.open !== open) {
      setOpen(props.open);
      if (props.open && props.knot) {
        dispatch(getQuizAync(props.knot)).then((result: any) => {
          if (result.payload) {
            setQuizId(result.payload.quizId);
          }
        });
      }
    }

    if (props.save !== undefined) {
      setSave(props.save);
    }

    if (props.isQuiz !== undefined) {
      setStatus(props.isQuiz);
    }
  }, [dispatch, open, props.open, props.knot, props.save, props.isQuiz]);

  const handleClose = () => {
    setPickIndex(-1);
    setStatus(status_quiz);

    props.onClose();
  };

  const handleAction = () => {
    if (status === status_quiz) {
      setStatus(status_solution);

      const s: StatQuiz = {
        knot: props.knot,
        knotTitle: '',
        date: new Date(),
        quizId: quizId,
        question: quiz.question,
      }

      if (save) {
        dispatch(saveQuiz(s)).then(() => {
        }).catch((error: any) => {
          console.error('saveQuiz: error:', error);
        });
      }

    }
  }

  //startIcon={(i === pickIndex ? <Check /> : '')}

  const getOptionButtons = () => {
    const result = quiz.options.map((v, i) => {
      if (i == pickIndex)
        return (<OptionSelectedButton variant="outlined" size="medium" onClick={(e) => { setPickIndex(i) }}>{quiz.options[i]}</OptionSelectedButton>)
      else 
        return (<OptionButton variant="outlined" size="medium" onClick={(e) => { setPickIndex(i) }}>{quiz.options[i]}</OptionButton>)
    });
    return result;
  };

  const QuizContent = () => {
    if (!quiz || !quiz.question || quiz.question.length === 0) {
      return (
        <Stack spacing={2}>
          <Typography>퀴즈가 없습니다.</Typography>
        </Stack>)
    }
    return (
      <Stack spacing={2}>
        <Typography sx={{fontSize:20, textAlign:'left'}}  >{quiz.question}</Typography>
        <Stack spacing={1}>
        {getOptionButtons()}
        </Stack>
      </Stack>)
  };

  const getSolutionOptionButtons = () => {
    const result = quiz.options.map((v, i) => {

      var solved = "none";
      if (i === pickIndex && i === Number(quiz.answer)) {
        solved = "okay";
      } else if (i === pickIndex) {
        solved = "wrong";
      } else if (i === Number(quiz.answer)) {
        solved = "answer";
      }

      var variantValue: "text" | "outlined" | "contained" = "outlined";
      var icon = null;
      var button = null;
      switch (solved) {
        case "okay":
          button = (<OkeyStyleButton variant={variantValue} startIcon={icon}  disabled={true} >{quiz.options[i]}</OkeyStyleButton>);
          break;
        case "wrong":
          button = (<WrongStyleButton variant={variantValue} startIcon={icon}  disabled={true} >{quiz.options[i]}</WrongStyleButton>);
          break;
        case "answer":
          button = (<OkeyStyleButton variant={variantValue} startIcon={icon}  disabled={true} >{quiz.options[i]}</OkeyStyleButton>);
          break;
        default:
          button = (<OptionButton variant={variantValue} startIcon={icon} disabled={true} >{quiz.options[i]}</OptionButton>);
      }

      return button;
    });
    return result;
  };


  const SolutionContent = () => {
    console.log("SolutionContent", quiz);
    return (
      <Stack spacing={2}>
        <Typography sx={{fontSize:20, textAlign:'left'}}>{quiz.question}</Typography>
        <Stack spacing={1}>
        {getSolutionOptionButtons()}
        </Stack>
        <Typography sx={{fontSize:20, textAlign:'left'}}>해설</Typography>
        <Card>
          <CardHeader
            title={quiz.solution.title}
            subheader={quiz.solution.content}
          />
          <CardMedia
            component="img"
            height="100%"
            image={quiz.solution.imageLink}></CardMedia>
        </Card>
      </Stack>
    );
  };

  const ContentView = (status: string) => {
    if (status === status_solution) {
      return SolutionContent();
    }

    return QuizContent();
  }

  const ActionView = (status: string) => {
    if (!quiz || !quiz.question || quiz.question.length === 0) {
      return;
    }
    if (status === status_quiz) {
      return (
        <DialogActions>
          <Button variant="outlined" onClick={handleAction}>정답확인</Button>
        </DialogActions>
      );
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle  >
        <Typography sx={{ width: '100%', flexShrink: 0,  }}>
          문제풀이
          <CloseButton aria-label="close" color="primary" onClick={handleClose} sx={{ m: 0 }}>
            <Close />
          </CloseButton>
        </Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 1 }} >
        {ContentView(status)}
      </DialogContent>
      {ActionView(status)}
    </Dialog>
  );
}