import { Fragment } from 'react';

import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import { Outlet } from 'react-router';

import '../assets/css/Layout.css';

export default function Layout() {
  return (
    <Fragment>
      <section className="Header">
        <Header />
      </section>
      <section className="Content">
        <section className="Container">
          <Outlet />
        </section>
      </section>
      <section className="Footer">
        <Footer />
      </section>
    </Fragment>
    );
}
