import Title from '../../components/common/Title';
import { Box, Button, Container, Divider, Grid, Stack, TextField, } from "@mui/material";
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import { useState, useRef } from 'react';
import { getCurrentUserInfo } from '../../store/authSlice';
import { auth } from '../../firebase';
import { functionsAPI } from '../../api/functionsAPI';
import footer_logos from '../../assets/image/icon/footer_logos.svg';
import Typography from '@mui/material/Typography';


const StyledButton = styled(Button)({
    fontSize: 16,
    fontFamily: 'NanumSquare',
    fontWeight: 'bold'
})

export const StyledTextField = styled(TextField)({
    "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
        "-webkit-text-fill-color": "#bdbdbd",
        fontWeight: 'bold'
    },
    "& .MuiInputBase-input.MuiInput-input": {
        "-webkit-text-fill-color": "#000000",
        fontWeight: 'bold'
    },
    "& .MuiInputBase-root.MuiInput-root.Mui-disabled:before": {
        "border-bottom-style": "solid",
        "border-bottom-width": "1px",
        "border-bottom-color": "#dddddd"
    },
    "& .MuiInputBase-root.MuiInput-root:hover:before": {
        "border-bottom-style": "solid",
        "border-bottom-width": "2px",
        "border-bottom-color": "#5a62ad",
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: 'white',
        borderRadius: 0,
        '&:hover fieldset': {
            borderWidth: 2,
            borderColor: "#5a62ad",
        },
    },
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: 'NanumSquare',
});


const Input = styled('input')`
    display: 'none',
    color: #ffffff,
   font-family: 'NanumGothic',
   font-size: 15px,
   font-weight: bold,
   `;

const StyledGridItemTitle = styled(Grid)({
    display: "flex",
    paddingLeft: "3%",
    alignItems: 'center',
    backgroundColor: '#eaecf5',
    height: "8vh",
    border: "1px solid #dddddd"
});

const StyledGridItem = styled(StyledGridItemTitle)({
    backgroundColor: '#ffffff',
    paddingRight: "3%",
    border: "1px solid #dddddd"
});



export default function Qa() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [email, setEmail] = useState<string>('');
    const [nickName, setNickName] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [email2, setEmail2] = useState<string>('');
    const [maintext, setMaintext] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState(null);

    const uploadInputRef = useRef(null);

    const terms = { version: '20210101_TODO', content: '' };

    auth.onAuthStateChanged((user) => {
        // console.log("onAuthStateChanged : user"+ JSON.stringify(user));
        if (user) {
            currentUser();
        } else {
            navigate('/signIn');
        }
    });

    const currentUser = async () => {
        const userInfo: any = await dispatch(getCurrentUserInfo());
        // console.log("user3" + JSON.stringify(userInfo));
        // setEmail(user3 as AuthState.email);
        setEmail(userInfo.email);
        setEmail2(userInfo.email);
        setNickName(userInfo.nickName);
    }


    const onSubmit = (e: any) => {
        const formData = new FormData();

        // 배열 내부에 있는 모든 요소를 append 해야 하므로
        for (let i = 0; i < selectedFile.length; i++) {
            formData.append("files", selectedFile[i], selectedFile[i].name);
            console.log(selectedFile[i].name);
        }

        formData.append("id", email);
        formData.append("title", title);
        formData.append("email", email2);
        formData.append('nickname', nickName);
        formData.append('content', maintext);

        functionsAPI.QnAPost(formData).then((res) => {

        });

    }

    const handleFileChange = (e: any) => {
        // files 배열 자체를 담을 것이므로 index 뺌.
        setSelectedFile(e.currentTarget.files);
    };


    return (
        <Box component="div">
            <Container maxWidth="lg" sx={{ my:'5vh' }}>
                <Stack spacing={1} direction="row" alignItems="center" justifyContent="left">
                    <Box component="img" src={footer_logos} alt="QaLogo" sx={{ width: "26px", height: "26px", borderRadius: "0%" }} />
                    <Title title="문의하기" icon={false} divider={false} />
                </Stack>

                <Grid container spacing={0} alignItems="center" mt="2vh">
                    <StyledGridItemTitle item display='flex' xs={3} >
                        <Typography variant='body2'>아이디</Typography>
                    </StyledGridItemTitle>
                    <StyledGridItem item display='flex' xs={9} >
                        <Typography variant='body2'>{email} </Typography>
                    </StyledGridItem>

                    <StyledGridItemTitle item xs={3} >
                        <Typography variant='body2'>닉네임</Typography>
                    </StyledGridItemTitle>
                    <StyledGridItem item xs={9}>
                        <Typography variant='body2'>{nickName} </Typography>
                    </StyledGridItem>

                    <StyledGridItemTitle item xs={3}>
                        <Typography variant='body2'>회신 E-mail</Typography>
                    </StyledGridItemTitle>
                    <StyledGridItem item xs={9}>
                        <StyledTextField name="getemail" variant="outlined" size="small" fullWidth={true}
                            value={email2} placeholder="이메일을 입력하세요." onChange={(event) => setEmail2(event.currentTarget.value)}> </StyledTextField>
                    </StyledGridItem>

                    <StyledGridItemTitle item xs={3}>
                        <Typography variant='body2'>제목</Typography>
                    </StyledGridItemTitle>
                    <StyledGridItem item xs={9}>
                        <StyledTextField name="title" variant="outlined" size="small" fullWidth={true}
                            value={title} placeholder="제목을 입력하세요." onChange={(event) => setTitle(event.currentTarget.value)}> </StyledTextField>
                    </StyledGridItem>

                    <StyledGridItemTitle item xs={3} sx={{ height: "20vh" }}>
                        <Typography variant='body2'>내용</Typography>
                    </StyledGridItemTitle>
                    <StyledGridItem item xs={9} sx={{ height: "20vh" }}>
                        <StyledTextField name="textbox" variant="outlined" size="small" fullWidth={true}
                            value={maintext} placeholder="문의할 내용을 입력하세요." multiline minRows="5" maxRows="5"
                            defaultValue={terms.content} onChange={(event) => setMaintext(event.currentTarget.value)}></StyledTextField>
                    </StyledGridItem>
                    <StyledGridItemTitle item xs={3}>
                        <Typography variant='body2'>첨부파일</Typography>
                    </StyledGridItemTitle>
                    <StyledGridItem item xs={9}>
                        <Stack spacing={0} direction="column">
                        <Stack spacing={0} direction="row" display='flex' alignItems='center'>
                            <Input ref={uploadInputRef} accept="image/*" type="file" onChange={handleFileChange} multiple style={{ display: "none" }} />
                            <Button size='small' onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                                variant="contained" sx={{ width: "70px" }}>파일 선택</Button>
                                <Typography sx={{fontSize:"12px", ml:"10px", color:"#8c8c8c"}}>
                                    {(!selectedFile || selectedFile.length ===0 ?"선택한 파일 없음" :(selectedFile.length === 1? selectedFile[0].name: '첨부된 파일 '+ selectedFile.length +'개'))}
                                </Typography>
                                </Stack>
                            <Typography sx={{mt:"4px", fontSize:"12px", ml:"10px", color:"#8c8c8c"}}>총 20mb이하 이미지 파일만 가능합니다.(png, jpg, bmp, gif 등)</Typography>
                        </Stack>
                    </StyledGridItem>

                    <Grid item mt={3} xs={12} >
                        <Box alignItems='center' justifyContent='center' display='flex'>
                            <StyledButton variant="contained" size="medium" onClick={onSubmit} sx={{ width: "300px" }}>
                                등록</StyledButton>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
