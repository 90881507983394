import React, { Component, Fragment, useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { useAppSelector } from '../store/hooks';
import type { Part } from '../store/phraseSlice';

import { Content } from '../api/mock';

import Knot from './Knot';

import { ReactComponent as Left } from '../assets/image/icon/left.svg';
import { ReactComponent as Right } from '../assets/image/icon/right.svg';
import styles from '../assets/css/Phrase.module.css';

const FormTypography = styled(Typography)({
  color: '#FFF',
  margin: 0,
});
const StyledTypography = styled(Typography)({
  color: '#101010',
});
const AdjectiveTypography = styled(StyledTypography)(({ theme }) => ({
  backgroundColor: theme.palette.adjective.main,
}));
const NounTypography = styled(StyledTypography)(({ theme }) => ({
  backgroundColor: theme.palette.noun.main,
}));
const AdverbTypography = styled(StyledTypography)(({ theme }) => ({
  backgroundColor: theme.palette.adverb.main,
}));
const EtcTypography = styled(StyledTypography)(({ theme }) => ({
  backgroundColor: theme.palette.etc.main,
}));
const StyledTableCell = styled(TableCell)({
  padding: '10px 2px',
  verticalAlign: 'bottom',
  border: 'none',
});
const BorderTableCell = styled(StyledTableCell)({
  border: '1px solid #e4e4ed',
});

type syntaxProps = PhraseProps & {
  knot: Content,
}

export function Syntax(props: syntaxProps) {
  const showParts = useAppSelector((state) => state.phrase.showParts);
  function getParts(name: string) {
    return showParts[name as Part];
  }

  const hidden = <Box className={styles.Hidden} />;
  return (
    <Box className={styles.Spacing} onClick={(e) => props.selectKnot(props.knot.knot, e.target)}>
      <Knot className={styles.Bottom}>{props.knot.knot}</Knot>
      <Box sx={{ position: "relative" }}>
        { props.knot.part.startsWith('adjective') && <AdjectiveTypography align="center" variant="body2" noWrap>{props.knot.content}</AdjectiveTypography> }
        { props.knot.part.startsWith('noun') && <NounTypography align="center" variant="body2" noWrap>{props.knot.content}</NounTypography> }
        { props.knot.part.startsWith('Adverb') && <AdverbTypography align="center" variant="body2" noWrap>{props.knot.content}</AdverbTypography> }
        { (props.knot.part === "speicalP" || props.knot.part === "coordinator") && <EtcTypography align="center" variant="body2" noWrap>{props.knot.content}</EtcTypography> }
        { !getParts(props.knot.part) && hidden }
      </Box>
    </Box>
  );
}

export class Element extends Component {
  render() {
    return (
      <Fragment>
        <Box className={styles.Element}>
          <Left width="8" fill="#5A62AD" />
          <Divider sx={{ flex: 1, borderColor: "#5A62AD" }} />
          <Right width="8" fill="#5A62AD" />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: "-4px" }}>
          <Box className={styles.FormBox}>
            <FormTypography align="center" variant="h6" noWrap>{this.props.children}</FormTypography>
          </Box>
        </Box>
      </Fragment>
    );
  }
}

export type PhraseProps = {
  selectKnot: (knot: string, anchorEl: any) => void,
  body?: React.RefObject<null>,
}
function Phrase(props: PhraseProps) {
  const [ sentences, depth ] = useAppSelector((state) => [
    state.phrase.sentences,
    state.phrase.layerDepth,
  ]);
  const container = useRef(null);

  useEffect(() => {
    container.current.scroll(0, Number.MAX_SAFE_INTEGER);
  }, [sentences]);

  function makeLayer(layer: number) {
    let content = sentences[0].content;
    return content.map((value) => {
      if (layer == Math.min(value.layer, depth)) {
        return value;
      } else {
        return null;
      }
    });
  }
  function viewLayer(value: Content) {
    if (value != null) {
      if (value.knot == null) {
        return <BorderTableCell>
                 <StyledTypography variant="body2" noWrap>{value.content}</StyledTypography>
               </BorderTableCell>
      }
      else {
        return <BorderTableCell>
                 <Syntax knot={value} selectKnot={props.selectKnot} />
               </BorderTableCell>
      }
    }
    return <BorderTableCell />
  }
  function makeElement() {
    let element = new Map();
    let content = sentences[0].content;
    content.forEach((value) => {
      if (element.has(value.element)) {
        element.set(value.element, element.get(value.element) + 1);
      } else {
        element.set(value.element, 1);
      }
    });
    return element;
  }
  function viewElement(value: number, key: string) {
    return <StyledTableCell colSpan={value} key={key}><Element>{key}</Element></StyledTableCell>;
  }

  let layer = [];
  let element;
  if (sentences != null) {
    for ( let i=depth; i>=0; i-- ) {
      let pData = makeLayer(i);
      layer.push(
        <TableRow key={i}>
          {pData.map((value) => viewLayer(value))}
        </TableRow>
      );
    }
    let eData = makeElement();
    element = <TableRow key="last">
                {Array.from(eData).map(([key, value]) => viewElement(value, key))}
              </TableRow>
  }
  return (
    <Box className={styles.Phrase}>
      <TableContainer ref={container} className={styles.Container}>
        <Table className={styles.Table}>
          <TableBody ref={props.body}>
            { layer }
            { element }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
export default Phrase;
