import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { getDocs, query, collection, where, orderBy} from "firebase/firestore";
import { firestore } from '../firebase';


export interface LectureVideoState {
  id: string | null;
  knot: string | null;
  title: string | null;
  link: string | null;
}


export const getLectureVideoAsync = createAsyncThunk(
  'lecture/getLectureVideoAsync',
  async (knot: string) => {
    const result = await getLectureVideo(knot);
    return result;
  }
);


export interface ExampleState {
  id: string | null;
  knot: string | null;
  knotTitle:string | null;
  number: string | null;
  title: string | null;
  imageLink: string | null;
  isRead: boolean | null;
}

export const getExamplesAync = createAsyncThunk(
  'lecture/getExamplesAync',
  async (knot: string) => {
    const result = await getLectureExamples(knot);
    return result;
  }
)

async function getLectureVideo(knot: string) {
  const result: LectureVideoState = {
    id: "",
    knot: "",
    title: "",
    link: "",
  };

  const q = query(collection(firestore, "lectures"), where("knot", "==", knot));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    console.log(doc.id, " => ", doc.data());
    const data = doc.data();
    result.id = doc.id;
    result.knot = data.knot;
    result.title = data.title;
    result.link = data.link;
  });

  return result;
}

async function getLectureExamples(knot: string) {
  const result: Array<ExampleState> = [];
  var knotTitle = "";
  const q = query(collection(firestore, "lectures"), where("knot", "==", knot));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (doc) => {
    console.log(" EE ",doc.id, " => ", doc.data());
    const data = doc.data();
    knotTitle = data.title;
  });

  const q2 = query(collection(firestore, `examples`), where("knot", "==", knot));
  const querySnapshot1 = await getDocs(q2);
  querySnapshot1.forEach(async (doc) => {
    console.log(doc.id, " => ", doc.data());
    const docData: ExampleState = doc.data() as ExampleState;
    docData.id = doc.id;
    docData.knotTitle = knotTitle;
    result.push(docData);
  });

  return result;
}


export const getQuizAync = createAsyncThunk(
  'lecture/getQuizAync',
  async (knot: string) => {
    const result = await getLectureQuiz(knot);
    return result;
  }
)

export async function getLectureQuiz(knot: string) {

  const q2 = query(collection(firestore, `quizzes`), where("knot", "==", knot));
  const querySnapshot1 = await getDocs(q2);

  let result: QuizState;
  querySnapshot1.forEach(async (doc) => {
    console.log(doc.id, " => ", doc.data());
    const docData: QuizState = doc.data() as QuizState;
    result = docData;
    result.quizId = doc.id;
  });

  return result;
}

export interface LectureState {
  video: LectureVideoState;
  examples: ExampleState[];
  quiz: QuizState;
}

export interface solutionState {
  title: string | null;
  content: string | null;
  imageLink: string | null;
}


export interface QuizState {
  quizId: string;
  question: string;
  options: string[];
  answer: string;
  solution: solutionState;
}


const initialState: LectureState = {
  video: {
    id: "",
    knot: "",
    title: "",
    link: "",
  },
  examples: [],
  quiz: {
    quizId: "",
    question: "",
    options: [],
    answer: "",
    solution: {
      title: null,
      content: null,
      imageLink: null,
    }
  },
}

export const lectureSlice = createSlice({
  name: "lecture",
  initialState,
  reducers: {
    clearLectureVideo: (state) => {
      console.log("[[ clearLectureVideo ]]");
      state.video.id = "";
      state.video.knot = "";
      state.video.title = "";
      state.video.link = "";
    }
  },
  extraReducers: {
    [getLectureVideoAsync.pending.type]: (state, action) => {
      console.log("getLectureVideoAsync pending", state,",", action);
    },
    [getLectureVideoAsync.fulfilled.type]: (state, action) => {
      console.log("getLectureVideoAsync fulfilled", state,",", action);
      state.video.link = action.payload.link;
      state.video.knot = action.payload.knot;
      state.video.title = action.payload.title;
      state.video.id = action.payload.id;
    },
    [getLectureVideoAsync.rejected.type]: (state, action) => {
      console.log("getLectureVideoAsync rejected", state,",", action);
    },
    [getExamplesAync.pending.type]: (state, action) => {
      console.log("getExamplesAync pending", state,",", action);
    },
    [getExamplesAync.fulfilled.type]: (state, action) => {
      console.log("getExamplesAync fulfilled", state,",", action);
    },
    [getExamplesAync.rejected.type]: (state, action) => {
      console.log("getExamplesAync rejected", state,",", action);
    },
    [getQuizAync.pending.type]: (state, action) => {
      console.log("getQuizAync pending", state,",", action);
    },
    [getQuizAync.fulfilled.type]: (state, action) => {
      console.log("getQuizAync fulfilled", state,",", action);
      state.quiz.question = '';
      state.quiz.answer = '';
      state.quiz.options = [];
      state.quiz.solution = {
        title: null,
        content: null,
        imageLink: null,
      }
      if (action.payload) {
        state.quiz.question = action.payload.question;
        state.quiz.answer = action.payload.answer;
        state.quiz.options = action.payload.options;
        state.quiz.solution = {...action.payload.solution};
      }
    },
    [getQuizAync.rejected.type]: (state, action) => {
      console.log("getQuizAync rejected", state,",", action);
    },
  }
});

export const selectQuiz = (state: RootState) => state.lecture.quiz;
export const selectExamples = (state: RootState) => state.lecture.examples;
export const selectVideo = (state:RootState) => state.lecture.video;


export const {clearLectureVideo} = lectureSlice.actions;

export default lectureSlice.reducer;