import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { logEvent } from 'firebase/analytics';

import { RootState } from './store';

import { analytics } from '../firebase';

export type Pages = 'main' | 'signup' | 'signin' | 'profile' | 'editProfile' | 'phrase' | 'phraseResult' | 'study' | 'studyPhrase' | 'studyLecture' | 'studyExample' | 'terms' | 'privacy' | 'qa' | 'notices' | 'noticesDetail';

export interface GnbState {
  page: Pages;
}

const initialState: GnbState = {
  page: 'main',
}

export const gnbSlice = createSlice({
  name: 'gnb',
  initialState,
  reducers: {
    changeLocation: (state, action: PayloadAction<string>) => {
      let path = action.payload;
      let page = path.split('/').map( (v) => v.toLowerCase() );

      switch (page[1]) {
        case 'signup':
        case 'signin':
        case 'terms':
        case 'privacy':
        case 'qa':
          state.page = page[1];
          break;
        case 'profile':
        case 'phrase':
        case 'study':
        case 'notices':
          if (page.length <= 2) {
            state.page = page[1];
          } else {
            switch (page[2]) {
              case 'edit':
                state.page = 'editProfile';
                break;
              case 'result':
                state.page = 'phraseResult';
                break;
              case 'phrase':
                state.page = 'studyPhrase';
                break;
              case 'lecture':
                state.page = 'studyLecture';
                break;
              case 'example':
                state.page = 'studyExample';
                break;
              case 'detail':
                state.page = 'noticesDetail';
                break;
            }
          }
          break;
        default:
          state.page = 'main';
          break;
      }
      logEvent(analytics, 'page_view', {
        page_title: state.page,
        page_path: path,
      });
    },
  },
});

export const { changeLocation } = gnbSlice.actions;

export default gnbSlice.reducer;
