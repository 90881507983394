import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'; 
import { RootState, AppThunk } from './store';
import { auth, firestore } from '../firebase';
import { getDocs, query, collection, limit, startAfter, orderBy, Query } from "firebase/firestore";
import { doc, setDoc, addDoc, getDoc, Timestamp, increment, where } from "firebase/firestore";

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
export enum UserCollection {
  Phrases = 'phrases',
  Quizzes = 'quizzes',
  Lectures = 'lectures',
  Examples = 'examples',
}

////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
export interface GetParam {
  date: Date;
  page: number;
  rowsPerPage: number;
}

// WholeKnot
export interface Knot {
  knot: string;
  knotTitle: string;
}

export interface StatKnot {
  knot: string;
  knotTitle: string;
  date: Date;
  count: number;
}

export interface StatPhrase {
  index: number,
  date: Date;
  phraseId: string,
  phrase: string;
}

export interface StatQuiz {
  knot: string;
  knotTitle: string;
  date: Date;
  quizId: string;
  question: string; // TODO
}

export interface StatLecture {
  knot: string;
  knotTitle: string;
  date: Date;
  lectureId: string;
  title: string; // TODO
  link: string; // TODO
  currentTime: number;
  duration: number;
}

export interface StatExample {
  knot: string;
  knotTitle: string;
  date: Date;
  exampleId: string;
  number: string;
  exampleTitle: string;
  totalCount: number;
}

export interface KnotExample {
  knot: string;
  knotTitle: string;
  list: Array<StatExample>; 
}

export interface StatStudy {
  knot : string; // 꼬리표 번호
  knotTitle: string; // 꼬리표 명
  pharseCount : number; // 구분분석 횟수 
  doneQuiz: boolean; // 퀴즈 풀이 여부 
  doneLecture:boolean; // 구문 강의 수강 여부 
  exampleCount: number; // 
  exampleTotalCount : number; 
}


///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
// https://medium.com/firebase-tips-tricks/how-to-count-documents-in-firestore-a0527f792d04
export async function paginationCount(userCollection: UserCollection, param: GetParam) {
  let count = 0;

  let startDate;
  let endDate;
  if (param.date) {
    startDate = new Date(param.date.getFullYear(), param.date.getMonth(), param.date.getDate());
    endDate = new Date(param.date.getFullYear(), param.date.getMonth(), param.date.getDate()+1);
  }

  // Query the first page of docs
  let first = query(collection(firestore, `users/${auth.currentUser.uid}/${userCollection}`), orderBy('date'), limit(param.rowsPerPage));
  if (param.date) {
    first = query(first, where("date", ">=", startDate), where("date", "<", endDate));
  }
  let documentSnapshots = await getDocs(first);
  count = documentSnapshots.docs.length;
  if (count < param.rowsPerPage) {
    return count;
  }

  while (true) {
    // Get the last visible document
    const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
    // Construct a new query starting at this document,
    // get the next.
    let q = query(collection(firestore, `users/${auth.currentUser.uid}/${userCollection}`), orderBy('date'), startAfter(lastVisible), limit(param.rowsPerPage));
    if (param.date) {
      q = query(q, where("date", ">=", startDate), where("date", "<", endDate));
    }
    documentSnapshots = await getDocs(q);
    const length = documentSnapshots.docs.length;
    if (length === 0) {
      break;
    }
    count += length;
    if (length < param.rowsPerPage) {
      break;
    }
  }

  return count
}

async function queryCollection(userCollection: UserCollection, param: GetParam) {
  let q;
  let index = 0;
  if (param) {
    let startDate;
    let endDate;
    if (param.date) {
      startDate = new Date(param.date.getFullYear(), param.date.getMonth(), param.date.getDate());
      endDate = new Date(param.date.getFullYear(), param.date.getMonth(), param.date.getDate()+1);
    }

    const pp = param.page * param.rowsPerPage
    if (pp === 0) {
      q = query(collection(firestore, `users/${auth.currentUser.uid}/${userCollection}`), orderBy('date'), limit(param.rowsPerPage));
      if (param.date) {
        q = query(q, where("date", ">=", startDate), where("date", "<", endDate));
      }
    } else {
      // Query the first page of docs
      let first = query(collection(firestore, `users/${auth.currentUser.uid}/${userCollection}`), orderBy('date'), limit(pp));
      if (param.date) {
        first = query(first, where("date", ">=", startDate), where("date", "<", endDate));
      }
      const documentSnapshots = await getDocs(first);

      // Get the last visible document
      const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length-1];
      index += documentSnapshots.docs.length;

      // Construct a new query starting at this document,
      // get the next.
      q = query(collection(firestore, `users/${auth.currentUser.uid}/${userCollection}`), orderBy('date'), startAfter(lastVisible), limit(param.rowsPerPage));
      if (param.date) {
        q = query(q, where("date", ">=", startDate), where("date", "<", endDate));
      }
    } 
  } else {
    q = query(collection(firestore, `users/${auth.currentUser.uid}/${userCollection}`), orderBy('date'));
  }

  return {q, index};
}

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
// WholeKnot
/*
export const copyWholeKnots = createAsyncThunk(
  'stat/copyWholeKnots',
  async () => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }
  
    let result: Array<Knot> = [];
  
    const q = query(collection(firestore, `lectures`), orderBy('knot'));
    const querySnapshot = await getDocs(q);
    for (let i = 0; i < querySnapshot.size; i++) {
      const d = querySnapshot.docs[i];
      const data = d.data();
      let docData: Knot = {
        knot: data.knot,
        knotTitle: data.title,
      }
  
      result.push(docData);
    }

    result.sort(function(a, b) {
      return Number(a.knot) - Number(b.knot);
    });
    for (let i = 0; i < result.length; i++) {
      const k = result[i];
      const docRef = doc(firestore, `knots/${k.knot}`);
      await setDoc(docRef, k);
    }
  
    // The value we return becomes the `fulfilled` action payload
    return Promise.all(result);
  }
);
*/

export const getWholeKnots = createAsyncThunk(
  'stat/getWholeKnots',
  async () => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }
  
    let result: Array<Knot> = [];
  
    const q = query(collection(firestore, `knots`), orderBy('knot'));
    const querySnapshot = await getDocs(q);
    for (let i = 0; i < querySnapshot.size; i++) {
      /*
      const d = querySnapshot.docs[i];
      let docData: Knot = d.data() as Knot;
      docData.knot = d.id;
      */
      const d = querySnapshot.docs[i];
      const data = d.data();
      let docData: Knot = {
        knot: d.id,
        knotTitle: data.knotTitle,
      }
  
      result.push(docData);
    }

    result.sort(function(a, b) {
      return Number(a.knot) - Number(b.knot);
    });
  
    // The value we return becomes the `fulfilled` action payload
    return Promise.all(result);
  }
);

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
// Knot
export const saveKnot = createAsyncThunk(
  'stat/saveKnot',
  async (s: StatKnot) => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }

    let date;
    if (s.date) {
      date = s.date;
    } else {
      date = new Date();
    }
  
    const docData = {
      date: Timestamp.fromDate(date),
      count: increment(s.count),
    };
  
    const docRef = doc(firestore, `users/${auth.currentUser.uid}/knots/${s.knot}`);
    await setDoc(docRef, docData, { merge: true });

    // The value we return becomes the `fulfilled` action payload
    return null;
  }
);
 

export const getKnots = createAsyncThunk(
  'stat/getKnots',
  async () => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }
  
    let result: Array<StatKnot> = [];
  
    const q = query(collection(firestore, `users/${auth.currentUser.uid}/knots`));
    const querySnapshot = await getDocs(q);
    /* 
    querySnapshot.forEach(async(d) => {
      let docData: StatKnot = d.data() as StatKnot;
      docData.knot = d.id;
  
      const docRef = doc(firestore, 'knots', r.knot);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        r.knotTitle = docSnap.data().knotTitle;
      }
      result.push(r);
    });

    // The value we return becomes the `fulfilled` action payload
    return result;
    */
    for (let i = 0; i < querySnapshot.size; i++) {
      /*
      const d = querySnapshot.docs[i];
      let docData: StatKnot = d.data() as StatKnot;
      docData.knot = d.id;
      */
      const d = querySnapshot.docs[i];
      const data = d.data();
      let docData: StatKnot = {
        knot: d.id,
        knotTitle: '',
        // date: data.date.toDate(),
        date: data.date.toDate().toString(),
        count: data.count,
      }
  
      const docRef = doc(firestore, 'knots', docData.knot);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        docData.knotTitle = docSnap.data().knotTitle;
      }
      result.push(docData);
    }
  
    // The value we return becomes the `fulfilled` action payload
    return Promise.all(result);
  }
);

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
// Phrase
export const savePhrase = createAsyncThunk(
  'stat/savePhrase',
  async (s: StatPhrase) => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }

    if (s) {
      let date;
      if (s.date) {
        date = s.date;
      } else {
        date = new Date();
      }
  
      const docData = {
        date: Timestamp.fromDate(date),
        phrase: s.phrase,
      };
  
      const c = collection(firestore, `users/${auth.currentUser.uid}/phrases`);
      await addDoc(c, docData);
    }

    // The value we return becomes the `fulfilled` action payload
    return null;
  }
);

export const getPhrases = createAsyncThunk(
  'stat/getPhrases',
  async (param: GetParam) => {
    try {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }

    let result: Array<StatPhrase> = [];

    const ret: {q: Query, index: number} = await queryCollection(UserCollection.Phrases, param);
    const querySnapshot = await getDocs(ret.q);
    for (let i = 0; i < querySnapshot.size; i++) {
      const d = querySnapshot.docs[i];
      const data = d.data();
      let docData: StatPhrase = {
        index: ++ret.index,
        // date: data.date.toDate(),
        date: data.date.toDate().toString(),
        phraseId: d.id,
        phrase: data.phrase,
      }
  
      result.push(docData);
    }
  
    // The value we return becomes the `fulfilled` action payload
    return Promise.all(result);
  } catch (e) {
    console.error(e);
  }
  }
);

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
// Quiz
export const saveQuiz = createAsyncThunk(
  'stat/saveQuiz',
  async (s: StatQuiz) => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }

    let date;
    if (s.date) {
      date = s.date;
    } else {
      date = new Date();
    }
  
    const docData = {
      knot: s.knot,
      date: Timestamp.fromDate(date),
      question: s.question, // TODO
    };
  
    const docRef = doc(firestore, `users/${auth.currentUser.uid}/quizzes/${s.quizId}`);
    await setDoc(docRef, docData);

    // The value we return becomes the `fulfilled` action payload
    return null;
  }
);

export const getQuizzes = createAsyncThunk(
  'stat/getQuizzes',
  async (param: GetParam) => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }
  
    const result: Array<StatQuiz> = [];
  
    const ret: {q: Query, index: number} = await queryCollection(UserCollection.Quizzes, param);
    const querySnapshot = await getDocs(ret.q);
    /* 
    querySnapshot.forEach((doc) => {
      let docData: StatQuiz = doc.data() as StatQuiz;
      docData.quizId = doc.id;
      result.push(docData);
    });
    return result;
    */
    for (let i = 0; i < querySnapshot.size; i++) {
      /* 
      const d = querySnapshot.docs[i];
      let docData: StatQuiz = d.data() as StatQuiz;
      docData.quizId = d.id;
      */
      const d = querySnapshot.docs[i];
      const data = d.data();
      let docData: StatQuiz = {
        knot: data.knot,
        knotTitle: '',
        // date: data.date?.toDate(),
        date: data.date?.toDate().toString(),
        quizId: d.id,
        question: data.question,
      }
  
      const docRef = doc(firestore, 'knots', docData.knot);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        docData.knotTitle = docSnap.data().knotTitle;
      }
      result.push(docData);
    }
  
    // The value we return becomes the `fulfilled` action payload
    return Promise.all(result);
  }
);

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
// Lecture
export const saveLecture = createAsyncThunk(
  'stat/saveLecture',
  async (s: StatLecture) => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }
  
    let date;
    if (s.date) {
      date = s.date;
    } else {
      date = new Date();
    }

    const docData = {
      knot: s.knot,
      title: s.title, // TODO
      link: s.link, // TODO
      date: date,
      currentTime: s.currentTime,
      duration: s.duration,
    };
  
    const docRef = doc(firestore, `users/${auth.currentUser.uid}/lectures/${s.lectureId}`);
    await setDoc(docRef, docData);

    // The value we return becomes the `fulfilled` action payload
    return null;
  }
);

export const getLectures = createAsyncThunk(
  'stat/getLectures',
  async (param: GetParam) => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }

    const result: Array<StatLecture> = [];
  
    const ret: {q: Query, index: number} = await queryCollection(UserCollection.Lectures, param);
    const querySnapshot = await getDocs(ret.q);
    /* 
    querySnapshot.forEach((doc) => {
      let docData: StatLecture = doc.data() as StatLecture;
      docData.lectureId = doc.id;
      result.push(docData);
    });
    return result;
    */
    for (let i = 0; i < querySnapshot.size; i++) {
      /* 
      const d = querySnapshot.docs[i];
      let docData: StatLecture = d.data() as StatLecture;
      docData.lectureId = d.id;
      */
      const d = querySnapshot.docs[i];
      const data = d.data();
      let docData: StatLecture = {
        knot: data.knot,
        knotTitle: '',
        lectureId: d.id,
        title: data.title,
        link: data.link,
        // date: data.date?.toDate(),
        date: data.date?.toDate().toString(),
        currentTime: data.currentTime,
        duration: data.duration,
      }
  
      const docRef = doc(firestore, 'knots', docData.knot);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        docData.knotTitle = docSnap.data().knotTitle;
      }
      result.push(docData);
    }

    // The value we return becomes the `fulfilled` action payload
    return Promise.all(result);
  }
);

///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
// Example
export const saveExample = createAsyncThunk(
  'stat/saveExample',
  async (s: StatExample) => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }

    let date;
    if (s.date) {
      date = s.date;
    } else {
      date = new Date();
    }
  
    const docData = {
      knot: s.knot,
      date: Timestamp.fromDate(date),
      number: s.number,
      totalCount: s.totalCount,
    };
  
    const docRef = doc(firestore, `users/${auth.currentUser.uid}/examples/${s.exampleId}`);
    await setDoc(docRef, docData);

    // The value we return becomes the `fulfilled` action payload
    return null;
  }
);


export const getExamples = createAsyncThunk(
  'stat/getExamples',
  async () => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }
  
    const result: Array<StatExample> = [];
  
    const q = query(collection(firestore, `users/${auth.currentUser.uid}/examples`));
    const querySnapshot = await getDocs(q);
    /* 
    querySnapshot.forEach((doc) => {
      let docData: StatExample = doc.data() as StatExample;
      docData.exampleId = doc.id;
      result.push(docData);
    });
    return result;
    */

    

    for (let i = 0; i < querySnapshot.size; i++) {
      /* 
      const d = querySnapshot.docs[i];
      let docData: StatExample = d.data() as StatExample;
      docData.exampleId = d.id;
      */
      const d = querySnapshot.docs[i];
      const data = d.data();
 

      let docData: StatExample = {
        knot: data.knot,
        knotTitle: '',
        // date: data.date?.toDate(),
        date: data.date?.toDate().toString(),
        exampleId: d.id,
        number: data.number,
        exampleTitle: "",
        totalCount: data.totalCount,
      }
  
      const docRef = doc(firestore, 'knots', docData.knot);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        docData.knotTitle = docSnap.data().knotTitle;
      }
      result.push(docData);
    }
  
    // The value we return becomes the `fulfilled` action payload
    return Promise.all(result);
  }
);

const  getListPhaseKnotCount = async () => {
  var knotList:any=[];
  const knotDocQuery = query(collection(firestore, `users/${auth.currentUser.uid}/knots`));
  const knotSnapshot = await getDocs(knotDocQuery);

  knotSnapshot.forEach((d)=>{
    knotList.push(d.data());
  })
  return knotList;
}

const getDoneQuizList = async () =>{
  var doneQuizList:any=[];

  const quizQuery = query(collection(firestore, `users/${auth.currentUser.uid}/quizzes`));
  const quizSnapshot = await getDocs(quizQuery);
  quizSnapshot.forEach((d)=>{
    doneQuizList.push(d.data());
  })

  return doneQuizList;
}

const getDoneLecture = async () =>{
  var doneLectureList:any=[];

  const lectureQuery = query(collection(firestore, `users/${auth.currentUser.uid}/lectures`));
  const lectureSnapshot = await getDocs(lectureQuery);
  lectureSnapshot.forEach((d)=>{
    doneLectureList.push(d.data());
  })
  return doneLectureList;
}

const getUserExamples = async () =>{
  var userExampleList:any=[];
  const examplesQuery = query(collection(firestore, `users/${auth.currentUser.uid}/examples`));
  const exampleSnapshot = await getDocs(examplesQuery);
  exampleSnapshot.forEach((d)=>{
    userExampleList.push(d.data());
  })
  return userExampleList;

}

async function getAllExamples() {
  var examples:any=[];
  const q = query(collection(firestore, "examples"));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((d) => {
    examples.push(d.data());
});

  return examples;
} 


export const getStudyStatus = createAsyncThunk(
  'stat/getStudyStatus',
   async () => {

    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }

  const q = query(collection(firestore, "lectures"));
  const querySnapshot =  await getDocs(q);
  var knotList: Array<StatStudy> = [];
   querySnapshot.forEach((d) => {
    const data = d.data();
    let knot:string = data.knot;
    let knotTitle:string = data.title;
    const statData:StatStudy= {
      knot: knot,
      knotTitle : knotTitle,
      pharseCount : 0,
      doneQuiz : false,
      doneLecture : false,
      exampleCount : 0,
      exampleTotalCount : 0,
    };
    knotList.push(statData);
   });

   knotList = knotList.sort((a:StatStudy, b: StatStudy) => {
    return parseInt(a.knot) - parseInt(b.knot);
   })
   
   const exmpleList = await getAllExamples();
   const userPhaseList = await getListPhaseKnotCount();
   const userQuizList = await getDoneQuizList();
  const userLectureList = await getDoneLecture();
  const userExampleList = await getUserExamples();

  const result = knotList.map((item) => {
    // console.log("knot", item.knot, "knotTitle:", item.knotTitle);

    const userKnotPhases:[] = userPhaseList.filter((v: any) => {
     return  v.knot === item.knot
    });
    item.pharseCount = userKnotPhases.length;
   
    const userKnotQuiz = userQuizList.find((v: any) => {
      return  v.knot === item.knot
     });
     item.doneQuiz = (userKnotQuiz ? true: false);

     const userKnotLecture = userLectureList.find((v: any) => {
      return  v.knot === item.knot
     });
     item.doneLecture = (userKnotLecture ? true: false);

     const sortedKnotUserExamples:[] = userExampleList.filter((v:any) => {
        return  v.knot === item.knot;
     }).sort((a: any, b : any) => {
       return parseInt(a.number) - parseInt(b.number);
     });

     const knotExamples: [] = exmpleList.filter((v:any) => {
      return  v.knot === item.knot;
     })

    item.exampleTotalCount = knotExamples.length;

     const userKnotExampleCount = knotExamples.map((v: any) => {
       const found = sortedKnotUserExamples.find((t:any) => {
         return t.number === v.number;
       });
       return (found ? 1: 0)
     }).reduce((prev:number, curr:number) => prev + curr,0);

    item.exampleCount = userKnotExampleCount;

    //  console.log("item :", item);

    return item;
  });

   return result;

  // const promises = result.map( async (item) => {
  //   item.pharseCount = await getPhaseKnotCount(item.knot);
  //   item.doneQuiz= await getDoneQuiz(item.knot);
  //   item.doneLecture = await getDoneLecture(item.knot);
  //   const {exampleTotalCount, exampleCount } = await getExampleCounts(item.knot);
  //   item.exampleTotalCount = exampleTotalCount;
  //   item.exampleCount = exampleCount;
  //   return item;
  //  })

  //  return Promise.all(promises);
  }
  );



export const getKnotExamples = createAsyncThunk(
  'stat/getStudy',
  async (param: GetParam) => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }
  
    let result: Array<KnotExample> = [];
  
    const ret: {q: Query, index: number} = await queryCollection(UserCollection.Examples, param);
    const querySnapshot = await getDocs(ret.q);
    for (let i = 0; i < querySnapshot.size; i++) {
      const d = querySnapshot.docs[i];
      const data = d.data();

      const r = result.find( ({ knot }) => knot === data.knot );

      if (r) {
        let docData: StatExample = {
          knot: data.knot,
          knotTitle: '',
          // date: data.date?.toDate(),
          date: data.date?.toDate().toString(),
          exampleId: d.id,
          number: data.number,
          exampleTitle: '',
          totalCount: data.totalCount,
        }

        r.list.push(docData);
      } else {
        let ex: KnotExample = {
          knot: data.knot,
          knotTitle: '',
          list: [],
        }

        let docData: StatExample = {
          knot: data.knot,
          knotTitle: '',
          // date: data.date?.toDate(),
          date: data.date?.toDate().toString(),
          exampleId: d.id,
          number: data.number,
          exampleTitle : '',
          totalCount: data.totalCount,
        }

        ex.list.push(docData);

        const docRef = doc(firestore, 'knots', docData.knot);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          ex.knotTitle = docSnap.data().knotTitle;
          docData.knotTitle = docSnap.data().knotTitle;
        }
        
        result.push(ex);
      }
    }
  
    // The value we return becomes the `fulfilled` action payload
    return Promise.all(result);
  }
);


export const getExamplesOnDate = createAsyncThunk(
  'stat/getStudyExample',
  async (param: GetParam) => {
    if (!auth.currentUser || !auth.currentUser.uid) {
      return null;
    }
  
    let result: Array<StatExample> = [];
  
    const ret: {q: Query, index: number} = await queryCollection(UserCollection.Examples, param);
    const querySnapshot = await getDocs(ret.q);
    for (let i = 0; i < querySnapshot.size; i++) {
      const d = querySnapshot.docs[i];
      const data = d.data();

      var exampleTitle= data.exampleTitle;
      if (!exampleTitle){
        const q = query(collection(firestore, "examples"),where("number","==", data.number));
        const querySnapshot1 = await getDocs(q);

        if (!querySnapshot1.empty) {
          exampleTitle= querySnapshot1.docs[0].data().title;
        }
      }

      var knotTitle= data.knotTitle;
      if (!knotTitle) {
        const docRef = doc(firestore, 'knots', data.knot);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          knotTitle = docSnap.data().knotTitle;
        }
      }

      let docData: StatExample = {
        knot: data.knot,
        knotTitle: knotTitle,
        // date: data.date?.toDate(),
        date: data.date?.toDate().toString(),
        exampleId: d.id,
        number: data.number,
        exampleTitle : exampleTitle,
        totalCount: data.totalCount,
      }


      result.push(docData);
      }
  
  
    // The value we return becomes the `fulfilled` action payload
    return result;
  }
);


///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
export type StatRow = {
  knot: string,
  title: string,
  phrase: number,
  quiz: string,
  lecture: string,
  example: string,
  // for internal calculation
  readCount: number,
}



interface StatState {
  tag: string; // TODO
  list: Array<StatRow>;
  studyList: Array<StatStudy>;
}

const initialState: StatState = {
  tag: null,
  list: [],
  studyList: [],
};

// slice
export const statSlice = createSlice({
  name: 'stat',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setStat: (state, action: PayloadAction<StatState>) => {
      state.list = action.payload.list;
    },
    resetStat: (state) => {
      state.list = [];
      state.studyList = [];
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      /*
      .addCase(copyWholeKnots.rejected, (state, action) => {
        console.error("copyWholeKnots rejected", state, ",", action);
      })
      .addCase(copyWholeKnots.fulfilled, (state, action) => {
        console.log("copyWholeKnots fulfilled", state, ",", action);
      })
      */
      .addCase(getWholeKnots.rejected, (state, action) => {
        console.error("getWholeKnots rejected", state, ",", action);
      })
      .addCase(getWholeKnots.fulfilled, (state, action) => {
        const list: Array<Knot> = action.payload as Array<Knot>;
        list.forEach((l) => {
          const result = state.list.find( ({ knot }) => knot === l.knot );
          if (!result) {
            const initValue: StatRow = {
              knot: l.knot,
              title: l.knotTitle,
              phrase: 0,
              quiz: '풀이전',
              lecture: '수강전',
              example: '',
              readCount: 0,
            }
            state.list.push(initValue)
          }
        });
      })
      .addCase(saveKnot.pending, (state, action) => {
        // console.log("saveKnot pending", state, ",", action);
        // state.status = 'loading';
      })
      .addCase(saveKnot.rejected, (state, action) => {
        console.error("saveKnot rejected", state, ",", action);
        // state.status = 'reject';
      })
      .addCase(saveKnot.fulfilled, (state, action) => {
        // console.log("saveKnot fulfilled", state, ",", action);
        // state.status = 'idle';
        // state.tag = action.payload.tag;
      })
      .addCase(getKnots.rejected, (state, action) => {
        console.error("getKnots rejected", state, ",", action);
      })
      .addCase(getKnots.fulfilled, (state, action) => {
        // console.log("getKnots fulfilled", state, ",", action);
        // state.status = 'idle';
        // state.tag = action.payload.tag;
        const list: Array<StatKnot> = action.payload as Array<StatKnot>;
        list.forEach((l) => {
          const result = state.list.find( ({ knot }) => knot === l.knot );
          if (!result) {
            const initValue: StatRow = {
              knot: l.knot,
              title: l.knotTitle,
              phrase: l.count,
              quiz: '풀이전',
              lecture: '수강전',
              example: '',
              readCount: 0,
            }
            state.list.push(initValue)
          } else {
            result.title = l.knotTitle;
            result.phrase = l.count;
          }
        });
      })
      .addCase(saveQuiz.rejected, (state, action) => {
        console.error("saveQuiz rejected", state, ",", action);
      })
      .addCase(saveQuiz.fulfilled, (state, action) => {
      })
      .addCase(getQuizzes.rejected, (state, action) => {
        console.error("getQuizzes rejected", state, ",", action);
      })
      .addCase(getQuizzes.fulfilled, (state, action) => {
        const list: Array<StatQuiz> = action.payload as Array<StatQuiz>;
        list.forEach((l) => {
          const result = state.list.find( ({ knot }) => knot === l.knot );
          if (!result) {
            const initValue: StatRow = {
              knot: l.knot,
              title: l.knotTitle,
              phrase: 0,
              quiz: '풀이전',
              lecture: '수강전',
              example: '',
              readCount: 0,
            }
            state.list.push(initValue)
          } else {
            result.quiz = '풀이완료';
          }
        });
      })
      .addCase(saveLecture.rejected, (state, action) => {
        console.error("getWholeKnots rejected", state, ",", action);
      })
      .addCase(saveLecture.fulfilled, (state, action) => {
      })
      .addCase(getLectures.rejected, (state, action) => {
        console.error("getLectures rejected", state, ",", action);
      })
      .addCase(getLectures.fulfilled, (state, action) => {
        const list: Array<StatLecture> = action.payload as Array<StatLecture>;
        list.forEach((l) => {
          const result = state.list.find( ({ knot }) => knot === l.knot );
          if (!result) {
            const initValue: StatRow = {
              knot: l.knot,
              title: l.knotTitle,
              phrase: 0,
              quiz: '풀이전',
              lecture: '수강완료',
              example: '',
              readCount: 0,
            }
            state.list.push(initValue)
          } else {
            result.lecture = '수강완료';
          }
        });
      })
      .addCase(saveExample.rejected, (state, action) => {
        console.error("saveExample rejected", state, ",", action);
      })
      .addCase(saveExample.fulfilled, (state, action) => {
      })
      .addCase(getExamples.rejected, (state, action) => {
        console.error("getExamples rejected", state, ",", action);
      })
      .addCase(getExamples.fulfilled, (state, action) => {
        const list: Array<StatExample> = action.payload as Array<StatExample>;
        state.list.forEach((l) => {
          l.readCount = 0;
        });

        list.forEach((l) => {
          const result = state.list.find( ({ knot }) => knot === l.knot );

          if (!result) {
            const readCount = 1;
            const ex = `${readCount}/${l.totalCount}`
            const initValue: StatRow = {
              knot: l.knot,
              title: l.knotTitle,
              phrase: 0,
              quiz: '풀이전',
              lecture: '수강전',
              example: ex,
              readCount: readCount,
            }
            state.list.push(initValue)
          } else {
            result.readCount++;
            const ex = `${result.readCount}/${l.totalCount}`
            result.example = ex;
          }
        });
      })
      .addCase(getStudyStatus.rejected, (state, action) => {
        console.error("getStudyStatus rejected", state, ",", action);
      })
      .addCase(getStudyStatus.fulfilled, (state, action) => {
        console.info("getStudyStatus fulfilled", state, ",", action.payload);
        state.studyList= [...action.payload];
      })


      ;
  },
});

export const { setStat, resetStat} = statSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectStat = (state: RootState) => state.stat;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const getTODO  = () : AppThunk => async () => {
}

//
export default statSlice.reducer;
