import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { logEvent } from 'firebase/analytics';

import { TextField, Grid, Stack, Button, Divider, Box } from '@mui/material';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import ConfirmDialog from '../../components/ConfirmDialog';
import { DialogState } from '../../components/ConfirmDialog';
import LeftSideTitle from '../../components/LeftSideTitle';

import { useAppDispatch } from '../../store/hooks';
import { signIn, signOut, sendEmailVerification } from '../../store/authSlice';
import { analytics, auth } from '../../firebase';
import ImageButton from '../../components/ImageButton'

import { requestAuthNaver } from './SignInNaver';
import { requestAuthKakao } from './SignInKakao';

import logo from '../../assets/image/icon/logo.png';
import naverLogo from '../../assets/image/icon/naver.png';
import kakaoLogo from '../../assets/image/icon/kakao.png';
import { StyledButton,  StyledTextField } from '../../components/Profile';
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';


const ButtonTypography = styled(Typography)`
  color: #5f5f60;
  font-family: 'NanumSquare';
  font-size: 14px;
  font-weight: normal;
`;
// font-family: 'Malgun Gothic';
// font-family: 'NanumGothic';
// font-family: 'NanumSquare';

const LabelTypography = styled(Typography)({
  fontSize: '14px',
  fontFamily: 'NanumSquare',
  color: '#5f5f60',
});

const signInScheme = yup.object({
  email: yup.string().required('이메일을 입력해 주세요.').email("아이디는 이메일로 입력해 주세요."),
  password: yup.string().required('비밀번호을 입력해 주세요.').min(6, "비밀번호를 확인해 주세요").matches(/^(((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,"비밀번호는 영어+숫자/6글자 이상입니다."),
});

type SignInInputType = {
  email: string;
  password: string;
};

/* 
const ImageButton = styled(Button)({
  fontSize: '16px',
  fontFamily: 'NanumGothic',
  borderColor: '#dcdcdc',
  boxShadow: 'none',
  color: '#101010',
  borderRadius: '50%',
  '&:hover': {
    borderColor: '#dcdcdc',
    color: '#101010',
    backgroundColor: '#dcdcdc',
  },
  '&:active': {
    color: '#101010',
    backgroundColor: '#dcdcdc',
  },
  '&:focus': {
    color: '#101010',
    backgroundColor: '#dcdcdc',
  },
});
*/

type InfoPopup  = DialogState &  {
  name: string;
}

export default function SignIn() {
  const [isRemember, setIsRemember] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['rememberEmail']);
  const [infoPopup, setInfoPopup] = useState<InfoPopup>({open:false, name:""});
  const { register, handleSubmit, formState:{errors}, setValue, getValues } = useForm<SignInInputType>({
    resolver: yupResolver(signInScheme),
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if(!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY_WEB);
    } 

    if(cookies.rememberEmail !== undefined) {
      // setEmail(cookies.rememberEmail);
      setValue("email", cookies.rememberEmail);
      setIsRemember(true);
    }
  }, [cookies.rememberEmail])

  const login = async(email:string, password:string) => {
    if (!email || !password) {
      setInfoPopup({title:"안내", content:"아이디와 이메일을 입력해 주제요.", confirm:"확인", open:true, name:"input_email_password"});
      return;
    }

    if (isRemember) {
      setCookie('rememberEmail', email); // , {maxAge: 2000});
    }

    /*
    const user: AuthArg = {
        email: email,
        password: password,
        nickName: null,
    }
    dispatch(signIn(user));
    */
  
    /*
    let ret = null;
    ret = await dispatch(signIn( email, password ));
    if (!auth.currentUser) {
      alert('No auth');
      return;
    } else if (auth.currentUser && !auth.currentUser.emailVerified) {
      setInfoPopup({title:"안내", content:"사용자 정보가 수정되었습니다.", confirm:"확인", open:true, name:"sendEmailVerification", cancel:"취소"});
      // alert('이메일 인증 먼저');
      return;
    } else if (ret === null) {
        navigate('/', { replace: true });
    }
    */
    dispatch(signIn(email, password, (user) => {
      console.log('TODO >>> signIn:', auth.currentUser, user);
      if (!auth.currentUser) {
        setInfoPopup({title:"안내", content:"회원 아이디/비밀번호가 잘못되었습니다.", confirm:"확인", open:true, name:"no_auth"});
      } else if (auth.currentUser && !auth.currentUser.emailVerified) {
        setInfoPopup({title:"안내",  content:"로그인 전에 이메일 인증을 먼저 진행해 주세요.\n인증메일 재전송을 원하시면 재전송 버튼을 클릭해 주세요.", confirm:"재전송", open:true, name:"sendEmailVerification", cancel:"취소"});
      } else if (user && user.errorMessage) {
        setInfoPopup({title:"안내", content:"로그인 오류입니다.", confirm:"확인", open:true, name:"error"});
      } else {
        logEvent(analytics, 'login', { method: 'email' });
        navigate('/', { replace: true });
      }
    }));

    /*
    let ret = null;
    ret = await dispatch(signIn3( {email, password} ));
    if (ret.payload) {
        navigate('/', { replace: true });
    }
    */
  }

  function rememberEmail(checked: boolean) {
    setIsRemember(checked);
    if(checked) {
      const values = getValues();
      setCookie('rememberEmail', values.email); // , {maxAge: 2000});
    } else {
      removeCookie('rememberEmail');
    }
  }

  const onSubmit = (data:any) => {
    console.log(data);
    login(data.email, data.password);
  };

  return (
    <Grid container spacing={0} sx={{height: '100%'}}>
      <Grid item xs={12} sm={12} md={6}>
        <LeftSideTitle bigTitle="LOGIN" title="로그인" subTitle="잉글맵의 더 많은 혜택을 받으실 수 있습니다."></LeftSideTitle>
      </Grid>
      <Grid item xs={12} sm={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box component="div" display="flex" alignItems="center">
            <Stack alignItems="center" direction="column" maxWidth="md" sx={{ width: "100%" }} spacing={4} >
              <Stack alignItems="left" direction="column" maxWidth="md" sx={{ width: "80%" }} spacing={2} >
                <Stack direction="row" alignItems="center" justifyContent="center">
                  <Box component="img" sx={{width:"59%"}} alt="logo" src={logo} />
                </Stack>
                <Stack spacing={1}>
                  <StyledTextField id="tf_user_email" type="email" variant="outlined" size="small" fullWidth={true}
                                   placeholder="아이디"
                                   required
                                   autoFocus
                                   name="email"
                                   helperText={errors?.email?.message}
                                   {...register('email')}
                  > 
                  </StyledTextField>
                  <StyledTextField id="tf_user_password" type="password" variant="outlined" size="small" fullWidth={true}
                                   placeholder="비밀번호"
                                   name="password"
                                   helperText={errors?.password?.message}
                                   {...register('password')}>
                  </StyledTextField>
                  <Stack direction="row" alignItems="center" width="100%" justifyContent="flex-end">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox checked={isRemember} sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                    onChange={(event) => rememberEmail(event.target.checked)}
                          />
                        }
                        label={<ButtonTypography>아이디 저장</ButtonTypography>}
                      />
                    </FormGroup>
                  </Stack>
                </Stack>

                <Stack spacing={1}>
                  <StyledButton type='submit' variant="contained" size="medium" fullWidth={true} onClick={handleSubmit(onSubmit)}>로그인</StyledButton>
                  <Stack spacing={0} direction="row" alignItems="center" justifyContent="center" divider={<Divider orientation="vertical" variant="middle" flexItem />}>
                    <Button variant="text" onClick={() => navigate('/signUp')}>
                      <ButtonTypography width={100}>회원가입</ButtonTypography>
                    </Button>
                    <Button variant="text" onClick={() => navigate('/passwordModify')}>
                      <ButtonTypography width={100}>비밀번호 찾기</ButtonTypography>
                    </Button>
                  </Stack>
                </Stack>

                <Box sx={{ height: 30 }} />
                    
                <Stack>
                {/* 
                <Stack spacing={3} direction="row" alignItems="center" justifyContent="center">
                  <Avatar alt="Kakao" src={kakaoLogo} onClick={requestAuthKakao}/>
                  <Avatar alt="Naver" src={naverLogo} onClick={requestAuthNaver}/>
                </Stack>
                */}
                  <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                  {/*
                  <ImageButton variant="text" size="medium" onClick={requestAuthKakao}>
                    <Box component="img" sx={{width:"50px", height:"50px", borderRadius:"50%"}} alt="kakaoLogo" src={kakaoLogo} />
                  </ImageButton>
                  <ImageButton variant="text" size="medium" onClick={requestAuthNaver}>
                    <Box component="img" sx={{width:"50px", height:"50px", borderRadius:"50%"}} alt="naverLogo" src={naverLogo} />
                  </ImageButton>
                  */}
                    <ImageButton onClick={requestAuthKakao}
                                 src={kakaoLogo} alt="kakaoLogo" width="70px" height="70px" borderRadius="50%"
                    >
                    </ImageButton>
                    <ImageButton onClick={requestAuthNaver}
                                 src={naverLogo} alt="neverLogo" width="70px" height="70px" borderRadius="50%"
                    >
                    </ImageButton>
                  </Stack>
                  <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant="text" size="medium" onClick={requestAuthKakao}>
                      <LabelTypography sx={{width:"70px"}}>카카오로<br/>쉬운시작</LabelTypography>
                    </Button>
                    <Button variant="text" size="medium" onClick={requestAuthNaver}>
                      <LabelTypography sx={{width:"70px"}}>네이버로<br/>쉬운시작</LabelTypography>
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <ConfirmDialog title={infoPopup.title} content={infoPopup.content} open={infoPopup.open}
                           onClose={()=>{
                             setInfoPopup({title:"", content:".", confirm:"", open:false, name:"", cancel:""})
                             dispatch(signOut());
                           }}
                           confirm={infoPopup.confirm}
                           onConfirm={()=>{
                             setInfoPopup({title:"", content:".", confirm:"", open:false, name:"", cancel:""})
                             if (infoPopup.name === "sendEmailVerification") {
                               dispatch(sendEmailVerification((user) => {
                                 dispatch(signOut());
                                 if (user && user.errorMessage) {
                                   setInfoPopup({title:"안내", content:user.errorMessage, confirm:"확인", open:true, name:"fail_to_sendEmailVerification"});
                                 } else {
                                   // navigate('/signIn', { replace: true });
                                   setInfoPopup({title:"안내", content:"인증메일이 발송되었습니다.", confirm:"확인", open:true, name:"success_to_sendEmailVerification"});
                                 }
                               }
                                                             ));
                             }
                           }}
                           cancel={infoPopup.cancel}
                           onCancel={()=>{
                             setInfoPopup({title:"", content:".", confirm:"", open:false, name:"", cancel:""})
                             if (infoPopup.name === "sendEmailVerification") {
                               // navigate('/signIn', { replace: true });
                             }
                             dispatch(signOut());
                           }}
            ></ConfirmDialog>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};
