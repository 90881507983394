import Title from '../../components/common/Title';
import { Box, Container, Divider, Stack, Link } from "@mui/material";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { noticesText, fetchNoticeList, getNoticesCount, Notice, getNoticeAsync, selectNotice } from '../../store/policySlice';
import { useState } from 'react';
import { useEffect } from 'react';
import Pagination from "react-js-pagination";
import '../../assets/css/Paging.css';
import footer_logos from '../../assets/image/icon/footer_logos.svg';
import '../../assets/css/NoticeLayout.css';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { forEachChild } from 'typescript';
import { id } from 'date-fns/locale';

export const NoticeTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(234, 236, 245, 1)",
        fontSize: "16px",
        fontFamily: 'NanumSquare',
        height: "30px",
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "14px",
        color: "#101010",
        fontFamily: 'NanumSquare',
        height: "30px",
        fontWeight: 'normal',
    },
}));

export const NoticeLoadingTypography = styled(Typography)({
    fontSize: "14px",
    color: "#101010",
    fontFamily: 'NanumSquare',
    height: "20px",
    fontWeight: 'normal',
});


export default function Notices() {
    const stat = useAppSelector(selectNotice);
    const dispatch = useAppDispatch();
    const [notices, setNotices] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [page, setPage] = useState<number>(1);
    const [rowsPerPage] = useState<number>(10);

    useEffect(() => {
        // getNoticesText();
        dispatch(getNoticeAsync(page)).then((result:any) => {
        }).catch((error: Error)=>{
            // console.error('getStudyStatus: error:', error);
        })
    },[dispatch, stat.list.length]);

    // const getNoticesText = async () => {

        // console.log("[fetchNoticeList] Call");
    // const totalNoticeCount = async () => {
    //     await getNoticesCount();
    // } 
        
    // setTotalCount(totalNoticeCount);
        // setNotices(stat.list.map((v) => { 
        //     return {id:v.id, title:v.title, content:v.content, date: v.date
        //     }
        // }));
        // console.log("[fetchNoticeList]", stat.list , "total count :", totalNoticeCount, "notices" , notices);
        
    // }

    const handlePageChange = (page: number) => {
        setPage(page); 
    }

    const getNoticeData = (list:Notice[]) => {
        if (!list || list.length <= 0) {
            return (<TableRow
                key="-1"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                <NoticeTableCell component="th" scope="row" align="center"></NoticeTableCell>
                <NoticeTableCell align='center'>공지사항이 없습니다.</NoticeTableCell>
                <NoticeTableCell align="center"></NoticeTableCell>
            </TableRow>);
        } 

        return (list
            .slice(((page -1) * rowsPerPage), page * rowsPerPage) //rowsPerPage 지우면 12 - 2 안지우면 10 - 0 짤림 
            .map((rows) => (
                <TableRow
                    key={rows.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <NoticeTableCell component="th" scope="row" align="center">{rows.id} </NoticeTableCell>
                    <NoticeTableCell align="left">
                        <Link color="#101010" underline="none" href={`notices/detail/${rows.id}`} >{rows.title}</Link>
                    </NoticeTableCell>
                    <NoticeTableCell align="center">{rows.date}</NoticeTableCell>
                </TableRow>
            )));
    }

    return (
        <Box component="div" className="NoticeBody">
            <Container maxWidth="lg" sx={{ my: 6 }}>
                <Stack spacing={1} direction="row" alignItems="center" justifyContent="left">
                    <Box component="img" src={footer_logos} alt="NoticesLogo" sx={{ width: "26px", height: "26px", borderRadius: "0%" }} />
                    <Title title="공지사항" icon={false} divider={false} />
                </Stack>
                <Divider sx={{ backgroundColor: "rgba(90, 98, 173, 1)", mt: 1, marginBottom: "-10px" }} />
                <TableContainer component={Paper} className="Table" sx={{ maxHeight:475}}>
                    <Table sx={{ minWidth: 650 }} size="small" stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: "rgba(234, 236, 245, 1)" }}>
                                <NoticeTableCell align="center" sx={{ width: 50 }}>번호</NoticeTableCell>
                                <NoticeTableCell align="center">제목</NoticeTableCell>
                                <NoticeTableCell align="center" sx={{ width: 150 }}>날짜</NoticeTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                {getNoticeData(stat.list)}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
                <Pagination
                    activePage={page}
                    itemsCountPerPage={rowsPerPage}
                    totalItemsCount={stat.list.length}
                    pageRangeDisplayed={5}
                    prevPageText={<button className="lt">&lt;</button>}
                    nextPageText={<button className="gt">&gt;</button>}
                    firstPageText={<button className="lt">&lt;&lt;</button>}
                    lastPageText={<button className="gt">&gt;&gt;</button>}
                    hideDisabled={(stat.list.length <= 0 ? true : false)}
                    onChange={handlePageChange} />
            </Container>
        </Box>
    );
}
