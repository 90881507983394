import { useEffect, useState,  } from 'react';
import {  useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

import Pagination from "react-js-pagination";

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getWholeKnots, getKnots, getQuizzes, getLectures, getExamples, resetStat, StatRow, getStudyStatus, StatStudy } from '../../store/statSlice';
// import { copyWholeKnots } from '../../store/statSlice';
// import { saveKnot, StatKnot } from '../../store/statSlice';
import { selectStat } from '../../store/statSlice';
import { auth } from '../../firebase';

import { StudyTableCell, StudySubTitle, StudyLoadingTypography} from './StudyStyle';
import {CircularProgress, Stack} from '@mui/material';

import '../../assets/css/Paging.css';

/*
type row = {
  knot: string,
  title: string,
  phrase: number,
  quiz: string,
  lecture: string,
  example: string,
}

function createData(knot: string, title: string, phrase: number, quiz: string, lecture: string, example: string) {
  return { knot, title, phrase, quiz, lecture, example };
}

// let list: row [] = [];
let list = [
  createData('101', '동격 That 절 꼬리표 1', 159, '풀이완료', '수강완료', '1/10'),
  createData('102', '동격 That 절 꼬리표 2', 237, '풀이완료', '수강완료', '1/10'),
  createData('103', '동격 That 절 꼬리표 3', 262, '풀이완료', '수강완료', '1/10'),
  createData('104', '동격 That 절 꼬리표 4', 305, '풀이완료', '수강완료', '1/10'),
  createData('105', '동격 That 절 꼬리표 5', 356, '풀이완료', '수강완료', '1/10'),
];
*/

export default function Stat() {
  const stat = useAppSelector(selectStat);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  auth.onAuthStateChanged(async (user) => {
    if (!user) {
      if (stat.list.length > 0) {
        dispatch(resetStat());
      }
      
    }
  });

  // async componentDidMount() {
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (!user) {
        navigate('/signIn');
        return;
      }

      // TODO
      /*
      const k: StatKnot = {
        knot: '1',
        knotTitle: '',
        date: new Date(),
        count: 10,
      }
      await dispatch(saveKnot(k)).then((result: any) => {
        console.log('TODO >>> saveKnot', result.payload);
      }).catch((error: Error) => {
        console.log('TODO >>> saveKnot: error:', error);
      });
      */

      // TODO
      /*
      await dispatch(copyWholeKnots()).then((result: any) => {
        console.log('TODO >>> result:', result);
      }).catch((error: Error) => {
        console.error('copyWholeKnots: error:', error);
      });
      */

      // await dispatch(getWholeKnots()).then((result: any) => {
      // }).catch((error: Error) => {
      //   console.error('getWholeKnots: error:', error);
      // });

      // await dispatch(getKnots()).then((result: any) => {
      // }).catch((error: Error) => {
      //   console.error('getKnots: error:', error);
      // });

      // await dispatch(getQuizzes(null)).then((result: any) => {
      // }).catch((error: Error) => {
      //   console.error('getQuizzes: error:', error);
      // });

      // await dispatch(getLectures(null)).then((result: any) => {
      // }).catch((error: Error) => {
      //   console.error('getLectures: error:', error);
      // });

      // await dispatch(getExamples()).then((result: any) => {
      // }).catch((error: Error) => {
      //   console.error('getExamples: error:', error);
      // });

      await dispatch(getStudyStatus()).then((result: any) => {
      }).catch((error: Error) => {
        console.error('getStudyStatus: error:', error);
      });
      

      /* TODO
      const r: row = { knot: '100', title:'동격 That 절 꼬리표 10', phrase:159, quiz:'풀이완료', lecture:'수강완료', example:'1/10'};
      list.push(r);
      list.push(createData('101', '동격 That 절 꼬리표 1', 159, '풀이완료', '수강완료', '1/10'));
      list.push(createData('102', '동격 That 절 꼬리표 2', 237, '풀이완료', '수강완료', '1/10'));
      list.push(createData('103', '동격 That 절 꼬리표 3', 262, '풀이완료', '수강완료', '1/10'));
      list.push(createData('104', '동격 That 절 꼬리표 4', 305, '풀이완료', '수강완료', '1/10'));
      list.push(createData('105', '동격 That 절 꼬리표 5', 356, '풀이완료', '수강완료', '1/10'));
      console.log('TODO >>> list:', list);
      */

      //
      // const count = stat.list.length;
      const count = stat.studyList.length;
      setCount(count);
      setRowsPerPage(44);
      setIsLoading(false);
    });
  }, [dispatch, stat.studyList.length]);

  /*
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  }
  */

  const handlePageChange = (newPage: number) => {
    setPage(newPage-1); 
  }

  const getTableRows = (list:StatStudy[])  => {
    if (isLoading) {
      return (          
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <StudyTableCell colSpan={5} component="th" scope="row" sx={{pl:"3%"}}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <CircularProgress  size={20} />
            <StudyLoadingTypography justifyContent="center">로딩 중...</StudyLoadingTypography>
          </Stack>
          </StudyTableCell> 
        </TableRow>
      )
    }

    return (list
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((row) => (
        <TableRow
          key={row.knot} 
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <StudyTableCell component="th" scope="row" sx={{px:'3%'}}> {`#${row.knot}`} {row.knotTitle} </StudyTableCell>
          <StudyTableCell align="center">{row.pharseCount}</StudyTableCell>
          {/* <StudyTableCell align="center">{(row.doneQuiz?"풀이완료":"풀이전")}</StudyTableCell> */}
          <StudyTableCell align="center">{(row.doneLecture?"수강완료":"수강전")}</StudyTableCell>
          <StudyTableCell align="center">{(row.exampleCount < 10?`0${row.exampleCount}`: row.exampleCount)}/{(row.exampleTotalCount< 10? `0${row.exampleTotalCount}`:row.exampleTotalCount)}</StudyTableCell>
        </TableRow>
      )));
  }

  return (
    <Box component="div" className="StudyBody" >
      <Container maxWidth="lg" sx={{ my:1}}>
        {/* 
        <Title title="학습현황" icon={false} style={{marginLeft:"0px", marginRight:"0px"}} />
        */}
        <StudySubTitle >학습현황</StudySubTitle>
        <Divider sx={{  mt: 2, borderBottomWidth: 1, backgroundColor:"rgba(90, 98, 173, 1)", marginBottom:"-10px" }} />
        <TableContainer component={Paper} className="Table">
          <Table sx={{ minWidth: 650 }} size="small" stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <StudyTableCell align="center">구분</StudyTableCell>
                <StudyTableCell align="center">구분분석</StudyTableCell>
                {/* <StudyTableCell align="center">구분문제</StudyTableCell> */}
                <StudyTableCell align="center">구분강의</StudyTableCell>
                <StudyTableCell align="center">유사구문</StudyTableCell>
              </TableRow>
            </TableHead>
            {
            /* TODO
            <TableBody>
              {list.map((row) => (
                <TableRow
                  key={row.knot}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <StudyTableCell component="th" scope="row"> {row.title} </StudyTableCell>
                  <StudyTableCell align="right">{row.phrase}</StudyTableCell>
                  <StudyTableCell align="right">{row.quiz}</StudyTableCell>
                  <StudyTableCell align="right">{row.lecture}</StudyTableCell>
                  <StudyTableCell align="right">{row.example}</StudyTableCell>
                </TableRow>
              ))}
            </TableBody>
            */
            }
            <TableBody>
              {getTableRows(stat.studyList)}
            </TableBody>
          </Table>
        </TableContainer>
        {/*
        <TablePagination
          rowsPerPageOptions={rowsPerPage]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
        */}
        {/* <Pagination 
          activePage={page+1} 
          itemsCountPerPage={10} 
          totalItemsCount={count} 
          pageRangeDisplayed={5} 
          prevPageText={<button className="lt">&lt;</button>} 
          nextPageText={<button className="gt">&gt;</button>} 
          firstPageText={"<<"}
          lastPageText={">>"}
          onChange={handlePageChange}
        /> */}
      </Container>
    </Box>
  );
}

