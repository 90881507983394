import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { noticesText, fetchNoticeList, getNoticesCount, Notice, getNoticeAsync, selectNotice, noticeCountAsync } from '../store/policySlice';
import { useEffect } from 'react';
import { Box, Button, Card, CardContent, Container, Divider, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Title from './common/Title';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconButton } from '@mui/material';
import footer_logos from '../assets/image/icon/footer_logos.svg';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import '../assets/css/NoticeLayout.css';

export const NoticeTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        fontSize: "16px",
        fontFamily: 'NanumSquare',
        height: "30px",
        fontWeight: 'bold',

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: "14px",
        color: "#101010",
        fontFamily: 'NanumSquare',
        height: "30px",
        fontWeight: 'normal',
    },
}));

// const ButtonTypography = styled(Typography)`
//   color: #333333;
//   font-family: 'NanumGothic';
//   font-size: 10px;
// `;
const StyledButton = styled(Button)`

font-family: 'NanumSquare';
font-size: 12px;
color: #101010;
line-height: 1;
background-color: #ffffff;
border: 1px solid #dadada;
`;



export default function Detail() {
    const stat = useAppSelector(selectNotice);
    const dispatch = useAppDispatch();
    let { number } = useParams();
    const navigate = useNavigate();
    const itemIndex = parseInt(number);
    const [items, setItems] = useState([]);

    // const getNoticesText = async () => {
    //     dispatch(getNoticeAsync(parseInt(number) - 1)).then((noticeList: Array<Notice>) => {
    //         setItems(noticeList.map((v) => { return { id: v.id, title: v.titie, content: v.content, date: v.date } }));
    //     });

    // }

    // useEffect(() => {
    //     getNoticesText();
    // }, [number]);
    useEffect(() => {
        // getNoticesText();
        dispatch(noticeCountAsync(parseInt(number) - 1,)).then(() => {
            setItems(stat.list)
        }).catch((error: Error)=>{
            // console.error('getStudyStatus: error:', error);
        })
    },[dispatch, number]);

    

    const showViews = (list:Array<Notice>) => {

        if (!stat.list || stat.list.length === 0) {
            return (<div></div>);
        }

        // const listResult = list.filter(item => item, 3);
        // console.log(listResult)
        const previousItem = (itemIndex === 1 ? null : list[0]);
        const currentItem = (itemIndex === 1 ? list[0] : list[1]);;
        const nextItem = (itemIndex === 1 ? list[1] : list.length === 2 ? null : list[2]);

        return (
            <Container maxWidth="lg" sx={{ my: 5 }}>
                <Stack sx={{ m: 2 }} spacing={1} direction="row" alignItems="center" justifyContent="left">
                    <Box component="img" src={footer_logos} alt="NoticesDetailLogo" sx={{ width: "26px", height: "26px" }} />
                    <Title title="공지사항" icon={false} divider={false} />
                </Stack>
                <Divider sx={{ backgroundColor: "rgba(90, 98, 173, 1)", mt: 1, marginBottom: "-10px" }} />
                <TableContainer component={Paper} className="NoticeDetailTable">
                    <Table aria-label="simple table" size="small" stickyHeader   >
                        <TableHead>
                            <TableRow>
                                <NoticeTableCell>{currentItem.id}. {currentItem.title}</NoticeTableCell>
                                <NoticeTableCell align="right">작성일 : {currentItem.date}
                                </NoticeTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ height: "420px", verticalAlign: "top" }}>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <NoticeTableCell colSpan={2} sx={{ verticalAlign: "baseline" }} >
                                    <Box width="100%" border="0px" >
                                        {currentItem.content}
                                    </Box>
                                </NoticeTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <StyledButton sx={{ mt: 2, mb: 4, color: "#101010", fontSize: "12px", border: "1px solid #dadada", borderRadius: "2px" }} size="medium" variant="text" onClick={() => navigate('/notices')}>목록</StyledButton>
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                        <TableBody>
                            <TableRow >
                                <NoticeTableCell align="center" sx={{ width: "5%" }}>
                                    <IconButton disabled={!previousItem} onClick={() => navigate(`/notices/detail/${previousItem.id}`)}>
                                        <KeyboardArrowUpIcon />
                                    </IconButton>
                                </NoticeTableCell>
                                <NoticeTableCell align="center" sx={{ width: "10%", cursor:(previousItem?'pointer':'default') }} onClick={() => {if(previousItem) navigate(`/notices/detail/${previousItem.id}`)}}>이전글</NoticeTableCell>
                                <NoticeTableCell align="left" onClick={()=>{ if (previousItem) navigate(`/notices/detail/${previousItem.id}`)}} sx={{cursor:(previousItem?'pointer':'default')}} >{(!previousItem ? "이전 글이 없습니다. " : previousItem.title)}</NoticeTableCell>
                            </TableRow>
                            <TableRow >
                                <NoticeTableCell align="center">
                                    <IconButton disabled={!nextItem} onClick={() => navigate(`/notices/detail/${nextItem.id}`)}>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                </NoticeTableCell>
                                <NoticeTableCell align="center" onClick={() => { if (nextItem) navigate(`/notices/detail/${nextItem.id}`)}} sx={{cursor:(nextItem?'pointer':'default')}} >다음글</NoticeTableCell>
                                <NoticeTableCell align="left" onClick={()=>{ if (nextItem) navigate(`/notices/detail/${nextItem.id}`)}} sx={{cursor:(nextItem?'pointer':'default')}} >{(!nextItem ? "다음 글이 없습니다. " : nextItem.title)}</NoticeTableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>)
    }

    return (
        <Box component="div" className="NoticeBody">
            {showViews(stat.list)}
        </Box>
    );

}