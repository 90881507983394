import * as React from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { DateRangePickerView } from '@mui/lab/DateRangePicker/DateRangePickerView';
import { Console } from 'console';

const ButtonTypography = styled(Typography)({
  color: '#5a62ad',
  fontFamily: 'NanumGothic',
  fontSize: '10px',
  margin: '-20px',
});

const TodayButton = styled(Button)({
  fontSize: '16px',
  fontFamily: 'NanumGothic',
  color: '#101010',
  marginRight: "10px",
  borderRadius: 10,
  witdh: "40px",
  maxWidth: "40px",
  minWidth: "40px",
  height: "20px",
  backgroundColor: "#ffffff"
});

const ArrowButton = styled(Button)({
  width: "20px",
  maxWidth: "20px",
  minWidth: "20px",
  height: "20px",
});

interface LineCalendarProps  {
  date: Date;
  onChange: (date: Date) => void;
}

export default function LineCalendar(props: LineCalendarProps) {
  const [value, setValue] = React.useState<Date>(props.date);
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!value) {
      /* TODO
      const newDate = new Date();
      setValue(newDate);
      */
      // props.onChange(newDate); // WARNING: never do that
      setOpen(false);
    }
  }, [value, props])

  function handleToday() {
    const newDate = new Date();
    setValue(newDate);
    props.onChange(newDate);
    setOpen(false);
  }

  function movePrev() {
    const newDate = new Date(value);
    newDate.setDate(newDate.getDate() - 1);
    setValue(newDate);
    props.onChange(newDate);
    setOpen(false);
  }

  function moveNext() {
    const newDate = new Date(value);
    newDate.setDate(newDate.getDate() + 1);
    setValue(newDate);
    props.onChange(newDate);
    setOpen(false);
  }

  function handleChange(newDate: Date) {
    setValue(newDate);
    props.onChange(newDate);
    setOpen(false);
  }

  function openCalender() {
    setOpen(true);
  }

  function closeCalender() {
    setOpen(false);
  }

  return (
    <Stack spacing={0} direction="row" sx={{height:"20px", alignItems:"center"}}>
      <TodayButton variant="outlined" onClick={handleToday}>
        <ButtonTypography>오늘</ButtonTypography>
      </TodayButton>
      <ArrowButton   onClick={movePrev} ><KeyboardArrowLeftIcon fontSize='large'/></ArrowButton>
      <LocalizationProvider dateAdapter={AdapterDateFns} >
        <DatePicker
          open={open}
          mask="____.__.__"
          inputFormat="yyyy.MM.dd"
          value={value}
          onChange={(newValue) => {
            handleChange(newValue);
          }}
          onClose={closeCalender}
          renderInput={({ inputRef, inputProps, InputProps }) =>  {
             return (<Button ref={inputRef} onClick={openCalender}>{inputProps.value}</Button>);
          }} 
        />
      </LocalizationProvider>
      <ArrowButton onClick={moveNext}><KeyboardArrowRightIcon fontSize='large'/></ArrowButton>
    </Stack>
  );
}
