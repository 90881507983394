import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { auth, firestore } from '../firebase';
import { createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, updateProfile, updateEmail } from "firebase/auth";
import * as firebaseAuth from 'firebase/auth';
import { doc, setDoc, getDoc } from "firebase/firestore";
import { FirebaseError } from 'firebase/app';

export interface AuthState {
  errorMessage: string | null;
  email: string | null;
  uid: string | null;
  nickName: string | null;
  level: string | null;
  job: string | null;
}

const initialState: AuthState = {
  errorMessage: null,
  email: null,
  uid: null,
  nickName: null,
  level: null,
  job: null,
};

export interface AuthArg {
  email: string;
  password: string;
  nickName: string | null;
  level: string | null;
  job: string | null;
}

export const signIn3 = createAsyncThunk(
  'auth',
  async (action: AuthArg) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, action.email, action.password);
      return userCredential.user.uid;
    } catch (error) {
      console.error('siginIn3: error: ', error);
    }
    return null;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    signUpTODO: (state, action: PayloadAction<AuthArg>) => {
      createUserWithEmailAndPassword(auth, action.payload.email, action.payload.password)
      .then((userCredential) => {
        /* 에러 발생 
        state.email = userCredential.user.email;
        state.uid = userCredential.user.uid;
        state.nickName = userCredential.user.displayName;
        */

        if (auth.currentUser) {
          updateProfile(auth.currentUser, { displayName:action.payload.nickName });
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error('signUpTODO: error: ', errorCode, errorMessage);
      });
    },
    signInTODO: (state, action: PayloadAction<AuthArg>) => {
      signInWithEmailAndPassword(auth, action.payload.email, action.payload.password)
      .then((userCredential) => {
        /* 에러 발생 
        state.email = userCredential.user.email;
        state.uid = userCredential.user.uid;
        state.nickName = userCredential.user.displayName;
        */
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error('signInTODO: error: ', errorCode, errorMessage);
      });
      /*
      try {
        const userCredential = await signInWithEmailAndPassword(auth, action.payload.email, action.payload.password);
        state.email = userCredential.user.email;
        state.uid = userCredential.user.uid;
        state.nickName = userCredential.user.displayName;
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error('signInTODO: error: ', errorCode, errorMessage);
      }
      */
    },
    setUser: (state, action: PayloadAction<AuthState>) => {
      // TODO reset 
      state.errorMessage = action.payload.errorMessage;
      state.email = action.payload.email;
      state.uid = action.payload.uid;
      state.nickName = action.payload.nickName;
      state.level = action.payload.level;
      state.job = action.payload.job;
    },
    refreshUser: (state) => {
      state.errorMessage = null;
      state.email = auth.currentUser ? auth.currentUser.email : null;
      state.uid = auth.currentUser ? auth.currentUser.uid : null;
      state.nickName = auth.currentUser ? auth.currentUser.displayName : null;
      state.level = null;
      state.job = null;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(signIn3.pending, (state) => {
        // state.status = 'loading';
      })
      .addCase(signIn3.fulfilled, (state, action) => {
        // state.status = 'idle';
        state.uid = action.payload;
      });
  },
});


export const { setUser, refreshUser } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state: RootState) => state.auth.uid == null ? null : state.auth;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const signUp = (email: string, password: string, nickName: string, level: string, job: string, terms: string, callback: (a: AuthState) => void): AppThunk => async (
  dispatch,
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);

    let errorMessage = null
    if (auth.currentUser) {
      if (nickName) {
        await updateProfile(auth.currentUser, { displayName: nickName });
      }
      
      const uid = auth.currentUser.uid;
      if (uid && (level || job || terms)) {
        const docRef = doc(firestore, 'users', uid);
        await setDoc(docRef, { level: level, job: job, terms: terms });
      }
      
      await firebaseAuth.sendEmailVerification(auth.currentUser)
      // https://ai-englemap.firebaseapp.com/__/auth/action?mode=verifyEmail&oobCode=ECKssdhP-w4Ln62b06sIDlwF4gH9L7PtqsRqgC7lqzUAAAF9E9PJpw&apiKey=AIzaSyAubejR9vNUaP6unaY1rOa2vRKgGMXMTw0&lang=ko
      errorMessage = `입력하여주신 이메일로 
      인증메일이 전송되었습니다. 
      이메일에서 인증 하신 후에 
      이용가능합니다.`;
    }

    const user : AuthState = {
      errorMessage: errorMessage, 
      email: userCredential.user.email,
      uid: userCredential.user.uid,
      nickName: nickName,
      level: level,
      job: job,
    }
    await dispatch(setUser(user));

    //
    callback(user);
  } catch (error) {
    console.error('signUp: error: ', error);
    let errorMessage = null
    if (error instanceof FirebaseError) {
      switch (error.code) {
        case 'auth/email-already-in-use': 
          errorMessage = '이미 가입된 이메일입니다.';
          break;
        case 'auth/weak-password':
          errorMessage = '비밀번호는 영어+숫자/6글자 이상입니다.';
          break;
        case 'auth/invalid-email':
          errorMessage = '이메일을 정확히 입력해 주세요.';
          break;
        default:
          errorMessage = '에러 [' + error.code + ']';
          break;
      }
    }

    // TODO reset
    const user : AuthState = {
      errorMessage: errorMessage, 
      email: null,
      uid: 'TODO', 
      nickName: null,
      level: null,
      job: null,
    }
    await dispatch(setUser(user));

    //
    callback(user);
  }
};

export const signIn = (email: string, password: string, callback: (a: AuthState) => void): AppThunk => async (
  dispatch,
) => {
  try {
    // await firebaseAuth.setPersistence(auth, firebaseAuth.browserLocalPersistence)
    await firebaseAuth.setPersistence(auth, firebaseAuth.browserSessionPersistence)
    // await firebaseAuth.setPersistence(auth, firebaseAuth.inMemoryPersistence)

    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user : AuthState = {
      errorMessage: null, 
      email: userCredential.user.email,
      uid: userCredential.user.uid,
      nickName: userCredential.user.displayName,
      level: null,
      job: null,
    }

    const uid = auth.currentUser?.uid;
    if (uid) {
      const docRef = doc(firestore, 'users', uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        user.level = docSnap.data().level;
        user.job = docSnap.data().job;
      }
    }

    await dispatch(setUser(user));

    if (!auth.currentUser?.emailVerified) {
      user.errorMessage = '로그인 전에 이메일 인증을 먼저 진행해 주세요. 인증메일 재전송을 원하시면 재전송 버튼을 클릭해 주세요';
    }

    //
    callback(user);
  } catch (error) {
    console.error('signIn: error: ', error);

    let errorMessage;
    if (error instanceof FirebaseError) {
      console.error('signIn: error: code:', error.code);
      switch (error.code) {
        case 'auth/user-not-found': 
        case 'auth/wrong-password':
        case 'auth/too-many-requests':
          errorMessage = '아이디 또는 패스워드를 정확히 입력해 주세요.';
          break;
        default:
          errorMessage = '에러 [' + error.code + ']';
          break;
      }
    }

    const user : AuthState = {
      errorMessage: errorMessage,
      email: null,
      uid: null,
      nickName: null,
      level: null,
      job: null,
    }
    await dispatch(setUser(user));

    //
    callback(user);
  }
};

export const sendEmailVerification = (callback: (a: AuthState) => void): AppThunk => async (
  dispatch,
) => {
  try {
    await firebaseAuth.sendEmailVerification(auth.currentUser)
    const user : AuthState = {
      errorMessage: null,
      email: null,
      uid: null,
      nickName: null,
      level: null,
      job: null,
    }
    await dispatch(setUser(user));

    callback(user);
  } catch (error) {
    console.error('sendEmailVerification: error: ', error);
    // auth/too-many-requests
    const user : AuthState = {
      errorMessage: '인증메일 전송에 실패하였습니다. 잠시 후 로그인을 진행해 주세요.',
      email: null,
      uid: null,
      nickName: null,
      level: null,
      job: null,
    }
    await dispatch(setUser(user));

    //
    callback(user);
  }
};

export const updateUser =  (email: string, nickname:string, level: string, job:string): AppThunk => async (
) => {
  const auth = getAuth();
  const user = auth.currentUser;
  const uid = user.uid;

 updateEmail(user, email).then((userCredential)=> {
  updateProfile(user, { displayName:nickname}).then((v)=>{
    if (uid && (level || job )) {
      const docRef = doc(firestore, 'users', uid);
      setDoc(docRef, { level: level, job: job });
    }
  }).catch(e => console.log(e));
 }).catch(e => console.log(e));
};


export const signOut = (): AppThunk => async (
  dispatch,
) => {
  try {
    await firebaseAuth.signOut(auth);

    // TODO reset null
    const user : AuthState = {
      errorMessage: null,
      email: null,
      uid: null,
      nickName: null,
      level: null,
      job: null,
    }
    await dispatch(setUser(user));

    // if (!window.Kakao.Auth.getAccessToken()) {
    //   console.log('Not logged in.');
    //   return;
    // }
    if (window.Kakao.Auth && window.Kakao.Auth.getAccessToken()) {
      window.Kakao.Auth.logout(function() {
        console.log('kakao logout:', window.Kakao.Auth.getAccessToken());
      });
    }

    return null;
  } catch (error) {
    console.error('signOut: error: ', error);
    return error
  }
};

// 비밀번호 찾기 
export const findPassword = (email: string, fn: (values: string) => void): AppThunk => async (

) => {

  const auth = getAuth();
  sendPasswordResetEmail(auth, email)
  .then(() => {
    console.log("find pw success");
    fn("success");
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode);
    console.log(errorMessage);
    fn(errorCode);
  });
  return ;
};



//비밀번호 변경
// export const changepassword = (newpassword: string): AppThunk => async (
//   dispatch,
// ) => {
//   const auth = getAuth();
//   const user = auth.currentUser;
//   updatePassword(user, newpassword)
//   .then(() => {
//     console.log("change pw success")
// }).catch((error) => {
//   const errorCode = error.code;
//   const errorMessage = error.message;
//   console.log(errorCode);
//   console.log(errorMessage);
// });

// }
// 연습

// document.addEventListener('DOMContentLoaded', () => {
//   // TODO: Implement getParameterByName()
//   // Get the action to complete.
//   const mode = getParameterByName('mode');
//   // Get the one-time code from the query parameter.
//   const actionCode = getParameterByName('oobCode');
//   // (Optional) Get the continue URL from the query parameter if available.
//   const continueUrl = getParameterByName('continueUrl');
//   // (Optional) Get the language code if available.
//   const lang = getParameterByName('lang') || 'ko';

//   // Configure the Firebase SDK.
//   // This is the minimum configuration required for the API to be used.
//   // const config = {
//   //  'apiKey': "YOU_API_KEY" // Copy this key from the web initialization
//   //                          // snippet found in the Firebase console.
//   //};
//   //const app = initializeApp(config);
//   //const auth = getAuth(app);
//   const auth = getAuth();
//   // Handle the user management action.
//   switch (mode) {
//     case 'resetPassword':
//       // Display reset password handler and UI.
//       handleResetPassword(auth, actionCode, continueUrl, lang);
//       break;
//     default:
//       // Error: invalid mode.
//   }
// }, false);

// function handleResetPassword(auth: firebaseAuth.Auth, actionCode: string, continueUrl: any, lang: any) {
//   // Localize the UI to the selected language as determined by the lang
//   // parameter.

//   // Verify the password reset code is valid.
//   verifyPasswordResetCode(auth, actionCode).then((email) => {
//     const accountEmail = email;
//     console.log(accountEmail);

//     // TODO: Show the reset screen with the user's email and ask the user for
//     // the new password.
//     const newPassword = "...";

//     // Save the new password.
//     confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
//       // Password reset has been confirmed and new password updated.

//       // TODO: Display a link back to the app, or sign-in the user directly
//       // if the page belongs to the same domain as the app:
//       // auth.signInWithEmailAndPassword(accountEmail, newPassword);

//       // TODO: If a continue URL is available, display a button which on
//       // click redirects the user back to the app via continueUrl with
//       // additional state determined from that URL's parameters.
//     }).catch((error) => {
//       // Error occurred during confirmation. The code might have expired or the
//       // password is too weak.
//     });
//   }).catch((error) => {
//     // Invalid or expired action code. Ask user to try to reset the password
//     // again.
//   });
// }
/////////////////////////////////////////////



export const getCurrentUserInfo  = () : AppThunk => async ( dispatch,) => {
  const auth = getAuth();
  const uid = auth.currentUser?.uid;
  const email = auth.currentUser?.email;
  const displayName = auth.currentUser?.displayName;

  const user : AuthState = {
    errorMessage: null,
    email: email,
    uid: uid,
    nickName: displayName,
    level: null,
    job: null,
  }

  if (uid) {
    const docRef = doc(firestore, 'users', uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      // TODO reset
      user.level = docSnap.data().level;
      user.job = docSnap.data().job;
    }
  }

  await dispatch(setUser(user));
  return user;
}

export type AccountType = "email"|"naver"|"kakao";

export function getAccountType(uid:string):AccountType {
  
  if (uid.includes("naver")) return "naver";
  if (uid.includes("kakao")) return "kakao";

  return "email";
}

//
export default authSlice.reducer;





