import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';

import { TextField, Grid, Stack, Divider, Box, FormGroup, FormControlLabel, Checkbox, Button, Modal, styled, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Typography from '@mui/material/Typography';

import ConfirmDialog from '../../components/ConfirmDialog';
import { DialogState } from '../../components/ConfirmDialog';
import LeftSideTitle from '../../components/LeftSideTitle';
import { LabelTypography, StyledTextField, StyledButton, SelectStyledButton, SelectedStyledButton } from '../../components/Profile';

import { useAppDispatch } from '../../store/hooks';
import { /* AuthArg, */ signUp, signOut } from '../../store/authSlice';
import { analytics, auth } from '../../firebase';
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { termsText } from '../../store/policySlice';
import { Close } from '@mui/icons-material';
import { string } from 'yup/lib/locale';


type InfoPopup  = DialogState &  {
  name: string;
}

const signUpScheme = yup.object({
  email: yup.string().required('이메일을 입력해 주세요.').email("아이디는 이메일로 입력해 주세요."),
  password: yup.string().required('비밀번호을 입력해 주세요.').min(6, "비밀번호를 확인해 주세요").matches(/^(((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,"비밀번호는 영어+숫자/6글자 이상입니다."),
  confirmPassword: yup.string().oneOf([yup.ref('password'), null], '입력하신 비밀번호가 일치하지 않습니다.'),
  nickName: yup.string().min(2,"닉네임은 2자이상 입력해 주세요"),
});

type SignUpInputType = {
  email: string;
  password: string;
  confirmPassword: string;
  nickName: string;
}


export default function SignUp() {
  const [level, setLevel] = useState<string>('');
  const [job, setJob] = useState<string>('');
  const [selLevel, setSelLevel] = useState<number>(-1);
  const [selJob, setSelJob] = useState<number>(-1);
  const [checked, setChecked] = useState(false);
  const [infoPopup, setInfoPopup] = useState<InfoPopup>({open:false, name:""});
  const [modaltext, modalsetText] = useState<string>('');
 

  // const CloseButton = styled(Button)({
  //   float:"right",
  //   color: "#ffffff",
  //   fontSize: 14,
  //   minWidth: 27,
  //   minHeight: 22,
  //   padding:0,
  // }); 

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { register, handleSubmit, formState:{errors}, setValue, getValues } = useForm<SignUpInputType>({
    resolver: yupResolver(signUpScheme),
  });

  const jobList = ['초등학생', '중학생', '고등학생', '대학생', '일반'];
  const levelList = ['초급', '중급', '고급'];
  const terms = { version: '20210101_TODO', content:'이용약관 내용 .... TODO'};
  const contents = [
    "AI 잉글맵의 더 많은 혜택을 받으실 수 있습니다.", 
    "*는 필수 입력 항목 입니다."
  ]

  useEffect(() => {
    if(!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY_WEB);
    } 
  }, [])

  const doRegister = async(email:string, nickName:string, password:string, confirmPassword:string) => {
    if (!email || !nickName || !password || !confirmPassword) {
      setInfoPopup({title:"안내", content:"필수 항목을 입력해 주세요.", confirm:"확인", open:true, name:"input_email_nickname_password"});
      return;
    }

    const regExp = /^\w[\w\-.]*@\w+\.\w{2,}/
    if (!regExp.test(email)) {
      setInfoPopup({title:"안내", content:"아이디에는 이메일을 입력해 주세요.", confirm:"확인", open:true, name:"invalid_email"});
      return;
    }
    
    if (password !== confirmPassword) {
      setInfoPopup({title:"안내", content:"비밀번호를 정확히 입력해 주세요.", confirm:"확인", open:true, name:"mismatch_password"});
      return;
    }

    // const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    // const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
    if (!mediumRegex.test(password)) {
      setInfoPopup({title:"안내", content:"비밀번호는 영어+숫자/6글자 이상입니다.", confirm:"확인", open:true, name:"simple_password"});
      return;
    }

    if (!checked) {
      setInfoPopup({title:"안내", content:"이용약관에 동의해 주세요.", confirm:"확인", open:true, name:"agree_terms"});
      return;
    }

    /* 
    const user: AuthArg = {
    email: email,
    password: password,
    nickName: nickName,
    }
    dispatch(signUp(user));
    */

    /* 
    const ret = await dispatch(signUp(email, password, nickName, level, job, terms.version));
    if (ret === null) {
      navigate('/', { replace: true })
    }
    */
    dispatch(signUp(email, password, nickName, level, job, terms.version, (user) => {
      console.log('TODO >>> signUp:', auth.currentUser, user);
      dispatch(signOut());
      if (!auth.currentUser) {
        setInfoPopup({title:"안내", content:user.errorMessage, confirm:"확인", open:true, name:"no_auth"});
      } else if (auth.currentUser && !auth.currentUser.emailVerified) {
        setInfoPopup({title:"안내", content:user.errorMessage, confirm:"확인", open:true, name:"sendEmailVerification"});
      } else if (user && user.errorMessage) {
        setInfoPopup({title:"안내", content:user.errorMessage, confirm:"확인", open:true, name:"error"});
      } else {
        navigate('/signIn', { replace: true });
      }
    }));
  }

  const termsmodal = async() => {
    const getTerms: any =  await dispatch(termsText());
    console.log(getTerms);
    modalsetText(getTerms);
    setInfoPopup({title:"이용약관", content: modaltext, confirm:"확인", open:true, name:"terms"}); //dangerouslySetInnerHTML={{ __html: modaltext}}
    
    return;
  }

  const onClickLevel = (i: any, e: any) => {
    setSelLevel(i);
    setLevel(levelList[i]);
  }

  const onClickJob = (i: any, e: any) => {
    setSelJob(i);
    setJob(jobList[i]);
  }

  const getLevelList = () => {
    const result = levelList.map((v, i) => {
      return (i === selLevel ? 
        (<Grid item xs={3}><SelectedStyledButton variant="outlined" size="small" fullWidth={true} onClick={(e) => { onClickLevel(i, e) }}>{v}</SelectedStyledButton></Grid>) 
      : (<Grid item xs={3}><SelectStyledButton variant="outlined" size="small" fullWidth={true} onClick={(e) => { onClickLevel(i, e) }}>{v}</SelectStyledButton></Grid>))
    })
    return result;
  }

  const getJobList = () => {
    const result = jobList.map((v, i) => {

      const styleButton = (i: number) =>{
        return (i === selJob ? 
          (<SelectedStyledButton variant="outlined" size="small" fullWidth={true} onClick={(e) => { onClickJob(i, e) }}>{v}</SelectedStyledButton>) 
        : (<SelectStyledButton variant="outlined" size="small" fullWidth={true} onClick={(e) => { onClickJob(i, e) }}>{v}</SelectStyledButton>))
      }

      return (
        <Grid item xs={3}>
          {styleButton(i)}
        </Grid>)
    })
    return result;
  }

  const onSubmit = (data:any) => {
    console.log(data);
    //login(data.email, data.password);
    doRegister(data.email, data.nickName, data.password, data.confirmPassword);
  };




  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={6}>
        <LeftSideTitle bigTitle="JOIN MEMBERSHIP" title="회원가입" contents={contents}></LeftSideTitle>
      </Grid>

      <Grid item xs={12} sm={12} md={6} sx={{height:'100vh', backgroundColor: '#f4f5fa', overflowY:'auto',}}>
        <Box component="div" sx={{height:'1200px', backgroundColor: '#f4f5fa'}}>
          <form  onSubmit={handleSubmit(onSubmit)}>
            <Stack alignItems="center" direction="column" maxWidth="md" sx={{mt:'50px', width: "100%" }} spacing={4} >
              <Stack alignItems="left" direction="column" maxWidth="md" sx={{ width: "80%" }} spacing={2} >
                <Stack direction="row" spacing={1}><LabelTypography >아이디</LabelTypography><Typography color='#be0000'>*</Typography></Stack>
                <StyledTextField placeholder="이메일로 입력하세요" id="tf_user_email" variant="standard" size="small" fullWidth={true} name="email" helperText={errors?.email?.message} {...register('email')}> </StyledTextField>
                <Stack direction="row" spacing={1}><LabelTypography >닉네임</LabelTypography><Typography color='#be0000'>*</Typography></Stack>
                <StyledTextField placeholder="닉네임을 입력해 주세요" id="tf_user_nickname" variant="standard" size="small" fullWidth={true} name="nickName" helperText={errors?.nickName?.message} {...register('nickName')}></StyledTextField>
                <Stack direction="row" spacing={1}><LabelTypography >비밀번호</LabelTypography><Typography color='#be0000'>*</Typography></Stack>
                <StyledTextField placeholder="비밀번호를 입력하세요 (영어+숫자/6글자 이상)" id="tf_user_password" variant="standard" size="small" fullWidth={true} type="password" name="password" helperText={errors?.password?.message} {...register('password')}></StyledTextField>
                <Stack direction="row" spacing={1}><LabelTypography >비밀번호 확인</LabelTypography><Typography color='#be0000'>*</Typography></Stack>
                <StyledTextField placeholder="비밀번호를 입력하세요 (영어+숫자/6글자 이상)" id="tf_user_password2" variant="standard" size="small" fullWidth={true} type="password" name="confirmPassword" helperText={errors?.confirmPassword?.message} {...register('confirmPassword')}></StyledTextField>
                <Divider light sx={{ my: 3 }} />
                <LabelTypography >레벨</LabelTypography>
                <Grid container direction="row" spacing={1} columns={{ xs: 9 }}>
                  {getLevelList()}
                </Grid>
                <Divider light sx={{ my: 3 }} />
                <LabelTypography >직업</LabelTypography>
                <Grid container spacing={1} columns={{ xs: 9}}>
                  {getJobList()}
                </Grid>
                <Divider light sx={{ my: 3 }} />
                <Stack direction="row" alignItems="center">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox onChange={(event) => setChecked(event.target.checked)} />} label="이용약관에 동의합니다."/>
                  </FormGroup>
                  <Button variant='outlined' onClick={termsmodal} size="small" sx={{ width: "70px" }} >이용약관</Button>

                </Stack>
              </Stack>
              <Stack alignItems="left" direction="column" maxWidth="md" sx={{ width: "90%" }} spacing={2} >
                <StyledButton variant="contained" size="medium" fullWidth={true}  onClick={handleSubmit(onSubmit)}>회원가입</StyledButton>
              </Stack>
            </Stack>
          </form>
          <ConfirmDialog 
            title={infoPopup.title} 
            content={infoPopup.content} 
            open={infoPopup.open}
            onClose={()=>{ 
              setInfoPopup({title:"", content:".", confirm:"", open:false, name:"", cancel:""})
            }}
            confirm={infoPopup.confirm}

            onConfirm={()=>{
              setInfoPopup({title:"", content:".", confirm:"", open:false, name:"", cancel:""})
              if (infoPopup.name === "sendEmailVerification") {
                logEvent(analytics, 'sign_up', { method: 'email' });
                navigate('/signIn', { replace: true });
              }
            }} 
          ></ConfirmDialog>
        </Box>
      </Grid>
    </Grid>
  );
};
