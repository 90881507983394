import Router from './router'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../store/hooks';

export default Router;

export function withParams(Component: any) {
  return (props: Object) => <Component {...props} params={useParams()} />;
}

export function withNavigation(Component: any) {
  return (props: Object) => <Component {...props} navigate={useNavigate()} />;
}

export function withSearchParams(Component: any) {
  return (props: Object) => <Component {...props} searchParams={useSearchParams()} />;
}

export function withDispatch(Component: any) {
  return (props: Object) => <Component {...props} navigate={useNavigate()} dispatch={useAppDispatch()} />;
}

export function withRouter(Component: any) {
  return (props: Object) => <Component {...props} params={useParams()} navigate={useNavigate()} searchParams={useSearchParams()} />
}
