import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toPng } from 'html-to-image';
import download from 'downloadjs';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';

import BoxHeader from './common/BoxHeader';
import BoxSwitch from './common/BoxSwitch';
import Phrase, { PhraseProps } from './Phrase';

import { useAppSelector, useAppDispatch } from '../store/hooks';
import { savePhrase } from '../store/statSlice';
import { incrementLayer, decrementLayer, switchParts, Part } from '../store/phraseSlice';

import { ReactComponent as Up } from '../assets/image/icon/up.svg';
import { ReactComponent as Down } from '../assets/image/icon/down.svg';
import { ReactComponent as Download } from '../assets/image/icon/download.svg';
import styles from '../assets/css/PhraseTree.module.css';

const Title = styled(Typography)`
  color: #101010;
  font-family: NanumSquare;
  font-size: 14px;
`;

export function Header() {
  return (
    <Box className={styles.Header}>
      <Title className={styles.Title}>분석</Title>
    </Box>
  );
}

export default function PhraseTree(props: PhraseProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [ sentences, showParts, countParts ] = useAppSelector((state) => [state.phrase.sentences, state.phrase.showParts, state.phrase.countParts]);
  const body = React.useRef(null);

  function changePart(event: React.ChangeEvent<HTMLInputElement>, newValue: boolean) {
    dispatch(switchParts({name: event.target.id as Part, value: newValue}));
  }
  function saveNBack() {
    dispatch(savePhrase({
      index: 0,
      date: null,
      phraseId: '??',
      phrase: sentences[0].enSentence,
    }));
    navigate('/phrase');
  }
  function getLayer() {
    toPng(body.current)
      .then(function (dataUrl) {
        download(dataUrl, 'AI Englemap.png');
      });
  }

  return (
    <Box className={styles.PhraseTree}>
      <Box className={styles.Tree}>
        <Box sx={{ position: 'relative' }}>
          <BoxHeader>분석</BoxHeader>
          <Box className={styles.Overlap}>
            <Stack direction="row" className={styles.ButtonArea} spacing={1}>
              <Button sx={{height: '26px', backgroundColor: '#fff'}} onClick={getLayer}>
                <Download width="24" height="24" fill="#5A62AD" />
              </Button>
              <Button variant="text" sx={{backgroundColor: "#fff"}} onClick={()=>dispatch(incrementLayer())}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Typography variant="h4">펼치기</Typography>
                  <Up width="9" fill="#5A62AD" />
                </Stack>
              </Button>
              <Button variant="text" sx={{backgroundColor: "#fff"}} onClick={()=>dispatch(decrementLayer())}>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Typography variant="h4">접기</Typography>
                  <Down width="9" fill="#5A62AD" />
                </Stack>
              </Button>
            </Stack>
          </Box>
        </Box>
        <Phrase selectKnot={props.selectKnot} body={body} />
      </Box>
      <Box className={styles.Control}>
        <BoxHeader>구문 표시</BoxHeader>
        <BoxSwitch id="adjectiveP" checked={showParts["adjectiveP"]} onChange={changePart}>형용사구 ({countParts["adjectiveP"]})</BoxSwitch>
        <BoxSwitch id="adjective" checked={showParts["adjective"]} onChange={changePart}>형용사절 ({countParts["adjective"]})</BoxSwitch>
        <BoxSwitch id="nounP" checked={showParts["nounP"]} onChange={changePart}>명사구 ({countParts["nounP"]})</BoxSwitch>
        <BoxSwitch id="noun" checked={showParts["noun"]} onChange={changePart}>명사절 ({countParts["noun"]})</BoxSwitch>
        <BoxSwitch id="adverbP" checked={showParts["adverbP"]} onChange={changePart}>부사구 ({countParts["adverbP"]})</BoxSwitch>
        <BoxSwitch id="adverb" checked={showParts["adverb"]} onChange={changePart}>부사절 ({countParts["adverb"]})</BoxSwitch>
        <BoxSwitch id="specialP" checked={showParts["specialP"]} onChange={changePart}>특수구문 ({countParts["specialP"]})</BoxSwitch>
        <BoxSwitch id="coordinator" checked={showParts["coordinator"]} onChange={changePart}>등위 접속사 ({countParts["coordinator"]})</BoxSwitch>
        <Box className={styles.ButtonArea}>
          <Button onClick={saveNBack} variant="contained" fullWidth sx={{ height: "100%" }}>
            <Typography variant="button">분석 결과 저장</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
