import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';

import { httpsCallable } from 'firebase/functions';
import { signInWithCustomToken } from 'firebase/auth';
import { analytics, auth, functions } from '../../firebase';
import {CircularProgress, Stack, Box} from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const LoadingTypography= styled(Typography)({
  fontSize: "16px",
  color: "#101010",
  fontFamily: 'NanumSquare',
  height:"20px",
  fontWeight: '600',
});


export function requestAuthKakao() {
  const protocol = window.location.protocol;
  const hostName = window.location.hostname;
  const port = window.location.port;

  let url = protocol + '//' + hostName + (port ? ':' + port : '');
  url += '/auth/callback/kakao';

  // const auth = new KakaoAuth();
  // auth.auth();
  window.Kakao.Auth.authorize({
    redirectUri: url
    // , state:""
    // , scope:""
    , throughTalk: true
  });
}

export default function SignInKakao() {
  const navigate = useNavigate();

  useEffect(() => {
    if(!window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.REACT_APP_KAKAO_APP_KEY_WEB);
    } 
  }, [])

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
  
    const kakaoAuthCode = params.get('code');

    if(kakaoAuthCode) {
      // console.log(kakaoAuthCode);

      // 카카오 로그인 토큰을 파이어베이스 함수에 전달합니다.
      // const functions = getFunctions();
      var kakaoAuth = httpsCallable(functions, 'auth-authKakao');
      kakaoAuth({code: kakaoAuthCode}).then(function(result: any) {
        // console.log(result);

        // Read result of the Cloud Function.
        var kakaoToken = result.data.kakao_token;
        var fireToken = result.data.firebase_token;

        // 토근이 정상적으로 처리될 경우 로그인 처리합니다.
        signInWithCustomToken(auth, fireToken)
          .then(function(result) {
            logEvent(analytics, 'login', { method: 'kakao' });

            window.Kakao.Auth.setAccessToken(kakaoToken);

            const user = result.user;
            console.log("User : ", user);
            /* TODO
              if(result.additionalUserInfo.isNewUser) {
              console.log("신규 사용자...");
              _this.$router.push("/welcome");
              } else {
              _this.$router.push("/profile");
              }
            */
            navigate('/', { replace: true })
          })
          .catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            console.error(errorCode, errorMessage);
            // console.error(error);

            alert("토큰이 정상적이지 않습니다. 만료된 토큰이거나 이미 사용된 토큰입니다.");
            navigate('/signIn', { replace: true })
            return;
          });
      }).catch(function(error) {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        // console.error(error);
        console.error(code, message, details);
        // console.error(error.body);

        // functions/permission-denied auth/email-already-exists: The email address is already in use by another account. undefined
        if (message.startsWith('auth/email-already-exists')) {
          alert("이미 가입된 이메일입니다.");
        } else {
          alert("정상적이지 않은 접근입니다. 만료된 데이터이거나 이미 사용된 데이터입니다.");
        }
        // alert(message + ' ' + details);
        navigate('/signIn', { replace: true })
        return;
      });
    } else {
      alert("잘못된 방법으로 접근하셨습니다. 로그인 페이지로 이동합니다.");
      navigate('/signIn', { replace: true })
      return;
    }
  },[ navigate ]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} padding={5} bgcolor="#FFFFFF" border="1px solid #5a62ad">
              <CircularProgress />
              <LoadingTypography justifyContent="center">카카오 계정으로 로그인 중 입니다.</LoadingTypography>
            </Stack>
    </Box>
  );
}
