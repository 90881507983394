import { useState } from 'react';
import { Grid, Stack, Box } from '@mui/material';
import { useAppDispatch } from '../../store/hooks';
import { getCurrentUserInfo } from '../../store/authSlice';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';
import { styled } from '@mui/material/styles';
import LeftSideTitle from '../../components/LeftSideTitle';
import { LabelTypography, StyledButton } from '../../components/Profile';
import { StyledTextField as ProfileStyledTextField } from '../../components/Profile';

const StyledTextField = styled(ProfileStyledTextField)({
  "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
    "-webkit-text-fill-color": "black",
    fontWeight: 'bold'
  },
  "& .MuiInputBase-root.MuiInput-root.Mui-disabled:before": {
    "border-bottom-style": "solid",
    "border-bottom-width": "1px",
    "border-bottom-color": "#dddddd"
  },
  fontSize: 14,
  fontFamily: 'NanumSquare',
});

export default function Profile() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');
  const [nickName, setNickName] = useState<string>('');
  const [level, setLevel] = useState<string>('');
  const [job, setJob] = useState<string>('');

  auth.onAuthStateChanged((user) => {
    console.log("onAuthStateChanged : user" + JSON.stringify(user));
    if (user) {
      currentUser();
    } else {
      navigate('/signIn');
    }
  });

  const currentUser = async () => {
    const userInfo: any = await dispatch(getCurrentUserInfo());
    console.log("user3" + JSON.stringify(userInfo));
    // setEmail(user3 as AuthState.email); 
    setEmail(userInfo.email);
    setNickName(userInfo.nickName);
    setLevel(userInfo.level);
    setJob(userInfo.job);
  }

  function moveEditProfile() {
    navigate('/profile/edit');
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={6}>
        <LeftSideTitle bigTitle="PERSONAL INFORMATION" title="프로필" subTitle="등록한 정보를 확인합니다."></LeftSideTitle>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Box component="div" display="flex" alignItems="center" sx={{ height: '100vh', backgroundColor: '#f4f5fa' }}>
          <Stack alignItems="center" direction="column" maxWidth="md" sx={{ pl: 10, width: "45%", mt: -20 }} spacing={4} >
            <Stack alignItems="left" direction="column" maxWidth="md" sx={{ width: "90%" }} spacing={2} >
              <LabelTypography >아이디</LabelTypography>
              <StyledTextField id="tf_user_email" variant="standard" size="small" fullWidth={true} value={email} disabled={true} > </StyledTextField>
              <LabelTypography >닉네임</LabelTypography>
              <StyledTextField id="tf_user_nickname" variant="standard" size="small" fullWidth={true} value={nickName} disabled={true}> </StyledTextField>
              <LabelTypography >레벨</LabelTypography>
              <StyledTextField id="tf_user_level" variant="standard" size="small" fullWidth={true} value={level} disabled={true} > </StyledTextField>
              <LabelTypography >직업</LabelTypography>
              <StyledTextField id="tf_user_job" variant="standard" size="small" fullWidth={true} value={job} disabled={true} > </StyledTextField>
            </Stack>
            <StyledButton variant="contained" size="medium" fullWidth={true} onClick={moveEditProfile} >수정</StyledButton>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );

}
