import Lectureplayer from "../components/LecturePlayer";
import React from 'react';
import { Container, Button, TextField } from "@mui/material";
import SyntaxDialog from "../components/SyntaxDialog";
import QuizDialog from "../components/QuizDialog";
import EngDicDialog from "../components/EngDicDialog";

export default function TestView() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [open1, setOpen1] = React.useState<boolean>(false);
  const [open2, setOpen2] = React.useState<boolean>(false);
  const [knot, setKnot] = React.useState<string>("1");
  const [open3, setOpen3] = React.useState<boolean>(false);
  const [word, setWord] = React.useState<string>("");

  const buttonClick = (knot:string) =>{
    setKnot(knot);
    setOpen(true);
  };

  const buttonClick1 = (knot:string) =>{
    setKnot(knot);
    setOpen1(true);
  };

  const buttonClick2 = async (knot:string) =>{
    setOpen2(true);
    setKnot(knot);
  };

  function handleClose() {
    setOpen(false);
  }

  const buttonClick3 = async (knot:string) =>{
    setOpen3(true);
    setWord(word);
  };

  return (
    <Container >
      <Button variant="outlined" onClick={(e)=>{buttonClick('1')} }> Lecture 1</Button>
      <Button variant="outlined" onClick={(e)=>{buttonClick('2')}}> Lecture 2</Button>
      <Button variant="outlined" onClick={(e)=>{buttonClick('3')} }> Lecture 3</Button>
      <Button variant="outlined" onClick={(e)=>{buttonClick('4')}}> Lecture 4</Button>
      <Button variant="outlined" onClick={(e)=>{buttonClick('5')} }> Lecture 5</Button>
      <Button variant="outlined" onClick={(e)=>{buttonClick('6')}}> Lecture 6</Button>
      <Button variant="outlined" onClick={(e)=>{buttonClick('7')} }> Lecture 7</Button>
      <Button variant="outlined" onClick={(e)=>{buttonClick('8')}}> Lecture 8</Button>
      <Button variant="outlined" onClick={(e)=>{buttonClick1('1')}}> Example1</Button>
      <Button variant="outlined" onClick={(e)=>{buttonClick1('2')}}> Example2</Button>
      <Button variant="outlined" onClick={(e)=>{buttonClick2('1')}}> Quiz </Button>
      <TextField id="tf_dic" variant="outlined" size="small" fullWidth={true} value={word} onChange={(event) =>setWord(event.target.value)} ></TextField>
      <Button variant='outlined' onClick={(e)=>{buttonClick3('1')}}> Search</Button>
 
      <EngDicDialog open={open3} word={word} onClose={()=>{setOpen3(false)}} onResult={()=>{}}/>
      <SyntaxDialog open={open1} knot={knot} onClose={()=>{setOpen1(false);}} />
      <Lectureplayer open={open} knot={knot} onClose={handleClose} />
      <QuizDialog open={open2} knot={knot} onClose={()=>{setOpen2(false)}} />
    </Container>
    
  );
}