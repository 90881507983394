import { Component, ReactNode } from 'react';

import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type TitleProps = StackProps & {
  icon?: ReactNode,
}

export default class IconTitle extends Component<TitleProps> {
  render() {
    return (
      <Stack className={this.props.className} spacing={1} direction="row" alignItems="center">
        { this.props.icon }
        <Typography variant="subtitle1" color="primary">{this.props.children}</Typography>
      </Stack>
    );
  }
}
