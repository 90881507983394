import { Component } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

interface ImageButtonProps  {
  src: string | undefined;
  alt?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  onClick?: () => void;
}

const StyledButton = styled(Button)({
  borderColor: '#dcdcdc',
  boxShadow: 'none',
  color: '#101010',
  '&:hover': {
    borderColor: '#dcdcdc',
    color: '#101010',
    backgroundColor: '#dcdcdc',
  },
  '&:active': {
    color: '#101010',
    backgroundColor: '#dcdcdc',
  },
  '&:focus': {
    color: '#101010',
    backgroundColor: '#dcdcdc',
  },
});

export default class ImageButton extends Component<ImageButtonProps> {
  render() {
    return (
      <StyledButton variant="text"
        sx={{borderRadius:this.props.borderRadius}} 
        onClick={this.props.onClick}
      >
        <Box component="img" 
          sx={{width:this.props.width, height:this.props.height, borderRadius:this.props.borderRadius}} 
          alt={this.props.alt} 
          src={this.props.src}
        />
      </StyledButton>
    );
  }
}
