import React from 'react';
import ReactDOM from 'react-dom';

import { store } from './store/store';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@mui/system';

import Router from './router';
import { white } from './themes';

// import reportWebVitals from './reportWebVitals';
import './assets/css/index.css';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={white}>
      <Provider store={store}>
        <Router />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
