import { Component } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

const HVSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 35,
  height: 18,
  padding: 0,
  marginLeft: 5,
  '& .MuiSwitch-switchBase': {
    color: '#9EA3CF',
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#F0F1F8',
      '& + .MuiSwitch-track': {
        backgroundColor: '#9EA3CF',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light'
        ? theme.palette.grey[100]
        : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 18 / 2,
    backgroundColor: '#FFFFFF',
    opacity: 1,
    transition: theme.transitions.create(['color', 'background-color'], {
      duration: 500,
    }),
  },
}));

const Label = styled(Typography)`
  color: #101010;
`;

export default class LabelSwitch extends Component<SwitchProps> {
  render() {
    return (
      <FormControlLabel
        control={<HVSwitch {...this.props} />}
        label={<Label variant="h5">{this.props.children}</Label>}
        labelPlacement="start"
      />
    );
  }
}
