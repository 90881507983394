import React, { Fragment } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useAppSelector, useAppDispatch } from '../store/hooks';
import { changeElement, changeTranslate } from '../store/phraseSlice';

import BoxHeader from './common/BoxHeader';
import LabelSwitch from './common/LabelSwitch';
import Sentence from './Sentence';

import styles from '../assets/css/PhraseBody.module.css';

type PhraseBodyProps = {
  showDict: (word: string) => void,
};

const Translate = styled(Typography)({
  color: '#101010',
});

export default function PhraseBody(props: PhraseBodyProps) {
  const dispatch = useAppDispatch();
  const [ showElement, showTranslate, sentences, selected ] = useAppSelector((state) => [
    state.phrase.element,
    state.phrase.translate,
    state.phrase.sentences,
    state.phrase.selected,
  ]);
  
  let translate;
  if (sentences != null && showTranslate) {
    translate = <Fragment>
                  <Box className={styles.Translate}>
                    <Translate variant="h3">{sentences[selected].krSentence}
                    </Translate>
                  </Box>
                </Fragment>
  }
  return (
    <Box className={styles.PhraseBody}>
      <Box sx={{ position: 'relative' }}>
        <BoxHeader>본문</BoxHeader>
        <Box className={styles.Overlap}>
          <Stack className={styles.Right} direction="row" spacing={3}>
            <LabelSwitch checked={showElement} onChange={(e, v)=>dispatch(changeElement(v))}>문장성분</LabelSwitch>
            <LabelSwitch checked={showTranslate} onChange={(e, v)=>dispatch(changeTranslate(v))}>해석</LabelSwitch>
          </Stack>
        </Box>
      </Box>
      <Box className={styles.Box}>
        <Sentence showDictionary={props.showDict} />
        <Divider sx={{ borderColor: '#DCDCDC', borderBottomWidth: '2px' }} />
        {translate}
      </Box>
    </Box>
  );
}
