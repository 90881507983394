import Box, { BoxProps }from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import styles from '../../assets/css/BoxHeader.module.css';

export default function BoxHeader(props: BoxProps) {
  return (
    <Box className={styles.Header}>
      <Typography className={styles.Title} variant="subtitle2" color="primary">{props.children}</Typography>
    </Box>
  );
}
