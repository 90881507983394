import { Stack, Button, Box, Container, Typography } from '@mui/material';
import MainBGImage from '../assets/image/main_bg.png';
import KnotImage from '../assets/image/main.png';
import BookImage from '../assets/image/book.png';
import BetaImage from '../assets/image/tag-beta.png';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../store/hooks';

export const TitleTypography = styled(Typography)({
  color: '#000000',
  fontSize: '6em',
  letterSpacing: '-3px',
});
export const SubtitleTypography = styled(Typography)({
  color: '#292f39',
  fontSize: '1.6em',
  fontWeight: 'normal',
});
export const ContentTypography = styled(Typography)({
  color: '#292f39',
  fontSize: '1.8em',
  lineHeight: 1.44,
  letterSpacing: '-0.9px',
});
export const BottomTypography = styled(Typography)({
  color: '#2e399b',
  fontSize: '1.6em',
  lineHeight: 1.63,
  letterSpacing: '-0.8px',
});

export default function Main() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // backgroundImage: `url(${MainBGImage})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'top'
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ width: '85%', flex: 1, margin: '0 auto', display: 'flex', position: 'relative', alignItems: 'center' }}>
        <Box component="img" src={KnotImage} width='648px' height='498px' sx={{ position: 'absolute', top: '42px', right: 0, zIndex: -1 }} />
        <Stack direction="column" alignItems="flex-start" justifyContent='center' width='35vw' height='25vw'>
          <SubtitleTypography>매듭으로 풀어내는 신기한 영어 공부</SubtitleTypography>
          <Stack direction="row" alignItems="flex-end">
            <TitleTypography>AI 잉글맵</TitleTypography>
            <Box component="img" src={BetaImage} width='51px' height='18px' sx={{ mb: 2 }}/>
          </Stack>
          <ContentTypography sx={{ mt: 4, mb: 4 }}>영어 문장에 지도(map)을 접목하여<br />강력한 학습 데이터를 생성시키는 신개념 문법 학습</ContentTypography>
          <Button variant='contained' sx={{ width: '80%' }} onClick={(e) => {
            console.log("buttonClick");
            navigate('/phrase', {replace: false});
          }} ><b>구문 분석하기</b></Button>
        </Stack>
      </Box>
      <Box sx={{ height: '190px', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box width='972px' height='83px' sx={{ position: 'absolute', left: 0, right: 0, bottom: 0, backgroundColor: "#edeef5", zIndex: -2, margin: '0 auto' }} />
        <Box component="img" src={BookImage} width='736px' height='167px' sx={{ position: 'absolute', left: 0, right: 0, top: 0, zIndex: -1, margin: '0 auto' }}/>
        <Box>
          <ContentTypography sx={{ ml: 5, mt: 4 }}>영어 실력과 상관없이<br />영어 책을 통째로 외울 수 있게 되는 신기한 경험!</ContentTypography>
          <BottomTypography sx={{ ml: 5, mt: 3.5 }}>문장을 마디로 나누고 꼬리표를 매듭으로 묶기</BottomTypography>
        </Box>
      </Box>
    </Box>
  );
}
