import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';

import Box from '@mui/material/Box';

import { analytics } from '../../firebase';

import { useAppSelector } from '../../store/hooks';

import EngDicDialog from '../../components/EngDicDialog';
import KnotMenu, { MenuName } from '../../components/KnotMenu';
import LecturePlayer from '../../components/LecturePlayer';
import SyntaxDialog from '../../components/SyntaxDialog';
import IconTitle from '../../components/common/IconTitle';
import PhraseBody from '../../components/PhraseBody';
import PhraseTree from '../../components/PhraseTree';
import QuizDialog from '../../components/QuizDialog';

import styles from '../../assets/css/PhraseResult.module.css';
import { ReactComponent as IcMark } from '../../assets/image/icon/mark.svg';

type dictState = {
  showDict: boolean,
  dictWord: string,
}

type knotState = {
  syntaxKnot: string,
  anchorEl?: any,
}

export default function PhraseResult() {
  const sentences = useAppSelector((state) => state.phrase.sentences);
  const navigate = useNavigate();
  useEffect(() => {
    if (sentences == null) {
      navigate('/phrase');
    }
  });

  const [ dict, setDict ] = useState<dictState>({
    showDict: false,
    dictWord: '',
  });
  const [ knot, setKnot ] = useState<knotState>({
    syntaxKnot: '',
  });
  const [ menu, setMenu ] = useState<boolean>(false);
  const [ lecture, setLecture ] = useState<boolean>(false);
  const [ syntax, setSyntax ] = useState<boolean>(false);
  const [ quiz, setQuiz ] = useState<boolean>(false);

  function showDict(word: string) {
    logEvent(analytics, 'select_content', {
      content_type: 'wordDict',
      item_id: word,
    });
    setDict({showDict: true, dictWord: word} );
  }
  function hideDict() {
    setDict({showDict: false, dictWord: ''});
  }
  function showMenu(knot: string, anchorEl: any) {
    setKnot({syntaxKnot: knot, anchorEl: anchorEl});
    setMenu(true);
  }
  function selectMenu(menu?: MenuName) {
    setMenu(false);
    switch (menu) {
    case "lecture":
      logEvent(analytics, 'select_content', {
        content_type: 'lecture',
        item_id: knot.syntaxKnot,
      });
      setLecture(true);
      break;
    case "syntax":
      logEvent(analytics, 'select_content', {
        content_type: 'syntax',
        item_id: knot.syntaxKnot,
      });
      setSyntax(true);
      break;
    case "quiz":
      logEvent(analytics, 'select_content', {
        content_type: 'quiz',
        item_id: knot.syntaxKnot,
      });
      setQuiz(true);
      break;
    }
  }
  function hide(menu: MenuName) {
    switch (menu) {
    case "lecture":
      setLecture(false);
      break;
    case "syntax":
      setSyntax(false);
      break;
    case "quiz":
      setQuiz(false);
      break;
    }
  }

  return (
    <Box className={styles.PhraseLayout}>
      <IconTitle className={styles.Title} icon={<IcMark />}>분석 결과</IconTitle>
      <PhraseBody showDict={showDict} />
      <PhraseTree selectKnot={showMenu} />

      <EngDicDialog open={dict.showDict} word={dict.dictWord} onClose={hideDict} />
      <LecturePlayer open={lecture} knot={knot.syntaxKnot} onClose={()=>hide("lecture")} />
      <SyntaxDialog open={syntax} knot={knot.syntaxKnot} onClose={()=>hide("syntax")} />
      <QuizDialog open={quiz} knot={knot.syntaxKnot} onClose={()=>hide("quiz")} />
      <KnotMenu open={menu} anchorEl={knot.anchorEl} onClose={selectMenu} />
    </Box>
  );
}
