import Title from '../../components/common/Title';
import { Box, Card, CardContent, Container, Stack, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { termsText } from '../../store/policySlice';
import footer_logos from '../../assets/image/icon/footer_logos.svg';



export default function Terms() {
    const [text, setText] = useState<string>('');
    const dispatch = useAppDispatch();



    useEffect(()=>{
      getTermsText();
  });

    const getTermsText = async () => {
        const getTerms: any = await dispatch(termsText());
        
     
        console.log(getTerms);
        setText(getTerms);
       return getTerms;
       
    }

    
    

    return (
        <Box component="div" sx={{width:"100%", height:"100%", overflowY:"auto"}}>
            <Box sx={{ display: "flex", width: "94%", height: "97%", p: "3%"}}>
              <Stack direction="column">
              <Stack spacing={1} direction="row" alignItems="center" justifyContent="left">
                <Box component="img"  src={footer_logos} alt="TermsLogo" sx={{width:"20px", height:"20px", borderRadius:"0%"}}/> 
                <Title title="이용약관" icon={false} divider={false} />
              </Stack>
              <Box sx={{mt:"30px",mb:"30px", width:"100%", height:"100%", fontSize:"1.8em"}} dangerouslySetInnerHTML={{ __html: text}} ></Box>
              </Stack>
            </Box>
        </Box>

    );
}


