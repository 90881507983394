import { Fragment } from 'react';

import StudyHeader from './StudyHeader';
import { Outlet } from 'react-router';

import '../../assets/css/StudyLayout.css';

export default function StudyLayout() {
  return (
    <Fragment>
      <StudyHeader />
      <section className="container">
        <Outlet />
      </section>
    </Fragment>
    );
}
