import { Grid, Stack, Button, Divider, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { getCurrentUserInfo, findPassword, updateUser, getAccountType } from '../../store/authSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import ConfirmDialog from '../../components/ConfirmDialog';
import { DialogState } from "../../components/ConfirmDialog"
import LeftSideTitle from '../../components/LeftSideTitle';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { LabelTypography, StyledTextField, StyledButton, SelectStyledButton, SelectedStyledButton } from '../../components/Profile';

const PasswordStyledButton = styled(Button)({
  fontSize: 14,
});

type InfoPopup = DialogState & {
  name: string;
}

export default function EditProfile() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');
  const [nickName, setNickName] = useState<string>('');
  const [level, setLevel] = useState<string>('');
  const [job, setJob] = useState<string>('');
  const [selLevel, setSelLevel] = useState<number>(-1);
  const [selJob, setSelJob] = useState<number>(-1);
  const [infoPopup, setInfoPopup] = useState<InfoPopup>({ open: false, name: "" });
  const [init, setInit] = useState<boolean>(false)

  const jobList = ['초등학생', '중학생', '고등학생', '대학생', '일반'];
  const levelList = ['초급', '중급', '고급'];

  
  useEffect(() => {
    // console.log("userEffect open:", props.open, ", knot:" , props.knot, "save:", props.save);
    if (!init) currentUser();
    });

  const currentUser = async () => {
    const userInfo: any = await dispatch(getCurrentUserInfo());
    
    setInit(true);

    if (email.length === 0) setEmail(userInfo.email);
    if (nickName.length === 0) setNickName(userInfo.nickName);
    if (level.length === 0) {
      if (userInfo.level) {
        setLevel(userInfo.level);
        setSelLevel(levelList.findIndex((e: string) => e === userInfo.level));
      }
    }
    if (job.length === 0) {
      if (userInfo.job) {
        setJob(userInfo.job);
        setSelJob(jobList.findIndex((e: string) => e === userInfo.job));
      }
    }
  }

  const changePassword = async () => {
    // 이메일이 정상적으로쓰이지 않은경우
    // 이메일이 없는경우 
    const userInfo: any = await dispatch(getCurrentUserInfo());

    console.log(userInfo);
    let accountType = getAccountType(userInfo.uid);
    if (accountType === "email") {
      dispatch(findPassword(userInfo.email, (values) => {
        console.log(values);
        if (values === "success") {
          setInfoPopup({ title: "안내", content: "회원가입 시 입력한 이메일로 \n비밀번호 변경 링크를 전송해드렸습니다.", confirm: "확인", open: true, name: "" });
        } else {
          setInfoPopup({ title: "안내", content: "등록된 이메일이 없습니다.", confirm: "확인", open: true, name: "" });
        }
      }));
    } else {
      setInfoPopup({ title: "안내", content: `SNS(${accountType})계정으로 가입한 계정입니다.`, confirm: "확인", open: true, name: "" });
    }
  }

  const updateUserInfo = async (e: any) => {

    console.log("updateUserInfo");

    await dispatch(updateUser(email, nickName, level, job));
    setInfoPopup({ title: "안내", content: "사용자 정보가 수정되었습니다.", confirm: "확인", open: true, name: "popup_userinfo" });
  }

  const onClickLevel = (i: any, e: any) => {
    setSelLevel(i);
    setLevel(levelList[i]);
  }

  const onClickJob = (i: any, e: any) => {
    setSelJob(i);
    setJob(jobList[i]);
  }

  const getLevelList = () => {
    const result = levelList.map((v, i) => {
      return (i === selLevel ? 
        ( <Grid item xs={3}><SelectedStyledButton variant="outlined" size="small" fullWidth={true} onClick={(e) => { onClickLevel(i, e) }}>{v}</SelectedStyledButton></Grid>) 
      : ( <Grid item xs={3}><SelectStyledButton variant="outlined" size="small" fullWidth={true} onClick={(e) => { onClickLevel(i, e) }}>{v}</SelectStyledButton></Grid>))
    })
    return result;
  }

  const getJobList = () => {
    const result = jobList.map((v, i) => {

      const styleButton = (i: number) =>{
        return (i === selJob ? 
          (<SelectedStyledButton variant="outlined" size="small" fullWidth={true} onClick={(e) => { onClickJob(i, e) }}>{v}</SelectedStyledButton>) 
        : (<SelectStyledButton variant="outlined" size="small" fullWidth={true} onClick={(e) => { onClickJob(i, e) }}>{v}</SelectStyledButton>))
      }

      return (
        <Grid item xs={3}>
          {styleButton(i)}
        </Grid>)
    })
    return result;
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={6}>
        <LeftSideTitle bigTitle='EDIT PROFILE' title="프로필 수정" subTitle="등록한 정보를 수정합니다."></LeftSideTitle>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Box component="div" display="flex" alignItems="center" sx={{ height: '100vh', backgroundColor: '#f4f5fa' }}>
          <Stack alignItems="center" direction="column" maxWidth="md" sx={{ pl: 10, width: "60%", mt: -20 }} spacing={4} >
            <Stack alignItems="left" direction="column" maxWidth="md" sx={{ width: "90%" }} spacing={2} >
              <Stack direction="row" spacing={1}><LabelTypography >아이디</LabelTypography><Typography color='#be0000'>*</Typography></Stack>
              <StyledTextField id="tf_user_email" variant="standard" size="small" fullWidth={true} value={email} disabled={true} > </StyledTextField>
              <Stack direction="row" spacing={1}><LabelTypography >닉네임</LabelTypography><Typography color='#be0000'>*</Typography></Stack>
              <StyledTextField placeholder="닉네임을 입력해 주세요." id="tf_user_nickname" variant="standard" size="small" fullWidth={true} value={nickName} onChange={(event) => setNickName(event.target.value)}></StyledTextField>
              <PasswordStyledButton variant="contained" size="medium" fullWidth={true} onClick={changePassword}><b>비밀번호 변경하기</b></PasswordStyledButton>
              <Divider light sx={{ my: 3 }} />
              <LabelTypography >레벨</LabelTypography>
              {/* <Stack direction="row" spacing={1}> */}
              <Grid container spacing={1} columns={{ xs: 9 }} >
                {getLevelList()}
              </Grid>
              <Divider light sx={{ my: 3 }} />
              <LabelTypography >직업</LabelTypography>
              <Grid container spacing={1} columns={{ xs: 9 }} >
                {getJobList()}
              </Grid>
              <Divider light sx={{ my: 3 }} />
            </Stack>
            <Grid container spacing={1} columns={{ xs: 4 }} justifyContent="center">
                <Grid item xs={3}><StyledButton variant="contained" size="medium" fullWidth={true} onClick={updateUserInfo}>변경</StyledButton></Grid>
                <Grid item xs={1}><StyledButton variant="outlined" size="medium" fullWidth={true} onClick={(e) => { navigate("/profile") }} >취소</StyledButton></Grid>
              </Grid>
          </Stack>

          <ConfirmDialog title={infoPopup.title} content={infoPopup.content} confirm={infoPopup.confirm} open={infoPopup.open}
            onClose={() => {
              setInfoPopup({ title: "", content: ".", confirm: "", open: false, name: "" })
              if (infoPopup.name === "popup_userinfo") {
                navigate('/profile');
              }
            }}
            onConfirm={() => {
              setInfoPopup({ title: "", content: ".", confirm: "", open: false, name: "" })
              if (infoPopup.name === "popup_userinfo") {
                navigate('/profile');
              }
            }}
          ></ConfirmDialog>
        </Box>
      </Grid>
    </Grid>
  );

}
