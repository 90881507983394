//https://docs.videojs.com/tutorial-react.html

import * as React from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

interface IVideoPlayerProps {
  options: videojs.PlayerOptions;
  onReady?(player: any): void;
}

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
  controlBar: {
    volumePanel: {
      inline: false
    }
  },
  aspectRatio: '16:9',
  responsive: true,
};

const VideoPlayer: React.FC<IVideoPlayerProps> = (props: IVideoPlayerProps) => {
  const videoNode = React.useRef<HTMLVideoElement>(null);
  const player = React.useRef<videojs.Player>(null);

  console.log(initialOptions);

  React.useEffect(() => {
    // console.log('useEffect', this);

    // make sure Video.js player is only initialized once
    if (!player.current) {
      if (!videoNode.current) return;

      player.current = videojs(videoNode.current, {
        ...initialOptions,
        ...props.options
      }, () => {
        console.log('onPlayerReady', this);
        props.onReady && props.onReady(player.current);
      });
    }
  }, [props, videoNode]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      if (player.current) {
        player.current.dispose();
        player.current = null;
      }
    };
  }, [player]);

  return <video ref={videoNode} className="video-js vjs-big-play-centered vjs-layout-medium	" />;
};

export default VideoPlayer;
