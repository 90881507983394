import { TextField, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const LabelTypography = styled(Typography)({
  color: '#000000',
  fontSize: 14,
  fontFamily: 'NanumSquare',
  fontWeight: 'bold'
})
  
export const StyledTextField = styled(TextField)({
  "& .MuiInputBase-input.MuiInput-input.Mui-disabled": {
    "-webkit-text-fill-color": "#bdbdbd",
    fontWeight: 'bold'
  },
  "& .MuiInputBase-input.MuiInput-input": {
    "-webkit-text-fill-color": "#000000",
    fontWeight: 'bold'
  },
  "& .MuiInputBase-root.MuiInput-root.Mui-disabled:before": {
    "border-bottom-style": "solid",
    "border-bottom-width": "1px",
    "border-bottom-color": "#dddddd"
  },
  "& .MuiInputBase-root.MuiInput-root:hover:before": {
      "border-bottom-style": "solid",
      "border-bottom-width": "2px",
      "border-bottom-color": "#5a62ad",
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
  '&:hover fieldset': {
    borderWidth: 2,
    borderColor: "#5a62ad",
  },
},
  fontSize: 14,
  fontWeight: 'bold',
  fontFamily: 'NanumSquare',
});
  
export const StyledButton = styled(Button)({
  fontSize: 20,
  fontFamily: 'NanumSquare',
  fontWeight: 'bold'
})
  
export const SelectStyledButton = styled(Button)({
  fontSize: '16px',
  fontFamily: 'NanumSquare',
  background: '#f7f7f7',
  color: '#101010',
  borderColor: '#dddddd',
  borderWidth: 2,
  ":hover": {
    borderWidth: 2,
  }
});
  
export const SelectedStyledButton = styled(Button)({
  fontSize: 16,
  fontFamily: 'NanumSquare',
  background: '#ffffff',
  color: '#5a62ad',
  borderColor: '#5a62ad',
  borderWidth: 2,
  ":hover": {
    borderWidth: 2,
  },
});
