import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { refreshUser, signOut, selectUser } from '../../store/authSlice';
import { changeLocation } from '../../store/gnbSlice';
import { auth } from '../../firebase';

import logo from '../../assets/image/icon/logo.png';
import { ReactComponent as IcSignIn } from '../../assets/image/icon/signin.svg';
import { ReactComponent as IcSignUp } from '../../assets/image/icon/signup.svg';
import { ReactComponent as IcSignOut } from '../../assets/image/icon/signout.svg';
import { ReactComponent as IcProfile } from '../../assets/image/icon/profile.svg';
import Typography from '@mui/material/Typography';

type Pages = 'main' | 'phrase' | 'study' | 'signup' | 'signin' | 'profile' | '';

const StyledTab = styled(Tab)({
  fontSize: '20px',
  fontFamily: 'NanumSquareExtraBold',
  fontWeight: 900,
  color: '#5E5858',
  '&.Mui-selected': {
    color: '#5A62AD',
  },
  '&.MuiButtonBase-root.MuiTab-root': {
      padding:" 22px 25px 22px 25px"
  },
});

export default function Header() {
  let dispatch = useAppDispatch();
  let location = useLocation();
  let navigate = useNavigate();
  const TabMenu = ['main', 'phrase', 'study']

  let userInfo = useAppSelector(selectUser);
  const [tab, setTab] = useState(parseUrl());

  auth.onAuthStateChanged((user) => {
    dispatch(refreshUser());
  });

  useEffect(() => {
    dispatch(changeLocation(location.pathname));
  },[location]);

  function parseUrl() {
    let path = location.pathname.split('/');
    return path[1].length == 0 || !(TabMenu.includes(path[1])) ? 'main' : path[1];
  }

  function goto(menu: Pages, replace:boolean = false) {
    console.log("[goto]:", menu);
    setTab(TabMenu.includes(menu) ? menu : 'main');
    switch (menu) {
    case "signin":
      navigate('/signin', {replace: replace});
      break;
    case "signup":
      navigate('/signup', {replace: replace});
      break;
    case "profile":
      navigate('/profile', {replace: replace});
      break;
    case "phrase":
      navigate('/phrase', {replace: replace});
      break;
    case "study":
      navigate('/study', {replace: true});
      break;
    // case "terms":
    //   navigate('/terms', {replace: replace});
    //   break;
    // case "privacy":
    //   navigate('/privacy', {replace: replace});
    //   break;
    // case "qa":
    //   navigate('/qa', {replace: replace});
    //   break;
    // case "notices":
    //   navigate('/notices', {replace: replace});
    //   break;
    // case "auth":
    //   navigate(location, {replace: replace});
    //   break;
    default:
      navigate('/', {replace: replace});
      break;
    }
  }
  function signout() {
    dispatch(signOut());
  }

  let userArea;
  if (userInfo) {
    userArea =
      <Stack spacing={2} direction="row"  justifyContent="flex-end">
        <Stack spacing={1}  display="flex" alignItems="center" direction="row">
          <Typography variant='body2' color="#5a62ad" > {userInfo.nickName}</Typography> 
          <IconButton onClick={() => goto("profile")}>
            <IcProfile />
          </IconButton>
        </Stack>
        <IconButton onClick={signout}>
          <IcSignOut />
        </IconButton>
      </Stack>;
  } else {
    userArea =
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <IconButton onClick={() => goto("signin")}>
          <IcSignIn />
        </IconButton>
        <IconButton onClick={() => goto("signup")}>
          <IcSignUp />
        </IconButton>
      </Stack>;
  }
  return (
    <Fragment>
      <section className="Container">
        <Box className="NaviArea">
          <Box  className="Logo" display="flex" alignItems="center" justifyContent="flex-start">
            <Box  component="img" className="LogoImg" alt="logo" src={logo}  sx={{width:"170px", maxWidth:"170px", cursor:"pointer"}} onClick={() => goto("main")}>
            </Box>
          </Box>
          <Box className="Tab">
            <Tabs value={tab} centered indicatorColor='primary'>
              {/* <Tabs value={value} onChange={navi} centered indicatorColor='primary' TabIndicatorProps={(value === "main" || value === "phrase" || value === "study" ? {}: { style: {transition: 'none'}})}> */}
              <StyledTab label="AI 잉글맵" value={"main"}  onClick={(e) => goto("main")}/>
              <StyledTab label="구문분석" value={"phrase"} onClick={(e) => goto("phrase")}/>
              <StyledTab label="학습노트" value={"study"} onClick={(e) => goto("study")}/>
            </Tabs>
          </Box>
          <Box className="User" display="flex" alignItems="center" justifyContent="flex-end">
            { userArea }
          </Box>
        </Box>
      </section>
      <section className="Divider" />
    </Fragment>
  );
}

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
