import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import {Close } from '@mui/icons-material';
import { padding } from '@mui/system';

const CloseButton = styled(Button)({
  float:"right",
  color: "#ffffff",
  fontSize: 14,
  minWidth: 27,
  minHeight: 22,
  padding:0,
}); 

type DialogProps= {
  title?:string;
  content?:string;
  open:boolean;
  onClose():void;
  confirm:string;
  onConfirm():void;
  cancel?:string;
  onCancel?():void;
}

export type DialogState = {
  title?:string;
  content?:string;
  open: boolean;
  confirm?:string;
  cancel?:string;
}

export default class ConfirmDialog extends React.Component<DialogProps, DialogState >{

  constructor (props : DialogProps) {
    super(props);
    this.state = {
      open : props.open,
    }
  }

  static getDerivedStateFromProps(nextProps:any, prevState :any) {
    console.log("getDerivedStateFromProps");
    if (nextProps.open !== prevState.open 
      || nextProps.title !== prevState.title
      || nextProps.content !== prevState.content
      || nextProps.confirm !== prevState.confirm
      || nextProps.cancel !== prevState.cancel
    ) {
      return { 
        open: nextProps.open,
        title: nextProps.title,
        content: nextProps.content,
        confirm: nextProps.confirm,
        cancel: nextProps.cancel,
        type: nextProps.type,
       };
    }
    return null;
  }

  render() {
    const handleClose = () => {
      this.setState({
        open : false
      })
      if (this.props.onClose) {
        this.props.onClose();
      }
    };

    const handleConfirm = () => {
      this.setState({
        open : false
      })
      if (this.props.onConfirm) {
        this.props.onConfirm();
      }
    };

    const handleCancel = () => {
      this.setState({
        open : false
      })
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    };

    const parseContent =(content:string) => {
      if (content) {
          const contents = content.split("\n");
         return contents.map((v) => {
           return ( <Typography gutterBottom dangerouslySetInnerHTML={{ __html: v}}>
          </Typography>)
          })
      }
    }



    let buttons;
    if (this.props.onConfirm && this.props.onCancel && this.props.confirm && this.props.cancel) {
      buttons = 
        <Stack spacing={2} direction="row">
          <Button variant="outlined" onClick={handleConfirm}>
            {this.state.confirm}
          </Button>
          <Button variant="outlined" onClick={handleCancel}>
            {this.state.cancel}
          </Button>
        </Stack>;
    } else {
      buttons = 
        <Button variant="outlined" onClick={handleConfirm}>
          {this.state.confirm}
        </Button>;
    }

    console.log("CustomizedDialogs::" + this.state.open);

    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
      >
        <DialogTitle> 
        <Typography gutterBottom sx={{ width: '100%', flexShrink: 0 }}>
        {this.state.title} 
        <CloseButton aria-label="close" color="primary" onClick={handleClose}>
          <Close />
        </CloseButton>
        </Typography>
        </DialogTitle>
        <DialogContent dividers>
          {parseContent(this.state.content)}
        </DialogContent>
        <DialogActions>
          {buttons}
        </DialogActions>
      </Dialog>
    );
  }
}
