import { Component, Fragment } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useAppSelector } from '../store/hooks';
import { Content } from '../api/mock';

import styles from '../assets/css/Sentence.module.css';

const StyledDivider = styled(Divider)({
  width: '100%',
  borderColor: '#5961AC',
});
const FormTypography = styled(Typography)({
  color: '#FFF',
  margin: 0,
});
const StyledTypography = styled(Typography)({
  color: '#101010',
  margin: 0,
  overflow: 'unset',
});

export class Element extends Component {
  render() {
    return (
      <Fragment>
        <StyledDivider />
        <Box className={styles.FormBox}>
          <FormTypography variant="h6">{this.props.children}</FormTypography>
        </Box>
      </Fragment>
    );
  }
}

type FormProps = {
  children?: any,
  element: string,
  showDictionary?: (word: string) => void,
};

export function Form(props: FormProps) {
  const showElement = useAppSelector((state) => state.phrase.element);
  let element;
  if (showElement) {
      element = <Element>{props.element}</Element>
    }
    return (
      <Box className={styles.Form}>
        <Box className={styles.Words}>
          {
            (props.children as string).split(' ').map((word, index, array) => {
              let className = styles.Word;
              if (index === 0) {
                className = styles.First;
              } else if (index === (array.length - 1)) {
                className = styles.Last;
              }
          return <StyledTypography key={index} className={className} variant="body1" noWrap onClick={() => props.showDictionary(word)}>{word}</StyledTypography>;
            })
          }
        </Box>
        {element}
      </Box>
    );
}

export type SentenceProps = {
  showDictionary?: (word: string) => void,
};

export default function Sentence(props: SentenceProps) {
  const [ sentences, selected ] = useAppSelector((state) => [
    state.phrase.sentences,
    state.phrase.selected,
  ]);
  let contents: Array<Content> = [];

  function element(element: string): Content {
    let elementA = sentences[selected].content.filter((content) => content.element === element).map((content) => content.content);
    return { element: element, content: elementA.join(" ") };
  }
  if (sentences != null) {
    let form = new Map<string, string>();
    sentences[selected].content.forEach((content) => form.set(content.element, content.content));

    contents = Array.from(form).map(([key, value]) => element(key))
  }

  return (
    <Box className={styles.Sentence}>
      {
        contents.map(content =>
          <Form {...props} key={content.element} element={content.element}>{content.content}</Form>
        )
      }
    </Box>
  );
}
