import React, { useState } from 'react';

import { /*verifyPasswordResetCode,*/ confirmPasswordReset } from "firebase/auth";

// TODO import Spinner from 'react-spinkit';
import { auth } from '../../firebase';
import { Box, Button, Container, Divider, Grid, Stack, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Title from '../../components/common/Title';
import ConfirmDialog, { DialogState } from '../../components/ConfirmDialog';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import LeftSideTitle from '../../components/LeftSideTitle';
import { LabelTypography, StyledButton } from '../../components/Profile';
import { StyledTextField } from '../policy/Qa';

const ButtonTypography = styled(Typography)`
  color: #333333;
  font-family: 'NanumGothic';
  font-size: 10px;
`;



// interface AuthProps {
//   actionCode: string;
//   //navigate(path: string): void;
  
  
// }

// type AuthState = {
//   actionCode: string;
//   email: string|null;
//   error: string;
//   password: string;
//   success: boolean;
//   validCode: boolean;
//   verifiedCode: boolean;
  
// }

// export function Navigate(){
//   const navigate = useNavigate();
//   navigate('/')
// }



// export default class ResetPassword extends Component<AuthProps, AuthState> {
  
  
   
//   constructor (props : AuthProps) {
    
//     super(props);
//     this.state = {
//       actionCode: props.actionCode,
//       email: null,
//       error: '',
//       password: '',
//       success: false,
//       validCode: false,
//       verifiedCode: false,
//     }
//   }

  

//   componentDidMount() {
//     // Verify the password reset code is valid.
//       verifyPasswordResetCode(auth, this.props.actionCode)
//       .then(email => {
//         this.setState({ email, validCode: true, verifiedCode: true });
//       }, error => {
//         // Invalid or expired action code. Ask user to try to reset the password
//         // again.
//         this.setState({ error: error.message, validCode: false, verifiedCode: true });
//       });
//   }

//   handleResetPassword = (event: any) => {
    
    
    
//     event.preventDefault();
//     const { actionCode } = this.props;
//     const newPassword = this.state.password;

//     // Save the new password.
//       confirmPasswordReset(auth, actionCode, newPassword)
//       .then(() => {
//         // Password reset has been confirmed and new password updated.
//         this.setState({ success: true });
        
//           // const navigate = useNavigate();
//           // navigate('/')
//           //this.props.navigate('/');
        
        
//       }, error => {
//         // Error occurred during confirmation. The code might have expired or the
//         // password is too weak.
//         this.setState({ error: error.message });
//       });
//   }

//   setText = (evt: any) => {
//     this.setState({ password: evt.target.value });
//   }

//   render() {
   
    
    
    
//     const {
//       email,
//       error,
//       password,
//       success,
//       validCode,
//       verifiedCode,
//     } = this.state;

//     return(
//       <Box component="div">
//         <Header></Header>
//             <Title title="비밀번호 변경" subTitle=""/>
//             <Container maxWidth="md" sx={{my:10}}>
//                 <Grid container spacing={1} alignItems="center">
//                     <Grid item sm={4}>
//                         <h2>비밀번호 변경</h2>
//                         <h5>비밀번호를 변경해 주세요.</h5>
//                     </Grid>
//                     <Grid item sm={9}>
//                         <TextField 
//                         id="useremail1" 
//                         variant="outlined" 
//                         size="small" 
//                         fullWidth={true} 
//                         placeholder=" 비밀번호를 입력하세요. (영어+숫자 / 6글자 이상)"
//                         onChange={this.setText}
//                         value={password}
//                         type="password"
//                         required>
//                         </TextField>
//                     </Grid>
//                     <Grid item sm={9}>
//                         <TextField 
//                         id="useremail2" 
//                         variant="outlined" 
//                         size="small" 
//                         fullWidth={true} 
//                         placeholder=" 비밀번호를 입력하세요. (영어+숫자 / 6글자 이상)"
//                         ></TextField>
//                     </Grid>
//                     <Grid>
//                     <hr></hr>
//                     </Grid>
//                     <Grid item sm={9}>
//                         { <Button variant="contained" size="medium" fullWidth={true} 
//                         onClick={this.handleResetPassword} >비밀번호 변경</Button>}
//                     </Grid>
//                     {/* <Grid item sm={4}>
//                         <Button variant="contained" size="medium" fullWidth={true} >회원 탈퇴</Button>
//                     </Grid> */}
//                 </Grid>

          
//             </Container>
     
//       </Box>
      
//     );
//   }
// }

//export default function SuccessPlease();



interface AuthProps {
  actionCode: string;
  //navigate(path: string): void;
  
  
}

export  function ResetPassword(props : AuthProps) {

  const contents = [
    "비밀번호를 변경해 주세요."
  ]

  
  
  type AuthState = {
    actionCode: string;
    email: string|null;
    error: string;
    password: string;
    password2: string,
    success: boolean;
    validCode: boolean;
    verifiedCode: boolean;
    
  }

  type InfoPopup  = DialogState &  {
    name: string;
  }

  const navigate = useNavigate();
  const [infoPopup, setInfoPopup] = useState<InfoPopup>({open:false, name:""});
  const [state, setState] = useState<AuthState>({
    actionCode: props.actionCode,
    email: null,
    error: '',
    password: '',
    password2:'',
    success: false,
    validCode: false,
    verifiedCode: false,
  });
  

  /*
  function componentDidMount() {
    // Verify the password reset code is valid.
    
      verifyPasswordResetCode(auth, props.actionCode)
      .then(email => {
        setState({ ...state, email, validCode: true, verifiedCode: true });
      }, error => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        setState({ ...state, error: error.message, validCode: false, verifiedCode: true });
      });
  }
  */

  function handleResetPassword (event: any) {
     
    
    event.preventDefault();
    // const { actionCode } = props;
    const newPassword = state.password;
    const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
    
    if (password === password2){
    
   
    // Save the new password.
      confirmPasswordReset(auth, props.actionCode, newPassword)
      .then(() => {
        // Password reset has been confirmed and new password updated.
        setState({...state,  success: true });
        setInfoPopup({title:"안내",  content:"비밀번호 변경이 완료되었습니다.", confirm:"확인", open:true, name:"popup_userinfo"});
          // const navigate = useNavigate();
          // navigate('/')
          //this.props.navigate('/');
      }, error => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        setState({...state,  error: error.message });
      });
    } else if(password !== password2) {
      setInfoPopup({title:"안내",  content:"비밀번호가 일치하지 않습니다.", confirm:"확인", open:true, name:"popup_close"});
      
    }
    
    if (!mediumRegex.test(password)) {
      setInfoPopup({title:"안내", content:"비밀번호는 영어+숫자/6글자 이상입니다.", confirm:"확인", open:true, name:"simple_password"});
      return;
    }
  }

  function setText(evt: any)  {
    setState({...state,  password: evt.target.value });
  }

  function setText2(evt: any)  {
    setState({...state,  password2: evt.target.value });
  }

  const {
    /*
    email,
    error,
    */
    password,
    password2,
    /*
    success,
    validCode,
    verifiedCode,
    */
  } = state;
  return(
    <Grid container spacing={0}>
      <Grid item xs={12} sm={12} md={6}>
        <LeftSideTitle bigTitle='CHANGEING THE PASSWORD' title="비밀번호 변경" contents={contents}></LeftSideTitle>
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <Box component="div" display="flex" alignItems="center" sx={{ height: '100vh', backgroundColor: '#f4f5fa' }}>
          <Stack alignItems="center" direction="column" maxWidth="md" sx={{ pl: 10, width: "60%", mt: -20 }} spacing={4} >
            <Stack alignItems="left" direction="column" maxWidth="md" sx={{ width: "90%" }} spacing={2} >
             
              <Stack direction="row" spacing={1}><LabelTypography >비밀번호</LabelTypography><Typography color='#be0000'>*</Typography></Stack>
              <StyledTextField placeholder=" 비밀번호를 입력하세요. (영어+숫자 / 6글자 이상)" id="useremail1" variant="standard" size="small" fullWidth={true} onChange={setText} value={password} type="password" required></StyledTextField>
              <Stack direction="row" spacing={1}><LabelTypography >비밀번호 확인</LabelTypography></Stack>
              <StyledTextField placeholder=" 비밀번호를 입력하세요. (영어+숫자 / 6글자 이상)" id="useremail2" variant="standard" size="small" fullWidth={true} onChange={setText2} value={password2} type="password"></StyledTextField>
            
            </Stack>
            <Grid container spacing={1} columns={{ xs: 4 }} justifyContent="center">
                <Grid item xs={6}><StyledButton variant="contained" size="medium" fullWidth={true} onClick={handleResetPassword} >비밀번호 변경</StyledButton></Grid>
              </Grid>
          </Stack>

          <ConfirmDialog title={infoPopup.title} content={infoPopup.content} confirm={infoPopup.confirm} open={infoPopup.open} 
        onClose={()=>{
          setInfoPopup({title:"",  content:".", confirm:"", open:false, name:""})
          if (infoPopup.name === "popup_close") {
          
          }
        }}
        onConfirm={()=>{
          setInfoPopup({title:"",  content:".", confirm:"", open:false, name:""})
          if (infoPopup.name === "popup_userinfo") {
          navigate('/');
          }
        }}
      ></ConfirmDialog>
        </Box>
      </Grid>
    </Grid>
    
    // <Box component="div">
    //   <Header></Header>
    //       <Title title="비밀번호 변경" subTitle=""/>
    //       <Container maxWidth="xs" sx={{my:10}}>
    //       <Box alignContent="center">
    //       <Stack direction="column" alignItems="center" spacing={1}>
    //       <h2>비밀번호 변경</h2>
    //       </Stack>
    //     </Box>

    //     <Grid container spacing={1} alignItems="center">
    //       <Grid item sm={12}>
    //         <Stack direction="column" alignItems="center" spacing={1}>
    //         <h5>비밀번호를 변경해주세요.</h5>
    //         </Stack>
    //       </Grid>
    //     </Grid>

    //     <Grid container spacing={1} alignItems="center">
    //       <Grid item sm={12}>
    //       <TextField 
    //       id="useremail1" variant="outlined" size="small" fullWidth={true} placeholder=" 비밀번호를 입력하세요. (영어+숫자 / 6글자 이상)"
    //       onChange={setText} value={password} type="password" required>
    //       </TextField>
    //       </Grid>
    //       <Grid item xs={12}>
    //       <TextField 
    //       id="useremail2" variant="outlined" size="small" fullWidth={true}  placeholder=" 비밀번호를 입력하세요. (영어+숫자 / 6글자 이상)"
    //       onChange={setText2} value={password2} type="password">
    //       </TextField>
    //       </Grid>
    //     </Grid>

    //     <Divider light sx={{ my: 3 }} />
    //     <Grid container spacing={1} columns={{ xs: 8 }} justifyContent="center">
    //       <Grid item xs={9}>
    //         <Button variant="contained" size="medium" 
    //         fullWidth={true} onClick={handleResetPassword}>비밀번호 변경</Button>
    //       </Grid>
    //     </Grid>
    //     <Stack spacing={27} direction="row">
    //       <Button variant="text" startIcon={<KeyboardArrowRightIcon />} onClick={() => navigate('/')}>
    //         <ButtonTypography>회원탈퇴</ButtonTypography>
    //       </Button>
    //     </Stack>
    //       </Container>
    //       <ConfirmDialog title={infoPopup.title} content={infoPopup.content} confirm={infoPopup.confirm} open={infoPopup.open} 
    //     onClose={()=>{
    //       setInfoPopup({title:"",  content:".", confirm:"", open:false, name:""})
    //       if (infoPopup.name === "popup_close") {
          
    //       }
    //     }}
    //     onConfirm={()=>{
    //       setInfoPopup({title:"",  content:".", confirm:"", open:false, name:""})
    //       if (infoPopup.name === "popup_userinfo") {
    //       navigate('/');
    //       }
    //     }}
    //   ></ConfirmDialog>
    //   <Footer></Footer>
    // </Box>
    
  );
}
