import * as React from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { getExamplesAync, ExampleState } from '../store/lectureSlice';
import { useAppDispatch } from '../store/hooks';
import { StatExample, saveExample } from '../store/statSlice';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { Divider } from '@mui/material';

const CloseButton = styled(Button)({
  float:"right",
  color: "#ffffff",
  fontSize: 14,
  minWidth: 27,
  minHeight: 22,
  padding:0,
}); 


interface ISyntaxDialogProps {
  knot: string;
  open: boolean;
  onClose: () => void;
  save?: boolean;
  number?: string; 
};

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid #5a62ad`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.7rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#5a62ad',
  },
}));

export default function SyntaxDialog(props: ISyntaxDialogProps) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState<boolean>(props.open);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [examples, setExamples] = React.useState<ExampleState[]>([]);
  const [save, setSave ] = React.useState<boolean>(true);

  const handleChange =
    (index:number, panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      if (isExpanded) {
        examples[index].isRead = true;
      }
    };

  const handleClose = () => {
    setExamples([]);
    props.onClose();
    examples.forEach((ex) => {
      if (ex.isRead) {
        // props.onSave && props.onSave(ex.id, ex.number, examples.length);
        if (save) {
          const s: StatExample = {
            knot: props.knot,
            knotTitle: '',
            date: new Date(),
            exampleId: ex.id,
            number: ex.number,
            exampleTitle: ex.title,
            totalCount: examples.length,
          }
          dispatch(saveExample(s)).then(() => {
          }).catch((error: any) => {
            console.error('saveExample: error:', error);
          });
        }
      }
    });
  };

  React.useEffect(() => {
    console.log("isOpen:", props.open, "knot:", props.knot, "save:", props.save);
    if (!open) {
      if (props.knot) {
        dispatch(getExamplesAync(props.knot)).then((data) => {
          console.log("data:", data.payload);
          if (data.payload) {
            const list: Array<ExampleState> = data.payload as Array<ExampleState>;
            const examples_data = list.map((v, i) => { return { knot: v.knot, knotTitle:v.knotTitle, title: v.title, imageLink: v.imageLink, number: v.number, id: v.id, isRead: false } });

            if (props.number) {
              const expandIndex =  examples_data.findIndex((v) => props.number === v.number);
              setExpanded(`panel${expandIndex}`);
            }

            setExamples(examples_data);
          }
        });
      }
    }

    if (props.open !== open) {
      setOpen(props.open);
    }

    if (props.save !== undefined) {
      setSave(props.save);
    }

  }, [dispatch, open, props.open, props.knot, props.save]);

  const getExampleList = (list: ExampleState[]) => {
    console.log("getExampleList:::", examples);
    const result = list.map((v, i) => {
      console.log("v:", i, v);
      return (
        <Accordion  expanded={expanded === `panel${i}`} onChange={handleChange(i, `panel${i}`)} >
          <AccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon />}
            aria-controls={`panel${i}bh-content`}
            id={`panel${i}bh-header`}
          >
            <Typography textAlign="left" sx={{ width: '100%', flexShrink: 0 }}>
              {v.number}. {v.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails  sx={{m:0}}>
            <Card>
              <CardMedia
                component="img"
                height="100%"
                image={v.imageLink}
              />
            </Card>
          </AccordionDetails>
        </Accordion>);
    })
    return result;
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <DialogTitle >
        <Typography sx={{ width: '100%', flexShrink: 0 }}>
          유사구문 {(!examples || examples.length <= 0 ?"": " - #" + props.knot + " "+ examples[0].knotTitle)} 
          <CloseButton aria-label="close" color="primary" onClick={handleClose} sx={{ m: 0 }}>
          <CloseIcon />
        </CloseButton>
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 0, m:0 }}>
        {getExampleList(examples)}
      </DialogContent>
    </Dialog>
  );
}