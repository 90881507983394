import Title from '../../components/common/Title';
import { Box, Container, Stack } from "@mui/material";
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { privacyText } from '../../store/policySlice';
import footer_logos from '../../assets/image/icon/footer_logos.svg';

export default function Privacy() {
  const [text, setText] = useState<string>('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    getPrivacyText();
  });

  const getPrivacyText = async () => {
    const getTerms: any = await dispatch(privacyText());
    console.log(getTerms);
    setText(getTerms);
    return getTerms;
  }

  return (

    <Box component="div" sx={{ width: "100%", height: "100%" , overflowY: "auto" }}>
      <Box sx={{ display: "flex", width: "94%", height: "97%", p: "3%"}}>
        <Stack direction="column" width="100%">
          <Stack spacing={1} direction="row" alignItems="center" justifyContent="left">
            <Box component="img" src={footer_logos} alt="PrivacyLogo" sx={{ width: "20px", height: "20px", borderRadius: "0%" }} />
            <Title title="개인정보처리방침" icon={false} divider={false} />
          </Stack>
          <Box sx={{ mt: "30px", mb: "30px", width: "100%", height: "100%", fontSize: "1.8em"}} dangerouslySetInnerHTML={{ __html: text }}>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
